import { gql } from "apollo-boost";

export const ALL_ENPHASE_APPT_QUERY = gql`
  query allEnphaseAppointments {
    allEnphaseAppointments {
      _id
      RepName
      RepEmail
      oppnum
      Office
      Address
      City
      State
      CustomerName
      Zip
      type
      createdBy
    }
  }
`;

import React from "react";

import ProposalTable from "../../components/ProposalTable/ProposalTable";
import AssignProposal from "../../components/AssignProposal/AssignProposal";

import DropFromQueue from "../../components/DropFromQueue/DropFromQueue";

export const tableHeaders = [
  { id: "1", label: "Sales Rep", key: "RepName" },
  { id: "2", label: "Opportunity Number", key: "oppnum" },
  { id: "3", label: "Customer", key: "CustomerName" },
  { id: "4", label: "Office", key: "Office" },
  { id: "5", label: "Appointment Date", key: "AppointmentDateString" },
  { id: "6", label: "Appointment Address", key: "Address" },
  //{ id: "7", label: "Data Sheet Complete", key: "datasheetcomplete" },
  { id: "7.1", label: "Time Requested", key: "timerequestedpretty" },

  { id: "7", label: "Drop From Queue", key: "drop" },

  { id: "8", label: "Assign Proposal", key: "assign" },
];

export const proposalsUnassigned = (proposalData) => {
  // eslint-disable-next-line
  const allUnAssigned = proposalData.filter((prop) => {
    if (!prop.user) return prop;
  });

  return allUnAssigned;
};

export const proposalsAssigned = (proposalData) => {
  // eslint-disable-next-line
  const allAssigned = proposalData.filter((prop) => {
    if (prop.user) return prop;
  });

  return allAssigned;
};
const Assign = ({ proposalData, allUserData }) => {
  //(allUserData);

  const newProposalData = proposalData.reduce((arr, curr) => {
    arr.push({
      ...curr,

      assign: (
        <AssignProposal
          proposalData={proposalData}
          id={curr.id}
          allUserData={allUserData}
        />
      ),
      drop: <DropFromQueue id={curr.id} />,
    });
    return arr;
  }, []);

  return (
    <>
      {/* <Switch /> */}
      <ProposalTable
        tableHeaders={tableHeaders}
        data={proposalsUnassigned(newProposalData)}
      />
    </>
  );
};

export default Assign;

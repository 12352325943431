import React, { createContext, useState } from "react";

export const DecoTechContext = createContext({});

const DecoTechContextProvider = ({ children }) => {
  const [decoForm, setDecoForm] = useState({
    roof_squares: 0,
    roof_material: "shingles",
    roof_loan: 0,
    roof_cost: 0,
  });

  return (
    <DecoTechContext.Provider
      value={{
        decoForm,
        setDecoForm,
      }}
    >
      {children}
    </DecoTechContext.Provider>
  );
};

export default DecoTechContextProvider;

import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { cleanNumbers, pullEmpties, deleteKeys } from "./Helpers";
import { MenuItem, InputLabel } from "@material-ui/core";
import { USER_BY_EMAIL_QUERY } from "../../utils/GQL/QLQueries";
import { validateOtherValues, validateValues } from "./validate";
import {
  StyledCon,
  StyledFormCon,
  FormItself,
  FormControlStyles,
  TextFieldStyles,
  SelectStyles,
  SubmissionDiv,
  ButtonCon,
} from "./styles";
import CurrProposalDataHeader from "../../components/CurrProposalHeader/CurrProposalDataHeader";
import ProposalImages from "../../components/ProposalImages/ProposalImages";
import {
  getMonthlyBill,
  pvGen,
  getOffset,
  getGeneracLoanValues,
  getEPC,
  adjustedEPC,
  getLoanAmount,
} from "./Calculations";
import UserCallToAction from "../../components/UserCallToAction";
import Loan from "../../containers/Loan/Loan";
import Cash from "../../containers/Cash/Cash";

import IGSPPA from "../../containers/IGSPPA/IGSPPA";
import { client } from "../../App";
import { ALL_PRODUCTS_QUERY } from "../../utils/ProductsQueries";
import { COMPLETE_PROPOSAL_MUTATION } from "../../utils/QLMutations";
import {
  insertSpaceAtCaps,
  prepPDFData,
  addWorkVals,
  generatePDF,
} from "./utils";
// eslint-disable-next-line
import { sendEmail2, postSlackError } from "../../utils/API";
import { Context } from "../../Context/ProposalContext";
import CompletedProposal from "../../components/CompletedProposal/CompletedProposal";
import { BatteryContext } from "../../Context/BatteryContext";
import { PostDigitalFail } from "./slackError";
import Axios from "axios";
import { DecoTechContext } from "../../Context/DecoTechContext";
import { dash_type } from "../../config";
import useFetchAdditionalProposalFields from "./useFetchAdditionalProposalFields";
import LoadDataFromProposal from "./LoadDataFromProposal";

export let needsToBeInt = [
  "inverterWattage",
  "panelWattage",
  "production",
  "downPayment",
  "rebate",
  "utilityRate",
  "panels",
  "ppW",
  "solarRate",
  "solarEscalator",
];
export let deleteThese = [
  "panelImageUrl",
  "designImageUpload",
  "adjustedEPC",
  "monthlyBill",
  "srecs",
];

export const Loans = ["Loan", "GeneracLoan", "JEALoan", "DecoLoan"];
export const Cashes = ["Cash", "DecoCash"];
export const IGSES = ["IGSPPA", "IGSLEASE", "SUNRUN", "MSES"];
export const Generacs = ["GeneracLoan", "JEALoan"];

const StartProposalV2 = () => {
  const localProd = "https://proposalgen2.momentumsolar.app";

  const [modalState, setModalState] = useState("");
  const {
    initialProposalData,
    initialFormValues,
    setInitialValues,
    otherValues,
    setOtherValues,
    userValues: { userName, name },
    austinEnergy,
    monthValues,
    appPermissions: { _id },
    revision,
    setProposalPreview,
    useOriginal,
    consumptionType,
    fID,
    setUtilityList,
    utilitySubmit,
    demoid,
    setDemoId,
    setInitialProposalData,
  } = useContext(Context);
  const { decoForm } = useContext(DecoTechContext);
  const { generacForm, setGeneracForm } = useContext(BatteryContext);

  useEffect(() => {
    if (!otherValues.timerequested) {
      setOtherValues((prev) => ({ ...prev, timerequested: new Date() }));
    }
  }, []);

  const _ = useFetchAdditionalProposalFields(
    initialProposalData?.id,
    setOtherValues,
    setGeneracForm
  );

  const { proposalType } = otherValues;

  const resetWork = () => {
    setOtherValues({
      ...otherValues,
      additionalwork: [
        {
          type: "tree",
          notes: "",
          img: "",
          filePreview: "",
          required: "",
          estimatedCost: "",
        },
        {
          type: "trench",
          notes: "",
          img: "",
          filePreview: "",
          required: "",
          estimatedCost: "",
        },
      ],
    });
  };

  const clearGenerac = () => {
    setGeneracForm({
      batteryModuleQty: "",
      batteryCost: "",
      batteryModel: {},
      batteryPPW: "",
      batteryRebate: "",
      enphaseRebate: 0,
    });
  };

  // causing issue

  const { allProducts } = client.readQuery({
    query: ALL_PRODUCTS_QUERY,
  });
  let { allPanels, inverters } = allProducts;
  allPanels = allPanels.filter((panel) => panel.active);
  const [completeProposal] = useMutation(COMPLETE_PROPOSAL_MUTATION);

  const revisionValues = { ...initialProposalData };

  const [next, setNext] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // helper methods.
  const changeInput = (e) => {
    setInitialValues({
      ...initialFormValues,
      [e.target.name]: e.target.value,
    });
  };

  console.log(getLoanAmount(otherValues));
  const onSubmitForm = async (e) => {
    e.preventDefault();
    e.persist();
    console.log("--- Raw Form Data", { initialFormValues, otherValues });

    if (!otherValues.eminemId) {
      setErrorMessage("Please enter an Eminem ID");
      return;
    }

    if (!otherValues.panels) {
      setErrorMessage("Please enter a Panel Quantity");
      return;
    }

    if (
      (initialFormValues["State"] === "CT" ||
        initialFormValues["State"] === "RI") &&
      !otherValues.shade_percent //AKA Solar Access
    ) {
      setErrorMessage("Please enter Solar Access percentage!");
      return;
    }
    if (
      otherValues?.shade_percent &&
      (+otherValues.shade_percent > 100 || +otherValues.shade_percent < 0)
    ) {
      setErrorMessage(
        "Please make sure Solar Access percentage is between 0 and 100"
      );
      return;
    }

    if (otherValues.msesPrime === "" && otherValues.proposalType === "MSES") {
      setErrorMessage("Please select if this is MSES Prime");
      return;
    }
    if (
      otherValues.proposalType === "DecoCash" ||
      otherValues.proposalType === "DecoLoan"
    ) {
      if (+decoForm.roof_squares === 0) {
        setErrorMessage("Please enter a roof square count greater then zero!");
        return;
      }

      if (+decoForm.roof_cost === 0) {
        setErrorMessage("Please enter a roof cost greater then zero!");
        return;
      }
    }

    //Says this nested block is redunant try to fix
    // eslint-disable-next-line
    if (useOriginal) {
      //Says this nested block is redunant try to fix
      // eslint-disable-next-line
      if (!otherValues.designUrl) {
        // eslint-disable-next-line
        {
          setErrorMessage(
            "There isn't an Original Image to Use, Upload One Instead"
          );
          return;
        }
      }
    }
    //Says this nested block is redunant try to fix
    // eslint-disable-next-line
    if (!useOriginal) {
      //Says this nested block is redunant try to fix
      // eslint-disable-next-line
      if (!otherValues.designImageUpload) {
        // eslint-disable-next-line
        {
          setErrorMessage("Please Upload an Image");
          return;
        }
      }
    }

    if (
      otherValues.finnancialPartner === "EFSBridge" &&
      (!otherValues.efs_bridge_loan_amount ||
        !otherValues.efs_balloon_payment_amount)
    ) {
      setErrorMessage("Please enter missing EFS values");
      return;
    }

    if (!otherValues.production) {
      setErrorMessage("Please enter value for Production");
      return;
    }
    if (!otherValues.ppW) {
      setErrorMessage("Please enter PPW");
      return;
    }
    if (consumptionType === "Annual") {
      if (!otherValues.consumption) {
        setErrorMessage("Please enter value for Consumption");
        return;
      }
    }

    if (consumptionType !== "Annual") {
      let thing = monthValues.filter((month) => month.consumption === "");

      if (thing.length > 0) {
        setErrorMessage(
          `Please enter value for ${thing[0].month} Consumption -- Not Ann`
        );
        return;
      }
    }

    if (!otherValues.utilityRate) {
      setErrorMessage("Please enter a Utility Rate!");
      return;
    }

    if (Loans.includes(proposalType) && !otherValues.loanTerm) {
      setErrorMessage("Please select a Loan Term");
      return;
    }

    if (otherValues.proposalReason === "") {
      setErrorMessage("Proposal Reason Required a look above!");
      return;
    }
    if (
      otherValues.proposalReason.includes("Design Change Requested") &&
      otherValues.proposalSubreason === ""
    ) {
      setErrorMessage("Proposal Subreason required!");
      return;
    }

    if (otherValues.additionalwork[0].required === "") {
      setErrorMessage(`Please Select if Tree Work is required or not`);
      return;
    }
    if (otherValues.additionalwork[1].required === "") {
      setErrorMessage(`Please Select if Trench Work is required or not`);
      return;
    }

    if (otherValues.additionalwork[0].required) {
      if (!otherValues.additionalwork[0].img) {
        setErrorMessage(`Please Upload A Tree Work Image`);
        return;
      }
      if (
        !otherValues.additionalwork[0].treeNum ||
        +otherValues.additionalwork[0].treeNum === 0
      ) {
        setErrorMessage(`Please enter a tree number`);
        return;
      }
      if (!otherValues.additionalwork[0].remover) {
        setErrorMessage(`Please choose who is to remove trees`);
        return;
      }

      if (!otherValues.additionalwork[0].notes) {
        setErrorMessage(`Please Enter Tree Work Notes`);
        return;
      }
      if (!otherValues.additionalwork[0].estimatedCost) {
        setErrorMessage(`Please an Estimated Cost for the Tree Work`);
        return;
      }
    }
    if (otherValues.additionalwork[1].required) {
      if (!otherValues.additionalwork[1].img) {
        setErrorMessage(`Please Upload A Trench Work Image`);
        return;
      }
      if (!otherValues.additionalwork[1].notes) {
        setErrorMessage(`Please Enter Trench Work Notes`);
        return;
      }
      if (!otherValues.additionalwork[1].estimatedCost) {
        setErrorMessage(`Please an Estimated Cost for the Trench Work`);
        return;
      }
    }

    if (
      (otherValues.finnancialPartner === "EFS" ||
        otherValues.finnancialPartner === "EFSBridge") &&
      getLoanAmount(otherValues, initialFormValues) > 24850
    ) {
      return setErrorMessage(
        "EFS Loans cannot exceed a loan amount of of $24,850. Please rework proposal."
      );
    }
    //  if (otherValues.additionalwork[1].required) {

    //    if (!otherValues.additionalwork[1].estimatedCost) {
    //      setErrorMessage(`Please an Estimated Cost for the Trench Work`);
    //      return;
    //    }
    //  }

    setModalState("submitted");
    // From Here On Can Later be it's own FN call
    const body = {
      demoid: demoid,
      fid: fID,
    };
    if (body.fid !== "" && body.demoid !== "") {
      //do post
      await Axios.post(
        `https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/addpartnerid`,
        body
      );
      // alert(JSON.stringify(body, null, 2));
    }

    const utilBody = {
      demoid: demoid,
      utilityid: utilitySubmit.iis_utilityid ? utilitySubmit.iis_utilityid : "",
    };
    if (utilBody.demoid !== "" && utilBody.utilityid !== "") {
      await Axios.post(
        `https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/addutility`,
        utilBody
      );
    }

    let pdfdata = prepPDFData(
      otherValues,
      initialFormValues,
      monthValues,
      consumptionType,
      initialProposalData,
      austinEnergy,
      allPanels
    );

    let dbInfo = {
      ...pdfdata,
      preparerEmail: userName,
      preparerName: name,
    };

    const batteryValues = generateGeneracSystemValues(dbInfo, generacForm);

    if (revision) {
      let pdf = await generatePDF(pdfdata, revisionValues, revision, localProd);
      let pdfUrlLocal = pdf.data;
      setModalState("revision");
      setProposalPreview((prevState) => ({
        ...prevState,
        pdfUrl: pdfUrlLocal,
      }));

      openWindowWithPDF(pdfUrlLocal);
    } else {
      let url = "";
      // Proceed to saving to GQL
      let completedDigitalRes = await saveToDB(dbInfo, url);
      //here we submit
      const completedProposalId =
        completedDigitalRes && completedDigitalRes.data.completeProposal.id;

      const digitalUrl = `https://proposalview.momentumsolar.io/${completedProposalId}`;
      //post to update pdf url
      url = `https://proposal.momentumsolar.io/${completedProposalId}`;

      //@TO DO
      await Axios.post(
        "https://tld49hyei2.execute-api.us-east-1.amazonaws.com/latest/v1/prod/add_pdf_url",
        {
          url,
          guid: `${completedProposalId}`,
          timerequested: otherValues.timerequested,
        }
      );
      let send_body = {
        proposalSubreason: otherValues.proposalSubreason,
        shade_percent: otherValues.shade_percent ?? 0,
        guid: `${completedProposalId}`,
        tsrf: otherValues?.tsrf,
        monthly_consumption: otherValues?.monthly_consumption,
        monthly_production: otherValues?.monthly_production,
        eminem_id: otherValues?.eminemId || "",
        efs_bridge_loan_amount: otherValues.efs_bridge_loan_amount,
        efs_balloon_payment_amount: otherValues.efs_balloon_payment_amount,
      };

      if (
        initialFormValues.State === "RI" &&
        !otherValues.proposalType.includes("IGS") &&
        !otherValues.proposalType.includes("MSES")
      ) {
        if (+otherValues.tsrf >= 80) {
          send_body.qualifies_for_tsrf_rebate = true;
        } else {
          send_body.qualifies_for_pbi = true;
        }
      }

      // ADD battery values
      if (batteryValues) {
        console.log("HAS BATTERY VALUES");
        send_body.batterySystemSize = batteryValues.batterySystemSize;
        send_body.battery_make_model = generacForm.batteryModel.name;
        send_body.batteryCost = batteryValues.batteryCost;
        send_body.batteryModuleQuantity = batteryValues.batteryModuleQuantity;
      }

      console.log("Body", send_body);

      await Axios.post(
        `https://tld49hyei2.execute-api.us-east-1.amazonaws.com/latest/v1/prod/add_new_keys`,
        send_body
      );

      //if we got some deco data
      if (
        otherValues.proposalType === "DecoCash" ||
        otherValues.proposalType === "DecoLoan"
      ) {
        let send_body = {
          ...decoForm,
          proposalSubreason: otherValues.proposalSubreason,
          shade_percent: +otherValues.shade_percent ?? 0,
          guid: `${completedProposalId}`,
        };
        await Axios.post(
          `https://tld49hyei2.execute-api.us-east-1.amazonaws.com/latest/v1/prod/add_new_keys`,
          send_body
        );
      }
      let newDigital =
        otherValues.proposalType === "DecoCash" ||
        otherValues.proposalType === "DecoLoan"
          ? "none"
          : url;

      //do a lookup
      let prop_mb = await Axios.post(
        `https://api.momentumsolar.io/momentum_db/mongo/get_all`,
        {
          type: "proposals",
          collection: "completedproposals",
          query: {
            _id: `${completedProposalId}`,
          },
        }
      );

      //now we need to post to SQL

      let s = prop_mb.data[0];
      const validate = (value, type) => {
        if (!value) {
          if (type === "number") {
            return 0;
          } else if (typeof value === "Boolean") {
            return `'${value}'`;
          } else {
            return null;
          }
        }
        if (type === "string") {
          return `'${value}'`;
        } else if (type === "stringify") {
          return `'${JSON.stringify(value)}'`;
        } else if (type === "number") {
          return +value;
        } else {
          return null;
        }
      };

      const specialCharSafety = (string) => {
        return `${string}`
          .replace(/(\r\n|\n|\r)/gm, " ")
          .replace(/\s\s+/g, " ")
          .replace(/'/g, "''")
          .trim();
      };

      let query = null;

      if (batteryValues) {
        query = `insert into [MongoDB].[dbo].[Completedproposals] (_id, battery_module_quantity, battery_make_model, battery_cost, City, Zip, RepName, RepEmail, inverter, oppnum, production, Address, panels, State, panelWattage, inverterWattage, size, consumption, utilityRate, solarRate, wholesaleRate, ppW, EPC, downPayment, loanAmount, loanTerm, loanPayment, interest, offset, oldAnnualBill, utilityEscalator, solarLoanITC, SRECPrice, rebate, proposalType, solarEscalator, solarPanel, preparerEmail, __v, additionalwork, annualPVDeg, annualSolarBill, completedOn, completedOnUnix, createdAt, CustomerName, designUrl, finnancialPartner, lidar, msesPrime, oldMonthlyBill, panelInfo, pdfUrl, pimg, preparerName, proposalDraftNum, proposalReason, proposalwon, pvGenArray, repnotes, solarSavings, timeAssigned, timeAssignedUnix, updatedAt, CreatedBy, timerequested, timerequested_unix, proposalSubreason, shade_percent) values ('${
          s._id
        }', '${send_body.batteryModuleQuantity}', '${
          send_body.battery_make_model
        }', '${send_body.batteryCost}', ${validate(
          s.City?.replaceAll("'", "''"),
          "string"
        )}, ${validate(s.Zip, "string")}, ${validate(
          s.RepName?.replaceAll("'", "''"),
          "string"
        )}, ${validate(s.RepEmail, "string")}, ${validate(
          s.inverter,
          "string"
        )},${validate(s.oppnum, "string")},${validate(
          s.production,
          "number"
        )},${validate(s.Address?.replaceAll("'", "''"), "string")},${validate(
          s.panels,
          "number"
        )},${validate(s.State, "string")},${validate(
          s.panelWattage,
          "number"
        )},${validate(s.inverterWattage, "number")},${validate(
          s.size,
          "number"
        )},${validate(s.consumption, "number")},${validate(
          s.utilityRate,
          "number"
        )},${validate(s.solarRate, "number")},${validate(
          s.wholesaleRate,
          "number"
        )},${validate(s.ppW, "number")},${validate(s.EPC, "number")},${validate(
          s.downPayment,
          "number"
        )},${validate(s.loanAmount, "number")},${validate(
          s.loanTerm,
          "number"
        )},${validate(s.loanPayment, "number")},${validate(
          s.interest,
          "number"
        )},${validate(s.offset, "number")},${validate(
          s.oldAnnualBill,
          "number"
        )},${validate(s.utilityEscalator, "number")},${validate(
          s.solarLoanITC,
          "number"
        )},${validate(s.SRECPrice, "number")},${validate(
          s.rebate,
          "number"
        )},${validate(s.proposalType, "string")},${validate(
          s.solarEscalator,
          "number"
        )},${validate(s.solarPanel, "string")},${validate(
          s.preparerEmail,
          "string"
        )},${validate(s.__v, "number")},${validate(
          s.additionalwork,
          "stringify"
        )},${validate(s.annualPVDeg, "string")},${validate(
          s.annualSolarBill,
          "string"
        )},${validate(s.completedOn, "string")},${validate(
          s.completedOnUnix,
          "string"
        )}, ${validate(s.createdAt, "string")},${validate(
          specialCharSafety(s.CustomerName),
          "string"
        )},${validate(s.designUrl, "string")},${validate(
          s.finnancialPartner,
          "string"
        )},${validate(s.lidar, "string")},${validate(
          s.msesPrime,
          "string"
        )},${validate(s.oldMonthlyBill, "string")},${validate(
          s.panelInfo,
          "string"
        )},${validate(s.pdfUrl, "string")},${validate(
          s.pimg,
          "stringify"
        )},${validate(
          s.preparerName?.replaceAll("'", "''"),
          "string"
        )},${validate(s.proposalDraftNum, "string")},${validate(
          s.proposalReason,
          "string"
        )},${validate(s.proposalwon, "string")},${validate(
          s.pvGenArray,
          "stringify"
        )},${validate(s.repnotes, "string")},${validate(
          s.solarSavings,
          "stringify"
        )},${validate(s.timeAssigned, "string")},${validate(
          s.timeAssignedUnix,
          "string"
        )},${validate(s.updatedAt, "string")},'SQL MICRO', ${validate(
          s.timerequested,
          "string"
        )},${validate(s.timerequested_unix, "string")},${validate(
          s.proposalSubreason,
          "string"
        )},${validate(s.shade_percent, "number")} )`;
      } else {
        query = `insert into [MongoDB].[dbo].[Completedproposals] (_id, City, Zip, RepName, RepEmail, inverter, oppnum, production, Address, panels, State, panelWattage, inverterWattage, size, consumption, utilityRate, solarRate, wholesaleRate, ppW, EPC, downPayment, loanAmount, loanTerm, loanPayment, interest, offset, oldAnnualBill, utilityEscalator, solarLoanITC, SRECPrice, rebate, proposalType, solarEscalator, solarPanel, preparerEmail, __v, additionalwork, annualPVDeg, annualSolarBill, completedOn, completedOnUnix, createdAt, CustomerName, designUrl, finnancialPartner, lidar, msesPrime, oldMonthlyBill, panelInfo, pdfUrl, pimg, preparerName, proposalDraftNum, proposalReason, proposalwon, pvGenArray, repnotes, solarSavings, timeAssigned, timeAssignedUnix, updatedAt, CreatedBy, timerequested, timerequested_unix, proposalSubreason, shade_percent) values ('${
          s._id
        }', ${validate(s.City?.replaceAll("'", "''"), "string")}, ${validate(
          s.Zip,
          "string"
        )}, ${validate(s.RepName?.replaceAll("'", "''"), "string")}, ${validate(
          s.RepEmail,
          "string"
        )}, ${validate(s.inverter, "string")},${validate(
          s.oppnum,
          "string"
        )},${validate(s.production, "number")},${validate(
          s.Address?.replaceAll("'", "''"),
          "string"
        )},${validate(s.panels, "number")},${validate(
          s.State,
          "string"
        )},${validate(s.panelWattage, "number")},${validate(
          s.inverterWattage,
          "number"
        )},${validate(s.size, "number")},${validate(
          s.consumption,
          "number"
        )},${validate(s.utilityRate, "number")},${validate(
          s.solarRate,
          "number"
        )},${validate(s.wholesaleRate, "number")},${validate(
          s.ppW,
          "number"
        )},${validate(s.EPC, "number")},${validate(
          s.downPayment,
          "number"
        )},${validate(s.loanAmount, "number")},${validate(
          s.loanTerm,
          "number"
        )},${validate(s.loanPayment, "number")},${validate(
          s.interest,
          "number"
        )},${validate(s.offset, "number")},${validate(
          s.oldAnnualBill,
          "number"
        )},${validate(s.utilityEscalator, "number")},${validate(
          s.solarLoanITC,
          "number"
        )},${validate(s.SRECPrice, "number")},${validate(
          s.rebate,
          "number"
        )},${validate(s.proposalType, "string")},${validate(
          s.solarEscalator,
          "number"
        )},${validate(s.solarPanel, "string")},${validate(
          s.preparerEmail,
          "string"
        )},${validate(s.__v, "number")},${validate(
          s.additionalwork,
          "stringify"
        )},${validate(s.annualPVDeg, "string")},${validate(
          s.annualSolarBill,
          "string"
        )},${validate(s.completedOn, "string")},${validate(
          s.completedOnUnix,
          "string"
        )}, ${validate(s.createdAt, "string")},${validate(
          specialCharSafety(s.CustomerName),
          "string"
        )},${validate(s.designUrl, "string")},${validate(
          s.finnancialPartner,
          "string"
        )},${validate(s.lidar, "string")},${validate(
          s.msesPrime,
          "string"
        )},${validate(s.oldMonthlyBill, "string")},${validate(
          s.panelInfo,
          "string"
        )},${validate(s.pdfUrl, "string")},${validate(
          s.pimg,
          "stringify"
        )},${validate(
          s.preparerName?.replaceAll("'", "''"),
          "string"
        )},${validate(s.proposalDraftNum, "string")},${validate(
          s.proposalReason,
          "string"
        )},${validate(s.proposalwon, "string")},${validate(
          s.pvGenArray,
          "stringify"
        )},${validate(s.repnotes, "string")},${validate(
          s.solarSavings,
          "stringify"
        )},${validate(s.timeAssigned, "string")},${validate(
          s.timeAssignedUnix,
          "string"
        )},${validate(s.updatedAt, "string")},'SQL MICRO', ${validate(
          s.timerequested,
          "string"
        )},${validate(s.timerequested_unix, "string")},${validate(
          s.proposalSubreason,
          "string"
        )},${validate(s.shade_percent, "number")} )`;
      }

      console.log("before query");
      //hit a query
      console.log(query);
      if (query) {
        await Axios.post(
          `https://api.momentumsolar.io/internal/proposals/insert_sql`,
          {
            query,
          }
        );
      }

      console.log("after query");
      setProposalPreview((prevState) => ({
        ...prevState,
        digitalUrl: newDigital,
        pdfUrl: url,
        completedProposalId,
      }));
      const proposalViewerAppWindow = window.open(
        url,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  const generateGeneracSystemValues = (data, generacFormData) => {
    const { ppw, panelWattage, paymentFactor, loanAmount } = data;
    const {
      batteryModuleQty,
      batteryCost,
      enphaseRebate,
      batteryRebate,
      batteryModel,
    } = generacFormData;

    let GeneracSystemInput;
    if (proposalType === "GeneracLoan" || proposalType === "JEALoan") {
      GeneracSystemInput = {
        ...getGeneracLoanValues(
          batteryModuleQty,
          ppw,
          panelWattage,
          paymentFactor,
          batteryCost,
          loanAmount,
          enphaseRebate,
          batteryRebate,
          batteryModel
        ),
      };
    } else {
      GeneracSystemInput = null;
    }

    return GeneracSystemInput;
  };

  const saveToDB = async (data, oldPdfUrl) => {
    setProposalPreview((prevState) => ({
      ...prevState,
      userId: _id,
      proposalId: initialProposalData ? initialProposalData.id : null,
    }));

    let pdfUrl = oldPdfUrl;
    try {
      let initialDataCopy = { ...initialProposalData };
      delete initialDataCopy.id;
      let CompletedProposalInput = {
        ...cleanNumbers(
          deleteKeys(pullEmpties(data), deleteThese),
          needsToBeInt
        ),

        additionalwork: addWorkVals(data.additionalwork),
        pvGenArray: JSON.stringify(pvGen(initialProposalData, otherValues)),
        pdfUrl,
        annualPVDeg: 0.005,
        offset: getOffset(otherValues),
        oldMonthlyBill: getMonthlyBill(
          otherValues,
          monthValues,
          consumptionType
        ),
        ...initialDataCopy,
      };
      console.log(CompletedProposalInput);

      let variables = {
        CompletedProposalInput,
        user: _id,
        GeneracSystemInput: generateGeneracSystemValues(data, generacForm),
      };

      if (initialProposalData && initialProposalData.id !== "") {
        variables = {
          ...variables,
          proposalId: initialProposalData.id,
        };
      }
      variables.CompletedProposalInput.panels = +data.panels;
      variables.CompletedProposalInput.production = +data.production;
      variables.CompletedProposalInput.designUrl = otherValues.designUrl;
      variables.CompletedProposalInput.size = data.size;
      variables.CompletedProposalInput.msesPrime =
        otherValues.msesPrime === "" || otherValues.msesPrime === false
          ? false
          : true;
      console.log("------MB_NEWPROP------");
      delete variables.CompletedProposalInput.timerequested;
      delete variables.CompletedProposalInput.eminemId;
      delete variables.CompletedProposalInput.proposalSubreason;
      delete variables.CompletedProposalInput.shade_percent;
      delete variables.CompletedProposalInput.tsrf;
      delete variables.CompletedProposalInput.monthly_consumption;
      delete variables.CompletedProposalInput.monthly_production;
      delete variables.CompletedProposalInput.qualifies_for_tsrf_rebate;
      delete variables.CompletedProposalInput.qualifies_for_pbi;
      delete variables.CompletedProposalInput.efs_bridge_loan_amount;
      delete variables.CompletedProposalInput.efs_balloon_payment_amount;

      //The GQL only accepts id for panel info
      if (variables.CompletedProposalInput.panelInfo?._id)
        variables.CompletedProposalInput.panelInfo =
          variables.CompletedProposalInput.panelInfo._id;

      console.log({
        variables,
      });
      console.log({ otherValues });
      console.log("------MB_NEWPROP------");

      const completedres = await completeProposal({
        variables,
        refetchQueries: [
          {
            query: USER_BY_EMAIL_QUERY,
            variables: { proposalAgentEmail: userName },
          },
        ],
      });
      setModalState("completedproposal");
      const completedProposalId = completedres.data.completeProposal.id;
      console.log("after completed res", completedres);
      pdfUrl = `https://proposal.momentumsolar.io/${completedProposalId}`;
      //@UPDATE : ADDED SLACK HERE
      if (dash_type === "SLACK") {
        console.log({
          opportunityNumber: variables.CompletedProposalInput.oppnum,
          proposalType: variables.CompletedProposalInput.proposalType,
          _PPW: variables.CompletedProposalInput.ppW
            ? +variables.CompletedProposalInput.ppW
            : 0,
          _Size: variables.CompletedProposalInput.size
            ? +variables.CompletedProposalInput.size
            : 0,
          _Production: variables.CompletedProposalInput.production
            ? +variables.CompletedProposalInput.production
            : 0,
          "_Proposal Notes": "n/a",
          "_Proposal Link": pdfUrl,
          _EV: "",
          Design_Image: variables.CompletedProposalInput.designUrl,
        });
        const res = await Axios.post(
          `https://api.momentumsolar.io/slack-proposal-app/proposal/submission_async`,
          {
            opportunityNumber: variables.CompletedProposalInput.oppnum,
            proposalType: variables.CompletedProposalInput.proposalType,
            _PPW: variables.CompletedProposalInput.ppW
              ? +variables.CompletedProposalInput.ppW
              : 0,
            _Size: variables.CompletedProposalInput.size
              ? +variables.CompletedProposalInput.size
              : 0,
            _Production: variables.CompletedProposalInput.production
              ? +variables.CompletedProposalInput.production
              : 0,
            "_Proposal Notes": "n/a",
            "_Proposal Link": pdfUrl,
            _EV: "",
            Design_Image: variables.CompletedProposalInput.designUrl,
          }
        );
        console.log("after submission async", res);
      }
      //@UPDATE: ENDED SLACK HERE
      // const digitalUrl = `https://dev.momentumsolar.app/home/${completedProposalId}`;
      setProposalPreview((prevState) => ({
        ...prevState,
        pdfUrl,
        digitalUrl: pdfUrl,
        completedProposalId,
      }));
      sendEmail2(CompletedProposalInput, { userName, name }, true);
      resetWork();
      setOtherValues((prevState) => ({
        ...prevState,
        proposalReason: "",
      }));
      clearGenerac();
      return completedres;
    } catch (error) {
      // Send Slack / Email if fails also infrom user
      PostDigitalFail(error, data.oppnum, data.preparerEmail);
      setModalState("digitalerror");
      console.log(error);
      return error;
    }
  };

  const openWindowWithPDF = (pdfUrl) => window.open(pdfUrl, "_newtab");

  const checkNextStep = (e) => {
    e.persist();
    e.preventDefault();
    const { otherValuesStatus, otherValuesMsg } = validateOtherValues(
      otherValues,
      initialFormValues
    );
    const { initialValuesStatus, initialOtherValuesMsg } =
      validateValues(initialFormValues);

    if (!initialValuesStatus || !otherValuesStatus) {
      setErrorMessage(initialOtherValuesMsg || otherValuesMsg);
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }

    //we want to hit api to get utilities
    Axios.post(
      "https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/utiliteslist"
    )
      .then((v) => {
        console.log(v.data);
        setUtilityList(v.data);
        setNext(true);
      })
      .catch((e) => {
        setNext(true);
      });
  };

  const runOppLokup = (e) => {
    Axios.get(
      `https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/searchopn/OP-${initialFormValues.oppnum.replace(
        /\D/g,
        ""
      )}`
    )
      .then((v) => {
        if (v.data.length > 0) {
          let valid = v.data[0];
          let household = valid.mts_Household;
          let person = valid.mts_PrimaryContact.fullname;
          let updated_form = {
            ...initialFormValues,
            State:
              household["iis_state@OData.Community.Display.V1.FormattedValue"],
            Address: household.address1_line1,
            City: household.address1_city,
            Zip: household.address1_postalcode,
            CustomerName: person,
          };
          setInitialValues(updated_form);
          setDemoId(valid.mts_demoappointmentid);
        }
      })
      .catch((e) => {});
  };

  const onClickBack = (e) => {
    e.preventDefault();
    setOtherValues({
      ...otherValues,
      loanTerm: "",
      interest: "",
      msesPrime: "",
      paymentFactor: "",
      rebate: "",
      // proposalReason: "",
    });
    clearGenerac();

    setNext(false);
  };

  const handleDropChange2 = (e) => {
    setInitialValues({
      ...initialFormValues,
      [e.target.name]: e.target.value,
    });
    setOtherValues({ ...otherValues });
  };

  const handleDropChange = (e) => {
    let product;
    if (e.target.name === "solarPanel") {
      product = allPanels.find((prod) => prod.name === e.target.value);

      setOtherValues({
        ...otherValues,
        solarPanel: e.target.value,
        panelWattage: `${product.pmax}`,
        panelImageUrl: product.productImage && product.productImage.panelUrl,
        panelInfo: product && product._id,
      });
    } else if (e.target.name === "proposalType") {
      console.log("hits first type conditional");

      if (Generacs.includes(e.target.value)) {
        console.log("hits if type of generac confitional");
        product = inverters.find(
          (prod) => prod.name === "Enphase IQ7-60-2-US-208"
        );
        setOtherValues({
          ...otherValues,
          proposalType: e.target.value,

          inverter: product.name,
          inverterWattage: `${product.pmax}`,
        });
        return;
      } else {
        console.log("not generac conditional");
        setOtherValues({
          ...otherValues,
          [e.target.name]: e.target.value,
        });
        return;
      }
    } else if (e.target.name === "inverter") {
      product = inverters.find((prod) => prod.name === e.target.value);
      setOtherValues({
        ...otherValues,
        inverter: e.target.value,
        inverterWattage: `${product.pmax}`,
      });
    } else {
      console.log("is htis also hitting ?");
      setOtherValues({
        ...otherValues,
        [e.target.name]: e.target.value,
      });
    }
  };
  const states = [
    "GA",
    "NJ",
    "NY",
    "PA",
    "CT",
    "FL",
    "CA",
    "TX",
    "NV",
    "AZ",
    "MA",
    "RI",
  ];

  const notTX = [
    { value: "IGSPPA", name: "IGS PPA" },
    { value: "IGSLEASE", name: "IGS Lease" },
    { value: "MSES", name: "MSES" },
  ];

  const ProposalFormToReturn = () => {
    let Tag;

    const { proposalType } = otherValues;
    if (Loans.includes(proposalType)) Tag = Loan;
    if (Cashes.includes(proposalType)) Tag = Cash;
    if (IGSES.includes(proposalType)) Tag = IGSPPA;

    return (
      <Tag
        State={initialFormValues.State}
        otherValues={otherValues}
        setOtherValues={setOtherValues}
      />
    );
  };
  // dom rendering.
  return (
    <>
      <StyledCon>
        <CompletedProposal
          modalState={modalState}
          setModalState={setModalState}
        />
        <CurrProposalDataHeader />
        {initialProposalData &&
          initialProposalData.pimg &&
          initialProposalData.pimg.length > 0 && <ProposalImages />}

        <StyledFormCon>
          <form onSubmit={(e) => onSubmitForm(e)}>
            {!next ? (
              <FormItself>
                {Object.keys(initialFormValues)
                  .filter(
                    (key1) =>
                      key1 !== "State" &&
                      key1 !== "FinancerId" &&
                      key1 !== "utility"
                  )
                  .map((key) =>
                    key === "oppnum" ? (
                      <TextFieldStyles
                        name={key}
                        onBlur={runOppLokup}
                        value={initialFormValues[key]}
                        key={key}
                        onChange={(e) => changeInput(e)}
                        id={`filled-${key}`}
                        label={insertSpaceAtCaps(key)}
                        autoComplete="off"
                        error={
                          !initialFormValues[key] && key !== "FinancerId"
                            ? true
                            : false
                        }
                      />
                    ) : (
                      <TextFieldStyles
                        name={key}
                        value={initialFormValues[key]}
                        key={key}
                        onChange={(e) => changeInput(e)}
                        id={`filled-${key}`}
                        label={insertSpaceAtCaps(key)}
                        autoComplete="off"
                        error={
                          !initialFormValues[key] && key !== "FinancerId"
                            ? true
                            : false
                        }
                      />
                    )
                  )}
                <FormControlStyles>
                  <InputLabel> State </InputLabel>
                  <SelectStyles
                    value={initialFormValues.State}
                    onChange={(e) => handleDropChange2(e)}
                    name={"State"}
                  >
                    {states.sort().map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </SelectStyles>
                </FormControlStyles>

                {/* proposal types */}
                <FormControlStyles>
                  <InputLabel> Proposal Type </InputLabel>
                  <SelectStyles
                    value={otherValues.proposalType}
                    onChange={(e) => handleDropChange(e)}
                    name={"proposalType"}
                  >
                    {initialFormValues.State === "RI" && [
                      // Cant use fragment
                      //TODO: remove this whole section to enable all prop types

                      <MenuItem value={"Cash"}>Cash</MenuItem>,
                      <MenuItem value={"Loan"}>Loan</MenuItem>,
                      <MenuItem value={"GeneracLoan"}>
                        Enphase Solar + Storage Loan
                      </MenuItem>,
                      <MenuItem value={"IGSPPA"}>IGS PPA</MenuItem>,
                      <MenuItem value={"IGSLEASE"}>IGS Lease</MenuItem>,
                      <MenuItem value={"MSES"}>MSES</MenuItem>,
                    ]}
                    {initialFormValues.State !== "RI" && [
                      <MenuItem value={"Cash"}>Cash</MenuItem>,
                      <MenuItem value={"Loan"}>Loan</MenuItem>,
                      <MenuItem value={"GeneracLoan"}>
                        Enphase Solar + Storage Loan
                      </MenuItem>,
                      <MenuItem value={"DecoLoan"}>Decotech Loan</MenuItem>,
                    ]}
                    {(initialFormValues.State === "FL" ||
                      initialFormValues.State === "GA" ||
                      initialFormValues.State === "TX") && (
                      <MenuItem value={"JEALoan"}>
                        Solar + Storage Loan (No Net Meter)
                      </MenuItem>
                    )}
                    {initialFormValues.State !== "TX" &&
                      initialFormValues.State !== "RI" &&
                      notTX.map(({ name, value }) => (
                        <MenuItem key={value} value={value}>
                          {name}
                        </MenuItem>
                      ))}{" "}
                    {initialFormValues.State === "TX" && (
                      <MenuItem value={"MSES"}>MSES</MenuItem>
                    )}
                  </SelectStyles>
                </FormControlStyles>
                {/* If IGS */}
                {/* {(otherValues.proposalType === "IGSPPA" ||
                  otherValues.proposalType === "IGSLEASE") && (
                  <FormControlStyles>
                    <InputLabel> IGS Type </InputLabel>
                    <SelectStyles
                      value={otherValues.proposalType}
                      onChange={(e) => handleDropChange(e)}
                      name={"proposalType"}
                    >
                      <MenuItem value={"IGSPPA"}>PPA</MenuItem>
                      <MenuItem value={"IGSLEASE"}>Loan</MenuItem>
                    </SelectStyles>
                  </FormControlStyles>
                )} */}
                {Loans.includes(otherValues.proposalType) && (
                  // Proposal Fiannacial Partner
                  // Render this dropdown if loan selected
                  <FormControlStyles>
                    <InputLabel> Select Finnancial Partner </InputLabel>
                    <SelectStyles
                      value={otherValues.finnancialPartner}
                      onChange={(e) => handleDropChange(e)}
                      name={"finnancialPartner"}
                    >
                      {/* <MenuItem value={"GreenSky"}>Green Sky</MenuItem> */}
                      <MenuItem value={"Dividend"}>Dividend</MenuItem>
                      <MenuItem value={"EnFin"}>EnFin</MenuItem>
                      <MenuItem value={"Sunlight"}>Sunlight</MenuItem>
                      {initialFormValues.State === "NY" &&
                        otherValues.proposalType === "Loan" && [
                          <MenuItem value={"EFS"}>EFS</MenuItem>,
                          <MenuItem value={"EFSBridge"}>EFSBridge</MenuItem>,
                        ]}
                    </SelectStyles>
                  </FormControlStyles>
                )}

                {
                  // Panel Dropdown
                }
                {initialFormValues.State === "MA" && (
                  <FormControlStyles>
                    <InputLabel> Select Utility </InputLabel>
                    <SelectStyles
                      value={initialFormValues.utility}
                      onChange={(e) => handleDropChange2(e)}
                      name={"utility"}
                    >
                      <MenuItem value={"Eversource"}>Eversource</MenuItem>
                      <MenuItem value={"NationalGrid"}>National Grid</MenuItem>
                      <MenuItem value={"Unitil"}>Unitil</MenuItem>
                    </SelectStyles>
                  </FormControlStyles>
                )}
                {initialFormValues.State === "CT" && (
                  <FormControlStyles>
                    <InputLabel> Select Utility </InputLabel>
                    <SelectStyles
                      value={initialFormValues.utility}
                      onChange={(e) => handleDropChange2(e)}
                      name={"utility"}
                    >
                      <MenuItem value={"Eversource"}>Eversource</MenuItem>
                      <MenuItem value={"UnitedIlluminating"}>
                        United Illunminating
                      </MenuItem>
                    </SelectStyles>
                  </FormControlStyles>
                )}
                {initialFormValues.State === "NY" && (
                  <FormControlStyles>
                    <InputLabel> Select Utility </InputLabel>
                    <SelectStyles
                      value={initialFormValues.utility}
                      onChange={(e) => handleDropChange2(e)}
                      name={"utility"}
                    >
                      <MenuItem value={"CentralHudson"}>
                        Central Hudson
                      </MenuItem>

                      <MenuItem value={"ConEdison"}>Con Edison</MenuItem>
                      <MenuItem value={"NationalGrid"}>National Grid</MenuItem>
                      <MenuItem value={"NYSEG"}>NYSEG</MenuItem>
                      <MenuItem value={"Orange&Rockland"}>
                        {`Orange & Rockland`}
                      </MenuItem>

                      <MenuItem value={"RG&E"}>{`RG&E`}</MenuItem>
                      <MenuItem value={"Freeport"}>Freeport</MenuItem>
                      <MenuItem value={"PSEGLI"}>PSEGLI</MenuItem>
                    </SelectStyles>
                  </FormControlStyles>
                )}
                <FormControlStyles>
                  <InputLabel> Panel </InputLabel>
                  <SelectStyles
                    value={otherValues.solarPanel}
                    onChange={(e) => handleDropChange(e)}
                    name={"solarPanel"}
                  >
                    {allPanels.map((product) => (
                      <MenuItem key={product.name} value={product.name}>
                        {product.name}
                      </MenuItem>
                    ))}
                  </SelectStyles>
                </FormControlStyles>
                <FormControlStyles>
                  <InputLabel> Inverter </InputLabel>
                  <SelectStyles
                    value={otherValues.inverter}
                    onChange={(e) => handleDropChange(e)}
                    name={"inverter"}
                  >
                    {inverters.map((inverter) => (
                      <MenuItem key={inverter.name} value={inverter.name}>
                        {inverter.name}
                      </MenuItem>
                    ))}
                  </SelectStyles>
                </FormControlStyles>
              </FormItself>
            ) : (
              ProposalFormToReturn()
            )}

            {!next && (
              <div style={{ margin: "auto", marginTop: "1em" }}>
                <LoadDataFromProposal
                  initialFormValues={initialFormValues}
                  setInitialFormValues={setInitialValues}
                  setInitialProposalData={setInitialProposalData}
                  allProducts={allProducts}
                />
              </div>
            )}

            <SubmissionDiv>
              <p style={{ color: "#f44336", fontSize: "14px" }}>
                {errorMessage}
              </p>
              <br />
              {!next ? (
                <UserCallToAction>
                  <button onClick={(e) => checkNextStep(e)}>Continue</button>
                </UserCallToAction>
              ) : (
                <ButtonCon>
                  <UserCallToAction>
                    <button
                      disabled={modalState}
                      onClick={(e) => onClickBack(e)}
                    >
                      Back
                    </button>
                  </UserCallToAction>
                  <UserCallToAction>
                    <button
                      disabled={modalState}
                      onClick={(e) => onSubmitForm(e)}
                      type="submit"
                    >
                      Submit{modalState && "ting"}
                    </button>
                  </UserCallToAction>
                </ButtonCon>
              )}
            </SubmissionDiv>
          </form>
        </StyledFormCon>
      </StyledCon>
    </>
  );
};

export default StartProposalV2;

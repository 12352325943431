import { postSlackError } from "../../utils/API";

export const PostDigitalFail = (error, oppnum, preparerEmail) =>
  postSlackError({
    text: `\n \n 
    Error with digital proposal (MONGO)
     \n
    Preparer Email : ${preparerEmail} 
    \n
    Oppnum: ${oppnum}
    Error:
     \n
     ${JSON.stringify(error)}`,
  });

export const PostAllFail = (error, oppnum, preparerEmail) =>
  postSlackError({
    text: `\n \n 
    Error with digital proposal (MONGO)
     \n
    Preparer Email : ${preparerEmail} 
    \n
    Oppnum: ${oppnum}
    Error:
     \n
     ${JSON.stringify(error)}`,
  });

export const PostPDFail = (error, oppnum, preparerEmail) =>
  postSlackError({
    text: `\n \n 
    Error with digital proposal (MONGO)
     \n
    Preparer Email : ${preparerEmail} 
    \n
    Oppnum: ${oppnum}
    Error:
     \n
     ${JSON.stringify(error)}`,
  });

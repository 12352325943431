import React from "react";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { StylesProvider } from "@material-ui/core/styles";
import AppRouter from "./routers/AppRouter";
import ProposalContextProvider from "./Context/ProposalContext";
import BatteryContextProvider from "./Context/BatteryContext";
import ModalContextProvider from "./Context/ModalContext";
import DecoTechContextProvider from "./Context/DecoTechContext";

const theme = {
  lightBlue: "#3cc7fa",
  midnightBlue: "#001649",

  momLightBlue: `#299aff`,
  slateGrey: "#6f7d8B",
  cadetBlue: "#658eb3",
  challengeGreen: "#49B54A",
  alertRed: "#FF0000",
  backgroundDarkGrey: "#f5f5f5",
  backgroundLightGrey: "#e5e5e5",
  font: "Montserrat, sans-serif;",
};

export const client = new ApolloClient({
  uri: "https://ttwngt02f2.execute-api.us-east-1.amazonaws.com/dev/graphql",
});

const App = () => {
  return (
    <StylesProvider injectFirst>
      <ApolloProvider client={client}>
        <ProposalContextProvider>
          <BatteryContextProvider>
            <DecoTechContextProvider>
              <ModalContextProvider>
                <ThemeProvider theme={theme}>
                  <div className="App-container">
                    <AppRouter />
                  </div>
                </ThemeProvider>
              </ModalContextProvider>
            </DecoTechContextProvider>
          </BatteryContextProvider>
        </ProposalContextProvider>
      </ApolloProvider>
    </StylesProvider>
  );
};

export default App;

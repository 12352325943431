import { gql } from "apollo-boost";

export const COMPLETED_PROPOSAL_SEARCH_QUERY = gql`
  query completedProposals($oppnum: String, $_id: ID, $proposalType: String) {
    completedProposals(
      oppnum: $oppnum
      _id: $_id
      proposalType: $proposalType
    ) {
      id
      panels
      panelWattage
      inverterWattage
      size
      consumption
      utilityRate
      utility
      solarRate
      wholesaleRate
      partner
      ppW
      EPC
      downPayment
      loanAmount
      loanTerm
      loanPayment
      interest
      offset
      completedOn
      completedOnUnix
      oldAnnualBill
      oldMonthlyBill
      utilityEscalator
      solarLoanITC
      annualPVDeg
      SRECPrice
      paymentFactor
      rebate
      proposalType
      solarEscalator
      inverter
      solarPanel
      production
      preparerEmail
      preparerName
      activityid
      RepName
      RepEmail
      CustomerName
      oppnum
      Address
      City
      State
      Zip
      designUrl
      annualElectricityCost
      solarSavings
      pvGenArray
      pdfUrl
      pimg
      proposalDraftNum
    }
  }
`;

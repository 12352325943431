import React from "react";
import { tableHeaders } from "../Completed/Completed";
import ProposalTable from "../../components/ProposalTable/ProposalTable";
import { Public } from "@material-ui/icons";
import DispatchProposalStart from "../../components/DIspatchProposalStart/DispatchProposalStart";

const CompletedToday = ({ completedToday }) => {
  const newData = completedToday.reduce((arr, curr) => {
    arr.push({
      ...curr,
      navButton: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://proposal.momentumsolar.app/home/${curr.id}`}
        >
          <Public />
        </a>
      ),
      revision: <DispatchProposalStart data={curr} revision={false} />,
      hoarevision: <DispatchProposalStart data={curr} revision={true} />,
    });

    return arr.sort((a, b) => (a.completedOnUnix < b.completedOnUnix ? 1 : -1));
  }, []);
  return <ProposalTable data={newData} tableHeaders={tableHeaders} />;
};

export default CompletedToday;

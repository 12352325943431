import React, { useContext } from "react";
import { CurrPropHeader } from "../CurrProposalHeader/styles";
import BatteryContextProvider from "../../Context/BatteryContext";

export const BatteryHeader = (props) => {
  const {
    batteryFormValues,

    otherBatteryValues,
  } = useContext(BatteryContextProvider);

  const { CustomerName, RepEmail } = batteryFormValues;
  const { state } = otherBatteryValues;
  return (
    <>
      <CurrPropHeader>
        <div className="category">
          <h3> Customer Name</h3>
          <h4> {CustomerName}</h4>
        </div>

        <div className="category">
          <h3> State </h3>
          <h4> {state} </h4>
        </div>

        <div className="category">
          <h3> Proposal Type</h3>
          <h4> Battery Proposal </h4>
        </div>

        <div className="category">
          <h3> Sales Rep Name</h3>
          <h4> {RepEmail} </h4>
        </div>
      </CurrPropHeader>
    </>
  );
};

export default BatteryHeader;

import React from "react";
import { DeleteForever } from "@material-ui/icons";

import { useMutation } from "@apollo/react-hooks";
import {
  REMOVE_INVERTER_MUTATION,
  REMOVE_PANEL_MUTATION,
  ALL_PRODUCTS_QUERY
} from "../utils/ProductsQueries";

const DeleteProduct = props => {
  const { activeProducts, _id } = props;
  const [removePanel] = useMutation(REMOVE_PANEL_MUTATION, {
    variables: {
      _id
    },
    refetchQueries: [{ query: ALL_PRODUCTS_QUERY }]
  });

  const [removeInverter] = useMutation(REMOVE_INVERTER_MUTATION, {
    variables: {
      _id
    },
    refetchQueries: [{ query: ALL_PRODUCTS_QUERY }]
  });

  return (
    <DeleteForever
      onClick={() =>
        activeProducts === "allPanels" ? removePanel() : removeInverter()
      }
    />
  );
};

export default DeleteProduct;

import React from "react";

import { tableHeaders } from "../Assign/Assign";
import ProposalTable from "../../components/ProposalTable/ProposalTable";

const AllProposalsView = ({ allRequestedProposals }) => {
  let newTableHeaders = [
    ...tableHeaders,
    { id: "10", label: "Take Proposal", key: "take" },
  ];

  const newestTableHeaders = newTableHeaders.filter(
    (header) => header.key !== "assign" && header.key !== "drop"
  );

  const newData = allRequestedProposals.reduce((prev, curr) => {
    curr = {
      ...curr,
    };
    prev.push(curr);
    return prev;
  }, []);

  return (
    <ProposalTable
      data={newData}
      tableHeaders={newestTableHeaders}
      nodispatch={true}
    />
  );
};

export default AllProposalsView;

import React, { useContext } from "react";
import { TextFieldStyles } from "../Refactored/StartProposalV2/styles";

import { Context } from "../Context/ProposalContext";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const Rebate = () => {
  const {
    otherValues,
    setOtherValues,
    initialFormValues,
    austinEnergy,
    setAustinEnergy,
  } = useContext(Context);
  const changeInput = (e) => {
    setOtherValues({
      ...otherValues,
      [e.target.name]: e.target.value,
    });
  };

  const hasRebate = ["TX", "NY", "CT", "AZ"];
  const austin = ["Loan", "GeneracLoan", "Cash"];

  return (
    <>
      {!hasRebate.includes(initialFormValues.State) ? null : (
        <>
          <TextFieldStyles
            type="number"
            name={"rebate"}
            value={otherValues.rebate}
            onChange={(e) => changeInput(e)}
            label={"Rebate"}
            autoComplete="off"
            error={!otherValues.rebate ? true : false}
            helperText={
              initialFormValues.State === "TX" &&
              austin.includes(otherValues.proposalType) && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          setAustinEnergy({
                            no: false,
                            yes: !austinEnergy.yes,
                          })
                        }
                        checked={austinEnergy.yes}
                        value={austinEnergy.yes}
                      />
                    }
                    label="Austin Energy (Y)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          setAustinEnergy({
                            yes: false,
                            no: !austinEnergy.no,
                          })
                        }
                        checked={austinEnergy.no}
                        value={austinEnergy.no}
                      />
                    }
                    label="Austin Energy (N)"
                  />
                </>
              )
            }
          />
        </>
      )}
    </>
  );
};

export default Rebate;

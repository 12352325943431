import React, { useState, useContext } from "react";
import { Clear } from "@material-ui/icons";
import ModalComp from "../ConsumptionType/Modal/Modal";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import {
  DROP_INTO_QUEUE,
  CANCEL_ASSIGNMENT_MUTATION,
} from "../../utils/QLMutations";
import {
  USER_BY_EMAIL_QUERY,
  ALL_REQUESTED_PROPOSALS,
} from "../../utils/GQL/QLQueries";
import { Context } from "../../Context/ProposalContext";

const InnerModalContent = styled.div`
  display: grid;
  height: 100%;

  grid-template-rows: 60px 2fr 1fr 1fr;
  align-items: center;

  .modal-header {
    h2 {
      margin: 0;
    }
    display: grid;
    grid-template-columns: 1fr 60px;
    align-items: center;
    justify-items: center;
  }

  .proposal-info {
    display: grid;
    width: 60%;
    margin: 0 auto;
    grid-gap: 5px;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    text-align: center;
  }

  .col {
    border: solid 2px #3db0ef;
    border-radius: 2px;
    padding: 6px;

    p {
      margin: 0;
    }
  }

  .descriptive-text {
    display: grid;
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }

  .drop-options {
    display: grid;
    justify-content: center;
    grid-auto-flow: column;
    grid-template-columns: 160px 160px;
    grid-gap: 10px;
    button {
      border: solid 2px #3db0ef;
      background: white;
      border-radius: 4px;
      padding: 8px;
      color: #3db0ef;
      font-size: 1rem;
      :hover {
        cursor: pointer;
        box-shadow: 0 0.4rem 1.4rem 0 rgba(86, 185, 235, 0.5);
        transform: translateY(-0.1rem);
        transition: transform 150ms;
      }

      [disabled]:hover {
        cursor: not-allowed;
        box-shadow: none;
        transform: none;
      }
    }
  }
`;

const DropProposal = ({ proposal, userId }) => {
  const {
    userValues: { userName },
  } = useContext(Context);

  const { Office, Address, id, RepName, oppnum } = proposal;
  const [modalOpen, setModalOpen] = useState(false);
  const [dropStatus, setDropStatus] = useState("");
  return (
    <>
      <ModalComp width={"80%"} open={modalOpen} setModalState={setModalOpen}>
        <InnerModalContent>
          <div className="modal-header">
            <h2> Select an Option Below </h2>
            <Clear onClick={() => setModalOpen(false)} />
          </div>

          <div className="proposal-info">
            <div className="row">
              <div className="col">
                <p> Sales Rep </p>
              </div>
              <div className="col">
                <p> {RepName} </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p> Address </p>
              </div>
              <div className="col">
                <p> {Address} </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p> Office </p>
              </div>
              <div className="col">
                <p> {Office} </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p> Opportunity </p>
              </div>
              <div className="col">
                <p> {oppnum} </p>
              </div>
            </div>
          </div>
          {dropStatus === "return" && (
            <DropFromPersonalQueue
              setDropStatus={setDropStatus}
              proposalId={id}
              userId={userId}
              email={userName}
            />
          )}
          {dropStatus === "drop" && (
            <DropCompletely
              setDropStatus={setDropStatus}
              proposalId={id}
              userId={userId}
              email={userName}
            />
          )}
          {dropStatus === "" && (
            <div className="drop-options">
              <button onClick={() => setDropStatus("return")}>
                Return To Queue
              </button>
              <button onClick={() => setDropStatus("drop")}>
                Drop Entirely
              </button>
            </div>
          )}
        </InnerModalContent>
      </ModalComp>
      <Clear onClick={() => setModalOpen(true)} />
    </>
  );
};

const DropCompletely = ({ setDropStatus, proposalId, userId, email }) => {
  const [cancelAssignment] = useMutation(CANCEL_ASSIGNMENT_MUTATION, {
    variables: {
      id: proposalId,
      userId,
    },
    refetchQueries: [
      { query: ALL_REQUESTED_PROPOSALS },
      {
        query: USER_BY_EMAIL_QUERY,

        variables: {
          proposalAgentEmail: email,
        },
      },
    ],
  });
  const dropCompletely = () => {
    cancelAssignment();
  };
  return (
    <>
      <div className="descriptive-text">
        <p>
          The following action will drop the Proposal entirely, you nor anybody
          on your team will be able to retrieve this proposal. The Sales Rep
          would have to request it again. This action is final. Confirm Below
        </p>
      </div>
      <div className="drop-options">
        <button onClick={() => dropCompletely()}> Yes </button>
        <button onClick={() => setDropStatus("")}> No </button>
      </div>
    </>
  );
};

const DropFromPersonalQueue = ({
  setDropStatus,
  proposalId,
  userId,
  email,
}) => {
  const [dropToQueue] = useMutation(DROP_INTO_QUEUE, {
    variables: {
      input: {
        proposalId,
        userId,
      },
    },

    refetchQueries: [
      {
        query: USER_BY_EMAIL_QUERY,

        variables: {
          proposalAgentEmail: email,
        },
      },
    ],
  });
  const dropFromPersonal = () => {
    console.log(
      `Drops the proposal with id of ${proposalId} from user with id of ${userId} back into queueu`
    );
    dropToQueue();
  };
  return (
    <>
      <div className="descriptive-text">
        <p>
          The following action will drop the Proposal from your queue only, it
          can be reassigned or re-used.
        </p>
      </div>
      <div className="drop-options">
        <button onClick={() => dropFromPersonal()}> Yes </button>
        <button onClick={() => setDropStatus("")}> No </button>
      </div>
    </>
  );
};

export default DropProposal;

// { id: "10", label: "Take Proposal", key: "take" },

import React from "react";
import styled from "styled-components";
import { Clear } from "@material-ui/icons";

const originalValues = [
  { State: "PA", set: true, key: "PA" },
  { State: "PNY", set: true, key: "Plainview NY" },
  { State: "TFL", set: true, key: "Tampa FL" },
  { State: "FTL", set: true, key: "Ft. Lauderdale FL" },
  { State: "SATX", set: true, key: "San Antonio TX" },
  { State: "DAL", set: true, key: "Dallas TX" },
  { State: "OFL", set: true, key: "Orlando FL" },
  { State: "MTNJ", set: true, key: "Metuchen NJ" },
  { State: "STCT", set: true, key: "Stamford CT" },
  { State: "OCA", set: true, key: "Orange CA" },
  { State: "AUTX", set: true, key: "Austin TX" },
  { State: "CHNJ", set: true, key: "Cherry Hill NJ" }
];

const StyledButton = styled.button`
  background-color: ${props =>
    props.clicked ? props.theme.midnightBlue : "rgba(0, 22, 73, .3)"};
  color: white;
  &:first-child {
    border-radius: 5px 0 0 5px;
  }
  &:last-child {
    border-radius: 0 5px 5px 0;
  }
  outline: none;
  padding: 5px;
  font-size: 14px;
`;
const StateFilter = ({ states, setStates }) => {
  //const checkClicked = key => !states[key];
  const handleStateClick = key => {
    const stateIndex = states.findIndex(state => state.key === key);
    // states[stateIndex].set = !states[stateIndex].set;
    const updatedState = {
      ...states[stateIndex],
      set: !states[stateIndex].set
    };
    const updatedStates = [
      ...states.slice(0, stateIndex),
      updatedState,
      ...states.slice(stateIndex + 1)
    ];

    setStates(updatedStates);
  };
  const clearStateFilters = () => setStates(originalValues);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {states.some(state => !state.set) && (
        <Clear onClick={() => clearStateFilters()} />
      )}
      {states.map(state => (
        <StyledButton
          key={state.key}
          clicked={state.set}
          onClick={() => handleStateClick(state.key)}
        >
          {state.State}
        </StyledButton>
      ))}
    </div>
  );
};

export default StateFilter;

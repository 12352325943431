import styled from "styled-components";

export const AdminHomeStyles = styled.div`
  margin-left: ${props => props.show && "75px"};
  transition: all 0.5s;
  margin-top: 100px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  align-items: center;

  .admin-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 98%;
  }

  .active-users {
    display: flex;

    width: 19%;
  }

  .false-div {
    display: flex;
    width: 20%;
  }
  .top-nav {
    display: flex;
    flex-direction: row;
    /* background: rgba(255, 255, 255, 0.5); */
    background: linear-gradient(
      135deg,
      rgba(0, 174, 239, 0.5),
      rgb(0, 22, 73, 0.5)
    );
    color: white;
    justify-content: space-around;
    width: ${props => (props.width ? props.width : "95%")};
    margin: 0 auto;
    margin-bottom: -2px;
    border-radius: 10px 10px 0 0;

    ol {
      list-style: none;
      display: flex;
      width: 60%;
      justify-content: space-between;
    }

    li {
      display: flex;
      font-size: 28px;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 0.05em;

      display: inline-block;
      padding: 15px 20px;
      position: relative;

      :hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }

    li:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: #fff;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    li:hover:after {
      width: 100%;
      left: 0;
    }

    .active {
      position: relative;

      display: inline-block;
      padding: 15px 20px;
      color: lightgray;
      opacity: 0.6;
    }
    .active:after {
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 5%;
      position: absolute;
      background: #fff;

      width: 90%;
    }
  }
`;
export const TableNavStyles = styled.div``;

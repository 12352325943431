import React, { useState } from "react";
import moment from "moment";

import styled from "styled-components";
import {
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@material-ui/core";
import QueueTimer from "./QueueTimer/QueueTimer";
import { Clear } from "@material-ui/icons";

const ActiveStyles = styled.div`
  display: flex;
  flex-direction: column;

  .active-sub {
    display: flex;
    flex-direction: row;
    :hover {
      color: lightgrey;
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .details {
    padding: 3px;
    width: 100%;
    border-radius: 10px;
    border: solid 2px lightgrey;
    display: flex;
    flex-direction: row;
    max-height: 60px;
    overflow-y: auto;
    flex-wrap: wrap;
  }

  .descriptor-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .clear {
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  /* .user {
    display: flex;
    width: 99%;
  } */
`;
const CountActive = users => {
  const currentlyActive = users.reduce((prev, curr) => {
    if (curr.UserTime) {
      // const diff = moment.unix(curr.UserTime.lastAction).fromNow();
      const differential = moment().unix() - curr.UserTime.lastAction;

      if (differential <= 5400)
        prev.push({ ...curr, lastActive: differential });
    }
    return prev;
  }, []);

  return currentlyActive;
};

const CountAvailable = users => {
  const currentlyActive = users.reduce((prev, curr) => {
    if (curr.UserTime) {
      // const diff = moment.unix(curr.UserTime.lastAction).fromNow();
      const differential = moment().unix() - curr.UserTime.lastAction;
      // seconds == 1.5 hours
      if (differential <= 5400 && curr.proposalsAssigned.length === 0)
        prev.push({ ...curr, lastActive: differential });
    }
    return prev;
  }, []);

  return currentlyActive;
};

const calcDiff = user => {
  return moment().unix() - user.UserTime.lastAction;
};

// const CountActiveOverHour = users => {
//   const currentlyActive = users.reduce((prev, curr) => {
//     if (curr.UserTime) {
//       const diff = moment.unix(curr.UserTime.lastAction).fromNow();
//       const differential = moment().unix() - curr.UserTime.lastAction;

//       if (differential > 3600) prev.push(curr);
//     }
//     return prev;
//   }, []);

//   console.log(currentlyActive);
//   return currentlyActive;
// };
const ActiveUsers = ({ users }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState("");
  const [detailStatus, setDetailStatus] = useState("");

  const onReadyDetails = detailStatusE => {
    setDetailStatus(detailStatusE);
    if (details.length === 0) {
      setDetails("The Deets");
    } else {
      setDetails("No Deets");
    }
    setShowDetails(!showDetails);
  };

  return !showDetails ? (
    <ActiveStyles>
      <Tooltip
        title={`${CountActive(users).map(user => user.proposalAgentName)}`}
      >
        <div
          onClick={() =>
            CountActive(users).length > 0 && onReadyDetails("active")
          }
          className="active-sub"
        >
          Active Users {CountActive(users).length}
        </div>
      </Tooltip>
      <div onClick={() => onReadyDetails("available")} className="active-sub">
        Available Users {CountAvailable(users).length}
      </div>
    </ActiveStyles>
  ) : (
    <ActiveUsersInfo
      detailStatus={detailStatus}
      users={users}
      onReadyDetails={onReadyDetails}
    />
  );
};

const ActiveUsersInfo = ({ detailStatus, users, onReadyDetails }) => {
  // if(detailStatus === 'active' && CountActive(users).length === 0 )
  if (detailStatus === "active")
    return (
      <ActiveStyles>
        <div className="descriptor-row">
          Active Users
          <Clear className="clear" onClick={() => onReadyDetails()}></Clear>
        </div>
        <div onClick={() => onReadyDetails()} className="details">
          <Table>
            <TableBody>
              {CountActive(users).map(user => (
                <TableRow
                  key={user.proposalAgentName}
                  style={{
                    backgroundColor:
                      calcDiff(user) > 3600 && "rgba(255, 0, 0, .6)",
                    borderRadius: "10px"
                  }}
                >
                  <TableCell style={{ padding: "2px" }}>
                    <div className="user">{user.proposalAgentName}</div>
                  </TableCell>
                  <TableCell>
                    <div className="user">
                      <QueueTimer time={user.UserTime.lastAction} />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </ActiveStyles>
    );
  if (detailStatus === "available")
    return (
      <ActiveStyles>
        <div className="descriptor-row">
          Available Users
          <Clear className="clear" onClick={() => onReadyDetails()}></Clear>
        </div>
        <div onClick={() => onReadyDetails()} className="details">
          <Table>
            <TableBody>
              {CountAvailable(users)
                .sort((a, b) =>
                  a.UserTime.lastAction > b.UserTime.lastAction ? 1 : -1
                )
                .map(user => (
                  <TableRow
                    key={user.UserTime.lastAction}
                    style={{
                      backgroundColor:
                        calcDiff(user) > 3600 && "rgba(255, 0, 0 , .6)",
                      borderRadius: "10px"
                    }}
                  >
                    <TableCell style={{ padding: "2px" }}>
                      <div className="user">{user.proposalAgentName}</div>
                    </TableCell>
                    <TableCell>
                      <div className="user">
                        <QueueTimer time={user.UserTime.lastAction} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </ActiveStyles>
    );
};

export default ActiveUsers;

import React, { useContext } from "react";
import NonUniques from "../NonUniqueInputs/NonUniques";
import {
  FormItself,
  TextFieldStyles,
} from "../../Refactored/StartProposalV2/styles";
import NonUniqueStatic from "../NotUniqueStatic/NotUniqueStatic";

import AdditionalWork from "../AdditionalWork.js/AdditionWork";
import { Context } from "../../Context/ProposalContext";

const IGSPPA = () => {
  const { otherValues, setOtherValues } = useContext(Context);
  const handleChange = (e) => {
    setOtherValues({
      ...otherValues,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <FormItself>
        <NonUniques otherValues={otherValues} setOtherValues={setOtherValues} />
        <TextFieldStyles
          name={"solarEscalator"}
          value={otherValues.solarEscalator}
          onChange={(e) => handleChange(e)}
          label={"Solar Escalator"}
          type={"number"}
          autoComplete="off"
          error={!otherValues.solarEscalator ? true : false}
        />
        <TextFieldStyles
          name={"solarRate"}
          value={otherValues.solarRate}
          onChange={(e) => handleChange(e)}
          label={"Solar Rate"}
          type={"number"}
          autoComplete="off"
          error={!otherValues.solarRate ? true : false}
        />
      </FormItself>
      <AdditionalWork />
      <NonUniqueStatic />
    </>
  );
};

export default IGSPPA;

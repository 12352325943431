import React, { useContext } from "react";
import { DecoTechContext } from "../../Context/DecoTechContext";
import { TextFieldStyles } from "../../Refactored/StartProposalV2/styles";

const DecoTechForm = ({ proposalType }) => {
  const { decoForm, setDecoForm } = useContext(DecoTechContext);

  const changeInput = (e) => {
    let toUpdate = decoForm;
    if (e.target.name === "roof_cost") {
      toUpdate["roof_cost"] = +e.target.value;
      toUpdate["roof_loan"] = +e.target.value;
    } else {
      toUpdate[e.target.name] = +e.target.value;
    }
    setDecoForm({ ...toUpdate });
  };

  return (
    <>
      <TextFieldStyles
        type="number"
        name={"roof_squares"}
        value={decoForm.roof_squares === 0 ? "" : decoForm.roof_squares}
        onChange={(e) => changeInput(e)}
        label={"Number of Roof Squares"}
        autoComplete="off"
        error={decoForm.roof_squares === 0 ? true : false}
      />
      <TextFieldStyles
        type="number"
        name={"roof_cost"}
        value={decoForm.roof_cost === 0 ? "" : decoForm.roof_cost}
        onChange={(e) => changeInput(e)}
        label={"Cost of Roof"}
        autoComplete="off"
        error={decoForm.roof_cost === 0 ? true : false}
      />
      {/* {proposalType === "DecoLoan" && (
        <TextFieldStyles
          type="number"
          name={"roof_loan"}
          value={decoForm.roof_loan}
          onChange={(e) => changeInput(e)}
          label={"Roof Loan Amount"}
          autoComplete="off"
        />
      )} */}
    </>
  );
};

export default DecoTechForm;

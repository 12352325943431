import React from "react";
import styled from "styled-components";

const CountStyles = styled.div`
  display: flex;
  margin-left: 10px;
  .circle {
    border-radius: 50%;
    background: #3db0ef;
    /* background: linear-gradient(
      45deg,
      rgb(0, 174, 239),
      rgb(0, 174, 239),
      rgb(0, 22, 73)
    ); */
    box-shadow: rgba(0, 0, 0, 0.14) 0px 12px 17px 2px,
      rgba(0, 0, 0, 0.12) 0px 5px 22px 4px, rgba(0, 0, 0, 0.2) 0px 7px 8px -4px;

    font-variant-numeric: tabular-nums;
    min-width: 30px;
    padding: 8px;
    color: white;
    font-weight: 600;
    font-size: 16px;
  }
`;
const Count = ({ count }) => {
  return (
    <CountStyles>
      <div className="circle">{count}</div>
    </CountStyles>
  );
};

export default Count;

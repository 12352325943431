import styled from "styled-components";

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${(props) => props.theme.font};

  .modalMain {
    h1 {
      display: flex;
      align-self: center;
      margin: 0;
      margin-bottom: 3%;
      margin-top: 3%;

      margin-right: 2%;
      margin-left: 2%;
      justify-content: center;
      text-align: center;
      font-size: 28px;
    }
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    border-radius: 8px;
    background-color: #ffffff;

    width: ${(props) => (props.width ? props.width : "100%")};
    @media (max-width: 800px) {
      height: 85%;
      width: 85%;
    }

    @media (max-width: 900px) {
      height: 75%;
      width: 70%;
    }
    height: 65%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: left 0.5s;
    left: ${(props) => (props.sideNavOpen ? "calc(50% + 150px)" : "50%")};
  }

  .upload {
    h1 {
      display: flex;
      align-self: center;
      margin: 0;
      margin-bottom: 4%;
      margin-top: 4%;

      margin-right: 2%;
      margin-left: 2%;
      justify-content: center;
      text-align: center;
    }
    /* display: flex;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    border-radius: 8px;
    background-color: #ffffff; */

    width: ${(props) => (props.width ? props.width : "100%")};
    @media (max-width: 800px) {
      height: 85%;
      width: 85%;
    }

    @media (max-width: 900px) {
      height: 75%;
      width: 70%;
    }
    height: 65%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: left 0.5s;
    left: ${(props) => (props.sideNavOpen ? "calc(50% + 150px)" : "50%")};
  }

  h4 {
    width: 70%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-self: center;
  }

  .lead-created {
    display: flex;
    flex-direction: column;
  }

  .progress {
    width: 80%;
    margin: 0 auto;

    h3 {
      display: flex;

      justify-content: center;
    }
  }
`;

export default Modal;

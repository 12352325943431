import React, { useContext } from "react";
import CloseX from "../../assets/X.png";
import styled from "styled-components";
import { ModalContext } from "../../Context/ModalContext";

const ModalHeaderStyles = styled.div`
  color: white;
  background: ${(props) => props.theme.momLightBlue};
  display: grid;
  grid-template-columns: 1fr 50px;
  width: 100%;
  align-items: center;
  img {
    height: 20px;
  }
`;

const ModalHeader = ({ title }) => {
  const { setModalOpen } = useContext(ModalContext);
  return (
    <ModalHeaderStyles>
      <div className="title">{title}</div>

      <div role="button" onClick={() => setModalOpen(false)} className="close">
        <img alt="close-modal" src={CloseX} />
      </div>
    </ModalHeaderStyles>
  );
};

export default ModalHeader;

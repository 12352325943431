import React, { useState } from "react";
import { TableCell, TextField } from "@material-ui/core";
import DeleteProduct from "./DeleteProduct";
import { Create, FileCopyOutlined } from "@material-ui/icons";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";
import NewProductModal from "./NewProductModal";
import { EDIT_PANEL_MUTATION } from "../utils/ProductsQueries";
import HoverImage from "./HoverImage";
import Active from "./Active";

const CreateStyles = styled(Create)`
  color: lightskyblue;

  transform: ${(props) => props.rotated === "true" && "rotate(45deg)"};
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  :hover {
    opacity: 0.6;
    cursor: pointer;
    transform: rotate(45deg);
  }
`;

const SubmitStyles = styled(FileCopyOutlined)`
  :hover {
    cursor: pointer;
  }
`;

export const ButtonStyles = styled.button`
  display: flex;
  padding: 10px;
  background-color: #3db0ef;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const EditDel = ({ product, activeProducts }) => {
  const originalProduct = { ...product };
  const [productState, setProductState] = useState(product);
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [editPanel] = useMutation(EDIT_PANEL_MUTATION);

  const onSubmitEdits = async () => {
    const objectDiff = Object.keys(productState).reduce((diff, key) => {
      if (originalProduct[key] === productState[key]) return diff;
      return {
        ...diff,
        [key]: productState[key],
      };
    }, {});
    const isEmpty =
      Object.entries(objectDiff).length === 0 &&
      objectDiff.constructor === Object;

    if (isEmpty) {
      return;
    } else {
      //const editProductRes = await
      editPanel({
        variables: {
          _id: productState._id,
          editProductInput: objectDiff,
        },
        refetchQueries: ["allProducts"],
      });
      setEditMode(false);
    }
  };

  const { name, pmax, brand, model, active, productImage, _id } = productState;

  return (
    <>
      <EditOrStatic
        content={name}
        editMode={editMode}
        productState={productState}
        setProductState={setProductState}
        open={open}
        setOpen={setOpen}
        name={"name"}
      />
      <EditOrStatic
        content={pmax}
        editMode={editMode}
        productState={productState}
        setProductState={setProductState}
        open={open}
        setOpen={setOpen}
        name={"pmx"}
      />
      <EditOrStatic
        content={brand}
        editMode={editMode}
        productState={productState}
        setProductState={setProductState}
        open={open}
        setOpen={setOpen}
        name={"brand"}
      />
      <EditOrStatic
        content={model}
        editMode={editMode}
        productState={productState}
        setProductState={setProductState}
        open={open}
        setOpen={setOpen}
        name={"model"}
      />
      <EditOrStatic
        content={active}
        editMode={editMode}
        productState={productState}
        setProductState={setProductState}
        open={open}
        _id={_id}
        setOpen={setOpen}
        name={"active"}
      />
      <EditOrStatic
        content={productImage}
        editMode={editMode}
        productState={productState}
        setProductState={setProductState}
        open={open}
        setOpen={setOpen}
        name={"productImage"}
      />
      <TableCell>
        {editMode ? (
          <SubmitStyles onClick={() => onSubmitEdits()} />
        ) : (
          <DeleteProduct activeProducts={activeProducts} _id={_id} />
        )}
        <CreateStyles
          rotated={editMode ? "true" : "false"}
          style={{ marginLeft: "8px" }}
          onClick={() => setEditMode(!editMode)}
        />
      </TableCell>
      <NewProductModal
        open={open}
        setOpen={setOpen}
        productState={productState}
        setProductState={setProductState}
      />
    </>
  );
};

const EditOrStatic = ({
  content,
  editMode,
  productState,
  setProductState,
  name,
  open,
  setOpen,
  _id,
}) => {
  const onChangeValues = (e) => {
    setProductState({
      ...productState,
      [e.target.name]: e.target.value,
    });
  };
  if (name === "active") {
    return (
      <TableCell>
        <Active active={content} _id={_id} />
      </TableCell>
    );
  }
  if (!editMode && name === "productImage")
    return (
      <TableCell>
        <HoverImage image={content} />{" "}
      </TableCell>
    );
  if (!editMode) return <TableCell>{content}</TableCell>;
  if (editMode && name === "productImage")
    return (
      <TableCell>
        <ButtonStyles onClick={() => setOpen(!open)}>
          {productState.productImage ? "Submit ->" : "Upload Image"}
        </ButtonStyles>
      </TableCell>
    );
  if (editMode)
    return (
      <TableCell>
        <TextField
          name={name}
          value={content}
          onChange={(e) => onChangeValues(e)}
        />
      </TableCell>
    );
};

export default EditDel;

import styled from "styled-components";
import { TextField, Select, FormControl } from "@material-ui/core";

export const StyledCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  margin-top: 50px;
  flex-direction: column;
`;

export const StyledFormCon = styled.div`
  display: flex;
  overflow: auto;
  background: white;
  max-width: 80%;
  min-width: 80%;
  overflow: auto;
  max-height: 100%;
  background: white;
  border-radius: 6px;
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;

  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 12px 17px 2px,
    rgba(0, 0, 0, 0.12) 0px 5px 22px 4px, rgba(0, 0, 0, 0.2) 0px 7px 8px -4px;

  form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const FormItself = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 30px;
`;

export const SubmissionDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  p {
    display: flex;
  }
  button {
    border: solid 2px #3db0ef;
    background: white;
    border-radius: 4px;
    padding: 14px;
    color: #3db0ef;
    font-size: 1.5rem;
    margin-right: 8px;

    :hover {
      cursor: pointer;
      box-shadow: 0 0.4rem 1.4rem 0 rgba(86, 185, 235, 0.5);
      transform: translateY(-0.1rem);
      transition: transform 150ms;
    }

    [disabled]:hover {
      cursor: not-allowed;
      box-shadow: none;
      transform: none;
    }

    &:focus {
      outline: 0;
    }
  }
`;

export const TextFieldStyles = styled(TextField)`
  display: flex;
  && {
    min-width: ${(props) => (props.width ? props.width : "45%")};
    /* min-width: 45%; */
    padding: 2px;
    padding-bottom: 30px;
  }
`;

export const FormControlStyles = styled(FormControl)`
  display: flex;
  && {
    min-width: 45%;
    margin-bottom: 30px;
  }
`;

export const SelectStyles = styled(Select)`
  display: flex;
  && {
    min-width: 100%;
  }
`;

export const NonUniquesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  justify-content: center;
  align-self: center;
  overflow: auto;
`;

export const ConsumptionTypeCon = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

export const NonInputStyles = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 4px;
`;

export const ButtonCon = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3 {
    font-size: 14px;
  }

  button {
    display: flex;
    color: white;
    border-radius: 5px;
    background: #3db0ef;
    font-size: 32px;
    padding: 14px;

    :hover {
      cursor: pointer;
      box-shadow: 0 0.4rem 1.4rem 0 rgba(86, 185, 235, 0.5);
      transform: translateY(-0.1rem);
      transition: transform 150ms;
    }

    [disabled]:hover {
      cursor: not-allowed;
      box-shadow: none;
      transform: none;
    }

    &:focus {
      outline: 0;
    }
  }
`;

import React, { useContext, useState } from "react";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//import { Refresh } from "@material-ui/icons";
import CarouselComponent from "../Carousel/Carousel";
import styled from "styled-components";
import { Context } from "../../Context/ProposalContext";

const ExpansionPanelStyles = styled(ExpansionPanel)`
  width: 82%;
  margin-bottom: 20px;
  border-radius: 10px;
  /* overflow: auto; */
  /* min-height: 70px;
  max-height: 1200px; */

  .headers {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;

    .content {
      display: grid;
      align-content: center;
      justify-content: center;
    }

    h2 {
      margin: 0;
    }

    p {
      font-size: 12px;
    }
  }

  .notes {
    width: 90%;
    display: grid;
    align-items: center;
    text-align: center;
  }

  h2 {
    margin: 0 auto;
  }

  .carousel-wrapper {
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .expanison-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export default function SimpleExpansionPanel() {
  const [opened, setOpened] = useState(false);
  const { initialProposalData } = useContext(Context);

  return (
    <ExpansionPanelStyles>
      <ExpansionPanelSummary
        onClick={() => setOpened(!opened)}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="headers">
          <div className="content"></div>
          <div className="content">
            <h2>Open To View Photos / Notes</h2>
          </div>
          <div className="content">
            <p>{opened && "* Click On Image (Large) to Open in New-Tab"}</p>
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="expanison-wrap">
        <div className="notes">
          <h1> Rep Notes</h1>
          <p>{initialProposalData.repnotes}</p>
        </div>
        <div className="carousel-wrapper">
          <CarouselComponent
            pimg={initialProposalData && initialProposalData.pimg}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanelStyles>
  );
}

//import moment from "moment";

const onSetRotation = (rotation, setRotation) => {
  if (rotation === 180) {
    setRotation(0);
  }

  if (rotation === 0) {
    setRotation(180);
  }
};

const handleSRECIncome = (production, State) => {
  let kwHours = production / 1000;
  let initialkwHours = kwHours;
  let remainder = 0;
  const SRECValue = State === "NJ" ? 200 : 40;
  let arr = [];
  for (let i = 0; i < 10; i++) {
    initialkwHours = i === 0 ? initialkwHours : initialkwHours * 0.995;
    let newRemainder = (initialkwHours % 1) + remainder;
    let whole = Math.floor(kwHours) + Math.floor(newRemainder);
    if (Math.floor(newRemainder) > 0) {
      remainder = remainder - 1;
    }
    let currSrec = whole * SRECValue;
    arr.push(currSrec);
  }
  return arr;
};

export const SunlightArr = [
  { term: 15, interest: 3.99, paymentFactor: 0.0054045 },
  { term: 20, interest: 5.99, paymentFactor: 0.0052783 },
  { term: 25, interest: 6.99, paymentFactor: 0.005219115 },
  { term: 25, interest: 4.49, paymentFactor: 0.0040463 },
];

export const DividendArr = [
  // { term: 12, interest: 6.49, paymentFactor: 0.0073312 },
  { term: 12, interest: 5.99, paymentFactor: 0.0071206 },
  { term: 12, interest: 4.99, paymentFactor: 0.0067108 },
  { term: 12, interest: 7.99, paymentFactor: 0.0079862 },
  { term: 20, interest: 5.49, paymentFactor: 0.0049908 },
  { term: 20, interest: 4.99, paymentFactor: 0.0047744 },
  { term: 20, interest: 4.49, paymentFactor: 0.0045636 },
  { term: 20, interest: 2.99, paymentFactor: 0.0039656 },
  { term: 25, interest: 6.99, paymentFactor: 0.0051674 },
  { term: 25, interest: 4.99, paymentFactor: 0.0042252 },
  { term: 25, interest: 4.49, paymentFactor: 0.0040058 },
  { term: 25, interest: 3.99, paymentFactor: 0.0037928 },
  { term: 25, interest: 2.99, paymentFactor: 0.0033874 },
];
export const DividendKeyBank = [
  // { term: 12, interest: 6.49, paymentFactor: 0.0073312 },
  { term: 12, interest: 5.99, paymentFactor: 0.0071206 },
  { term: 12, interest: 4.99, paymentFactor: 0.0067108 },
  { term: 12, interest: 7.99, paymentFactor: 0.0079862 },
  { term: 20, interest: 5.49, paymentFactor: 0.0049908 },
  { term: 20, interest: 4.99, paymentFactor: 0.0047744 },
  { term: 20, interest: 4.49, paymentFactor: 0.0045636 },
  { term: 20, interest: 2.99, paymentFactor: 0.0039656 },
  { term: 25, interest: 6.99, paymentFactor: 0.0051674 },
  { term: 25, interest: 4.99, paymentFactor: 0.0042252 },
  { term: 25, interest: 4.49, paymentFactor: 0.0040058 },
  { term: 25, interest: 3.99, paymentFactor: 0.0037928 },
  { term: 25, interest: 2.99, paymentFactor: 0.0033874 },
];

export const EnFinLoanOptions = [
  { term: 15, interest: 5.99, paymentFactor: 0.006181538 },
  { term: 20, interest: 5.99, paymentFactor: 0.005247235 },
  { term: 25, interest: 4.99, paymentFactor: 0.004249621 },
];

export const EFSLoanOptions = [
  { term: 5, interest: 3.99, paymentFactor: 0.01841 },
  { term: 5, interest: 7.49, paymentFactor: 0.02003 },
  { term: 5, interest: 3.49, paymentFactor: 0.0181872 },
  { term: 5, interest: 6.99, paymentFactor: 0.0198 },

  { term: 10, interest: 3.99, paymentFactor: 0.01012 },
  { term: 10, interest: 7.49, paymentFactor: 0.01186 },
  { term: 10, interest: 3.49, paymentFactor: 0.009884 },
  { term: 10, interest: 6.99, paymentFactor: 0.01161 },

  { term: 15, interest: 3.99, paymentFactor: 0.00739 },
  { term: 15, interest: 7.49, paymentFactor: 0.00926 },
  { term: 15, interest: 3.49, paymentFactor: 0.007144 },
  { term: 15, interest: 6.99, paymentFactor: 0.00898 },
];

export const handleLoanTermChange = (
  newValue,
  finnancialPartner,
  setOtherValues
) => {
  setOtherValues((prev) => {
    const result = {
      ...prev,
      loanTerm: newValue,
      interest: "",
      paymentFactor: "",
    };
    if (prev.loanTerm != newValue || !newValue) {
      result.interest = "";
      result.paymentFactor = "";
    }

    if (finnancialPartner === "GreenSky") {
      result.loanTerm = newValue;
      result.interest = 5.99;
      result.paymentFactor = 0.005616;
    }

    if (finnancialPartner === "Sunlight") {
      let loanDetails = getLoanInterestOptions(newValue, finnancialPartner)[0];
      result.loanTerm = newValue;
      result.interest = loanDetails.interest;
      result.paymentFactor = loanDetails.paymentFactor;
    }

    if (finnancialPartner === "EFS" || finnancialPartner === "EFSBridge") {
      let loanDetails = getLoanInterestOptions(newValue, finnancialPartner)[0];
      console.log(loanDetails);
      result.loanTerm = newValue;
      result.interest = loanDetails.interest;
      result.paymentFactor = loanDetails.paymentFactor;
    }

    if (finnancialPartner === "Dividend") {
      const loanDetails = getLoanInterestOptions(
        newValue,
        finnancialPartner
      )[0];
      console.log(loanDetails);
      result.loanTerm = newValue;
      result.interest = loanDetails.interest;
      result.paymentFactor = loanDetails.paymentFactor;
    }

    if (finnancialPartner === "EnFin") {
      const loanDetails = EnFinLoanOptions.find(
        (option) => option.term === newValue
      );

      result.loanTerm = newValue;
      result.interest = loanDetails.interest;
      result.paymentFactor = loanDetails.paymentFactor;
    }
    console.log(
      "Loan Term Change",
      result.loanTerm,
      result.interest,
      result.paymentFactor
    );
    return result;
  });
};

export const handleInterestChange = (
  newValue,
  finnancialPartner,
  setOtherValues
) => {
  setOtherValues((prev) => {
    const result = { ...prev, interest: newValue };

    if (prev.interest != newValue || !newValue) {
      result.paymentFactor = "";
    }

    if (finnancialPartner === "Sunlight") {
      const loanDetails = SunlightArr.find(
        (details) =>
          details.term == prev.loanTerm && details.interest == newValue
      );
      result.paymentFactor = loanDetails.paymentFactor;
    }

    if (finnancialPartner === "Dividend") {
      const loanDetails = DividendArr.find(
        (details) =>
          details.term == prev.loanTerm && details.interest == newValue
      );
      result.paymentFactor = loanDetails.paymentFactor;
    }

    if (finnancialPartner === "EFS" || finnancialPartner === "EFSBridge") {
      const loanDetails = EFSLoanOptions.find(
        (details) =>
          details.term == prev.loanTerm && details.interest == newValue
      );
      result.paymentFactor = loanDetails.paymentFactor;
    }

    console.log("Interest Change", newValue, result.paymentFactor);
    return result;
  });
};
const DEV_USERS = [
  "dirwin@momentumsolar.com",
  "mbharrat@momentumsolar.com",
  "achelius@momentumsolar.com",
];
export const getLoanInterestOptions = (loanTerm, finnancialPartner) => {
  let loanDetailsOptions = [];

  if (finnancialPartner === "Dividend") loanDetailsOptions = DividendArr;
  if (finnancialPartner === "Sunlight") loanDetailsOptions = SunlightArr;

  if (finnancialPartner === "EFS" || finnancialPartner === "EFSBridge")
    loanDetailsOptions = EFSLoanOptions;

  let res = loanDetailsOptions.filter(
    (loanDetails) => loanDetails.term === loanTerm
  );

  return res;
};

export const handleEmpowerChange = (values, setValues, isKeyBank) => {
  const setStateByEmpower = (values, setValues, toUse) => {
    setValues({
      ...values,

      interest: toUse.interest,
      paymentFactor: toUse.paymentFactor,
    });
  };

  if (isKeyBank) {
    const toUse = DividendKeyBank.find((div) => div.term === values.loanTerm);

    setStateByEmpower(values, setValues, toUse);
    return;
  }

  if (!isKeyBank) {
    const toUse = DividendArr.find((div) => div.term === values.loanTerm);

    setStateByEmpower(values, setValues, toUse);
    return;
  }
};

const capEachFirstLetter = (str) => {
  if (str === null) return;
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};
const inHomeFilter = (data, currentFilter, disableHomeFilter) => {
  if (disableHomeFilter) return data;
  switch (currentFilter) {
    case "inhome":
      return data.filter((propdata) => propdata.inhome);

    case "outhome":
      return data.filter((propdata) => !propdata.inhome);

    default:
      return data;
  }
  // ref old.js for org date filters
};

const inHomeOutOfHomeCounter = (data, currentFilter) => {
  switch (currentFilter) {
    case "inhome":
      return data.filter((propdata) => propdata.inhome).length;

    case "outhome":
      return data.filter((propdata) => !propdata.inhome).length;

    default:
      return -1;
  }
};

const sortFilter = (data, currSort) => {
  if (currSort.key && currSort.direction) {
    let sortByKey = currSort.sortBy ? currSort.sortBy : currSort.key;
    let directionOne = currSort.direction === 1 ? 1 : -1;
    let directionTwo = currSort.direction === 1 ? -1 : 1;
    if (currSort.key === "AppointmentDateString") {
      data.sort((a, b) =>
        !currSort.direction || currSort.direction === 1
          ? new Date(`${b.AppointmentDateString} ${b.AppointmentTimeString}`) -
            new Date(`${a.AppointmentDateString} ${a.AppointmentTimeString}`)
          : new Date(`${a.AppointmentDateString} ${a.AppointmentTimeString}`) -
            new Date(`${b.AppointmentDateString} ${b.AppointmentTimeString}`)
      );
    } else {
      data.sort((a, b) =>
        a[sortByKey] < b[sortByKey] ? directionOne : directionTwo
      );
    }
  } else {
    data.sort(
      (a, b) =>
        new Date(`${b.AppointmentDateString} ${b.AppointmentTimeString}`) -
        new Date(`${a.AppointmentDateString} ${a.AppointmentTimeString}`)
    );
  }
  return data;
};

const searchFilter = (data, textInput, key) => {
  if (textInput) {
    const searchRes = data.filter(
      (val) =>
        val[key]
          .toString()
          .toLowerCase()
          .indexOf(textInput.toLowerCase().trim()) !== -1
    );

    if (searchRes.length > 0) {
      return searchRes;
    } else return data;
  } else {
    return data;
  }
};

const filterByOffice = (data, currentOffices) => {
  if (!currentOffices) return data;
  const unique = [...new Set(...data.map((x) => Object.keys(x)))];

  let allTrue = currentOffices.filter((x) => x.set);
  let filterThese;

  if (allTrue.length === 12) return data;

  if (!allTrue.length) return data;
  else {
    if (unique.includes("Office")) {
      filterThese = allTrue.map((x) => x.key);

      const filteredByOffice = data.reduce((prev, curr) => {
        if (filterThese.includes(curr.Office)) {
          prev.push(curr);
        }
        return prev;
      }, []);

      return filteredByOffice;
    } else {
      filterThese = allTrue.map((x) => x.AState);

      const filteredByOffice = data.reduce((prev, curr) => {
        if (filterThese.includes(curr.State)) {
          prev.push(curr);
        }
        return prev;
      }, []);

      return filteredByOffice;
    }
  }
};

const tableFilter = (
  data,
  textInput,
  currentFilter,
  currSort,
  currSearch,
  currentOffices,
  disableHomeFilter
) => {
  let filteredOffices = filterByOffice(data, currentOffices);
  let sortedByDate = inHomeFilter(
    filteredOffices,
    currentFilter,
    disableHomeFilter
  );
  let sortedData = sortFilter(sortedByDate, currSort);
  let searchData = searchFilter(sortedData, textInput, currSearch);
  return searchData;
};

const simpleTableFilter = (data, textInput, currSort, currSearch) => {
  let sortedData = sortFilter(data, currSort);
  let searchData = searchFilter(sortedData, textInput, currSearch);
  return searchData;
};

const annualELectricityCostM = (monthlyBill) => {
  let arr = [];
  for (let i = 0; i < 12; i++) {
    arr.push(Math.round(monthlyBill));
  }
  return arr;
};

const pullEmpties = (obj) =>
  Object.entries(obj).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});

export const cleanNumbers = (data, areNumbersArray) =>
  Object.keys(data).reduce((prev, curr) => {
    prev = {
      ...prev,
      [curr]: areNumbersArray.includes(curr) ? +data[curr] : data[curr],
    };
    return prev;
  }, {});

export const deleteKeys = (data, deleteArray) =>
  Object.keys(data).reduce((prev, curr) => {
    if (deleteArray.includes(curr)) return prev;
    else {
      prev = { ...prev, [curr]: data[curr] };
    }

    return prev;
  }, {});

const cleanSubmissionData = (otherValues) => {
  //const areNumbers = ["ppW", "panels", "loanTerm", ""];

  const pattern = /^\d+$/;

  let parseInts = Object.keys(otherValues).reduce((prev, curr) => {
    prev = {
      ...prev,
      [curr]: pattern.test(otherValues[curr])
        ? +otherValues[curr]
        : otherValues[curr],
    };
    return prev;
  }, {});
  let pulledEmpties = pullEmpties(parseInts);

  delete pulledEmpties.srecs;

  return pulledEmpties;
};

const whatSrec = (state) => {
  let srec = 0;
  if (state === "NJ") srec = 91.2;
  if (state === "PA") srec = 24;
  return srec;
};
// returns array of an objects keys
const keyExtractor = (obj) => Object.keys(obj);

const getProduct = (products, product, productType) => {
  const foundProduct = products.find(({ name }) => name === product);

  if (!foundProduct)
    return {
      brand: null,
      name: null,
      model: null,
      commonName: null,
      pmax: null,
      _id: null,
      productImage: null,
    };

  const {
    brand = null,
    name = null,
    model = null,
    commonName = null,
    pmax = null,
    _id = null,
    productImage = null,
  } = foundProduct;
  return {
    brand,
    name,
    model,
    commonName,
    pmax,
    _id,
    productImage,
  };
};

export {
  onSetRotation,
  // handleDropChange,
  capEachFirstLetter,
  searchFilter,
  tableFilter,
  handleSRECIncome,
  annualELectricityCostM,
  inHomeOutOfHomeCounter,
  cleanSubmissionData,
  whatSrec,
  pullEmpties,
  getProduct,
  simpleTableFilter,
  keyExtractor,
};

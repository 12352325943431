import React from "react";

import styled from "styled-components";
import BatteryHeader from "../../components/BatteryHeader/BatteryHeader";
import BatteryProposalForm from "./BatteryProposalForm";

const BatteryProposalStyles = styled.div`
  display: flex;
  margin-top: 80px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const BatteryProposal = () => {
  return (
    <BatteryProposalStyles>
      <BatteryHeader />
      <BatteryProposalForm />
    </BatteryProposalStyles>
  );
};

export default BatteryProposal;

import React, { useState } from "react";
import { Checkbox } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import {
  TOGGLE_ACTIVE_MUTATION,
  ALL_PRODUCTS_QUERY,
} from "../utils/ProductsQueries";

const Active = ({ active, _id }) => {
  const [toggleActivePanel] = useMutation(TOGGLE_ACTIVE_MUTATION);
  const [isActive, toggleActive] = useState(active);
  const onToggleActive = async () => {
    const res = await toggleActivePanel({
      variables: {
        _id,
        active: !isActive,
      },
      refetchQueries: [{ query: ALL_PRODUCTS_QUERY }],
    });

    if (res.data) {
      toggleActive(!isActive);
      return;
    }
  };
  return (
    <Checkbox
      value={isActive}
      checked={isActive}
      onClick={() => onToggleActive()}
    />
  );
};

export default Active;

import React, { useContext, useState } from "react";
import styled from "styled-components";

import { TextField, Select, MenuItem, InputLabel } from "@material-ui/core";
import moment from "moment";
import { FormControlStyles } from "../../Refactored/StartProposalV2/styles";
import { useMutation } from "@apollo/react-hooks";
import { NEW_BATTERY_PROPOSAL } from "../../utils/QLMutations";

import { InputStyles } from "../NonUniqueInputs/NonUniques";
import { useRef } from "react";
import { uploadImg, postSlackError } from "../../utils/API";
import ModalComp from "../../components/ConsumptionType/Modal/Modal";
import { Link, CloseOutlined } from "@material-ui/icons";

import { BatteryContext } from "../../Context/BatteryContext";
import { Context } from "../../Context/ProposalContext";

const BatteryFormStyles = styled.form`
  overflow: auto;
  background: white;
  max-width: 80%;
  min-width: 80%;
  overflow: auto;
  max-height: 100%;
  background: white;
  border-radius: 6px;
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
  grid-gap: 35px;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 12px 17px 2px,
    rgba(0, 0, 0, 0.12) 0px 5px 22px 4px, rgba(0, 0, 0, 0.2) 0px 7px 8px -4px;

  .button {
    grid-column: 1/-1;

    justify-self: center;
    padding: 12px;
    background: #3db0ef;
    color: white;
    display: grid;
    justify-content: center;
    font-size: 24px;
    border: none;
    border-radius: 6px;
    :hover {
      cursor: pointer;
    }
  }

  img {
    height: 160px;
    display: grid;
    align-self: center;
  }

  .modal-inner {
    height: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;

    .header-close {
      width: 100%;
      display: flex;
      /* justify-items: center; */
      justify-content: center;
      align-items: center;
      justify-content: space-around;
    }
  }
`;

const formatlabel = (str) => str.replace(/([A-Z])/g, " $1").trim();
const capitalizeFrist = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const states = [
  "GA",
  "NJ",
  "PA",
  "CT",
  "FL",
  "TX",
  "NY",
  "CA",
  "NV",
  "AZ",
  "MA",
];

const BatteryProposalForm = () => {
  const designImageRef = useRef();
  const [filePreview, setFilePreview] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [newBatteryProp] = useMutation(NEW_BATTERY_PROPOSAL);
  const [modalContent, setModalContent] = useState("");
  const [proposalType, setProposalType] = useState("");
  const {
    batteryFormValues,
    setBatteryFormValues,
    otherBatteryValues,
    setOtherBatteryValues,
    formErrors,
    setFormErrors,
  } = useContext(BatteryContext);
  const {
    userValues: { userName },
  } = useContext(Context);

  const handleUpload = (e) => {
    const currentSelection = e.target.files[0];

    let reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result);
      setSelectedFile(currentSelection);
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleChange = (e, setter) => {
    const { value, name, type } = e.target;

    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    setter((prevState) => ({
      ...prevState,
      [name]: type === "number" ? parseFloat(value) : value,
    }));
  };

  const handleSubmission = async (e) => {
    e.preventDefault();

    const isEmpty = Object.keys(batteryFormValues).filter(
      (x) => batteryFormValues[x] === ""
    );

    const isEmptyExceptOpp = isEmpty.filter((x) => x !== "oppnum");

    if (isEmptyExceptOpp.length > 0) {
      const errors = isEmptyExceptOpp.reduce((prev, curr) => {
        prev = {
          ...prev,
          [curr]: `Please fill out ${capitalizeFrist(
            formatlabel(curr)
          )} to submit.`,
        };
        return prev;
      }, {});

      setFormErrors(errors);
      return;
    } else {
      const ifImagepload = async () => {
        if (!selectedFile) return "";
        //const fileType = selectedFile["type"].split("/")[1];

        const designImage = await uploadImg(
          selectedFile,
          `batterypropsal/${batteryFormValues.CustomerName.split(" ").join(
            ""
          )}_${moment().format("MM-DD-YYYY_hh:mm:ssa")}`
        );

        return designImage;
      };

      const designImage = await ifImagepload();
      console.log(designImage);

      try {
        // eslint-disable-next-line
        const res = await newBatteryProp({
          variables: {
            input: {
              ...batteryFormValues,
              ...otherBatteryValues,
              submittedBy: userName,
              designImage,
            },
          },
        });
        // const id = res.data.newBatteryProposal._id;
        setModalContent(
          <div className="modal-inner">
            <div className="header-close">
              <div></div>
              <h2>Proposal Successfully Submitted</h2>
              <CloseOutlined onClick={() => setModalContent("")} />
            </div>
            <a href="https://www.w3schools.com">
              <Link />
            </a>
          </div>
        );
      } catch (e) {
        setModalContent(
          <div className="modal-inner">
            <div className="header-close">
              <div></div>
              <h2>Proposal Error Occured</h2>
              <CloseOutlined onClick={() => setModalContent("")} />
            </div>
            <h2>
              We have sent an email to the MTS team to try to diagnose the
              issues
            </h2>
          </div>
        );
        postSlackError(e);
      }
    }
  };

  const nums = ["installCost", "productCost", "finanncingCost"];

  const initialState = {
    address: "",
    city: "",
    CustomerName: "",

    RepEmail: "",
    zip: "",
  };

  const followUpVals = {
    finanncingCost: "",
    installCost: "",
    oppnum: "",
    productCost: "",
  };

  const setThePropType = (e) => {
    const type = e.target.value;
    if (type === "initial") {
      setBatteryFormValues(initialState);
    } else {
      setBatteryFormValues({ ...initialState, ...followUpVals });
    }
    setProposalType(e.target.value);
  };

  return (
    <>
      <BatteryFormStyles onSubmit={(e) => handleSubmission(e)}>
        <ModalComp open={modalContent} width={"60%"}>
          {modalContent}
        </ModalComp>
        <FormControlStyles>
          <InputLabel> Proposal Type </InputLabel>

          <Select value={proposalType} onChange={(e) => setThePropType(e)}>
            <MenuItem value={"initial"}> Initial </MenuItem>
            <MenuItem value={"follow-up"}> Follow-up </MenuItem>
          </Select>
        </FormControlStyles>
        {proposalType && (
          <>
            {Object.keys(batteryFormValues).map((k) => (
              <TextField
                type={nums.includes(k) ? "number" : "text"}
                key={k}
                onChange={(e) => handleChange(e, setBatteryFormValues)}
                label={capitalizeFrist(formatlabel(k))}
                name={k}
                value={batteryFormValues[k]}
                error={formErrors[k] ? true : false}
                helperText={formErrors[k]}
                autoComplete="off"
              />
            ))}

            <FormControlStyles>
              <InputLabel> State </InputLabel>
              <Select
                label="State"
                name="state"
                value={otherBatteryValues.state}
                onChange={(e) => handleChange(e, setOtherBatteryValues)}
              >
                {states.sort().map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControlStyles>
            <InputStyles width="100%">
              <div className="label-styles">
                <label htmlFor="upload">
                  Upload Design Image
                  <input
                    onChange={(e) => handleUpload(e)}
                    type="file"
                    name="designImageUpload"
                    id="upload"
                    accept="image/*"
                  />
                </label>
              </div>
            </InputStyles>

            {filePreview && (
              <img ref={designImageRef} alt="upload" src={filePreview} />
            )}

            <button className="button" type="submit">
              Submit
            </button>
            {/* {JSON.stringify(batteryFormValues, null, 2)}
      {JSON.stringify(otherBatteryValues, null, 2)} */}
          </>
        )}
      </BatteryFormStyles>
    </>
  );
};

export default BatteryProposalForm;

import React, { useState } from "react";
import { Photo, Clear } from "@material-ui/icons";
import styled from "styled-components";
import NewModal from "./NewModal";

const ModalStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .clear {
    align-self: flex-start;
  }

  img {
    width: 450px;
  }
`;

const HoverImage = ({ image }) => {
  const [open, setOpen] = useState(false);

  const handlePopoverOpen = event => {
    setOpen(true);
  };

  return image ? (
    <>
      <Photo
        onClick={() => handlePopoverOpen()}
        //   onMouseLeave={() => handlePopoverClose()}
      ></Photo>
      <NewModal open={open} setOpen={setOpen}>
        <Clear
          style={{ alignSelf: "flex-end" }}
          className="clear"
          onClick={() => setOpen(false)}
        ></Clear>
        <ModalStyles>
          <img alt="panel-img" src={image.panelUrl} />
        </ModalStyles>
      </NewModal>
    </>
  ) : (
    "No Photo Uploaded!"
  );
};

export default HoverImage;

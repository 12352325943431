import React, { useState } from "react";
import { AddCircleOutline } from "@material-ui/icons";
import { TableRow, TableCell, TextField } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";
import {
  ADD_INVERTER_MUTATION,
  ADD_PANEL_MUTATION
} from "../utils/ProductsQueries";
import { ButtonStyles } from "./EditDel";
import NewProductModal from "./NewProductModal";

const AddCircleOutlineStyles = styled(AddCircleOutline)`
  color: lightblue;
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }x
`;

const AddProduct = props => {
  const { activeProducts } = props;

  const [addProductInput, setAddProductInput] = useState({
    name: "",
    pmax: "",
    commonName: "",
    brand: "",
    model: ""
  });

  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const [
    addPanel
    //    { error: addPanelError }
  ] = useMutation(ADD_PANEL_MUTATION, {
    variables: {
      addProductInput
    },
    refetchQueries: ["allProducts"]
  });

  const [addInverter] = useMutation(ADD_INVERTER_MUTATION, {
    variables: {
      addProductInput
    },
    refetchQueries: ["allProducts"]
  });

  const onChangeProductInputs = e => {
    setAddProductInput({
      ...addProductInput,
      [e.target.name]: e.target.value
    });
  };
  const onSubmitProduct = async () => {
    const clearError = () => {
      setTimeout(() => {
        setError("");
      }, 2000);
    };
    if (!addProductInput.name) {
      setError("Please Add  Product Name Before Submitting");
      clearError("");
      return;
    }
    if (!addProductInput.pmax) {
      setError("Please Add  Product P Max Before Submitting");
      clearError("");
      return;
    }

    if (!addProductInput.brand) {
      setError("Please Add  Product Brand Before Submitting");
      clearError("");
      return;
    }

    if (!addProductInput.model) {
      setError("Please Add  Product Model Before Submitting");
      clearError("");
      return;
    }

    if (activeProducts === "allPanels") {
      addPanel();
      setAddProductInput({
        name: "",
        pmax: "",
        commonName: "",
        brand: "",
        model: ""
      });

      //   if (addPanelError) {
      //     setError(addPanelError);
      //     clearError("");
      //     return;
      //   }
    } else {
      addInverter();
      setAddProductInput({
        name: "",
        pmax: "",
        commonName: "",
        brand: "",
        model: ""
      });
    }
    // onClick={() =>
    //     activeProducts === "allPanels" ? addPanel() : addInverter()
    //   }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <TextField
            value={addProductInput.name}
            name="name"
            onChange={e => onChangeProductInputs(e)}
            error={!addProductInput.name}
          />
        </TableCell>
        <TableCell>
          <TextField
            value={addProductInput.pmax}
            type="number"
            name="pmax"
            onChange={e => onChangeProductInputs(e)}
            error={!addProductInput.pmax}
          />
        </TableCell>
        <TableCell>
          <TextField
            value={addProductInput.brand}
            name="brand"
            onChange={e => onChangeProductInputs(e)}
            error={!addProductInput.brand}
          />
        </TableCell>
        <TableCell>
          <TextField
            value={addProductInput.model}
            name="model"
            onChange={e => onChangeProductInputs(e)}
            error={!addProductInput.model}
          />
        </TableCell>
        <TableCell>
          <ButtonStyles onClick={() => setOpen(!open)}>
            {" "}
            {addProductInput.productImage ? "Submit ->" : "Upload Image"}
          </ButtonStyles>
        </TableCell>
        <TableCell>
          {error ? (
            error
          ) : (
            <AddCircleOutlineStyles onClick={() => onSubmitProduct()} />
          )}
        </TableCell>
      </TableRow>
      <NewProductModal
        open={open}
        setOpen={setOpen}
        productState={addProductInput}
        setProductState={setAddProductInput}
      />
    </>
  );
};

export default AddProduct;

import { gql } from "apollo-boost";
export const UPDATE_ACTION_MUATION = gql`
  mutation updateAction($id: ID!) {
    updateAction(id: $id) {
      id
      proposalAgentName
      proposalAgentEmail
      permissions
      UserTime {
        _id
        lastLogin
        lastAction
      }
    }
  }
`;

export const ADD_USER_MUTATION = gql`
  mutation addUser(
    $proposalAgentName: String!
    $proposalAgentEmail: String!
    $permissions: [Permission]!
  ) {
    addUser(
      proposalAgentName: $proposalAgentName
      proposalAgentEmail: $proposalAgentEmail
      permissions: $permissions
    ) {
      id
      proposalAgentName
      proposalAgentEmail
      registered
      permissions
      proposalsAssigned {
        id
      }
      proposalsCompleted {
        id
      }
    }
  }
`;

export const ASSIGN_PROPOSAL_MUTATION = gql`
  mutation assignProposal($userId: ID!, $proposalId: ID!) {
    assignProposal(userId: $userId, proposalId: $proposalId) {
      registered
      proposalAgentName
      proposalsAssigned {
        id
        activityid
        oppnum
        RepName
        RepEmail
        AppointmentStartString
        AppointmentTimeString
        AppointmentDateString
        momentdate
        Lead
        CustomerName
        Office
        Address
        City
        State
        Zip
        Canvasser
        ProposalStatus
        Notes
        type
        panels
        inverter
        module
        PVArray
        production
        datasheetcomplete
        reprequest
        inhome
        pimg
        repnotes
        timeAssigned
        timeAssignedUnix
        proposalCompleted
        proposalStarted
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $userId: ID!
    $proposalAgentName: String
    $proposalAgentEmail: String
    $permissions: [Permission]!
  ) {
    updateUser(
      userId: $userId
      proposalAgentName: $proposalAgentName
      proposalAgentEmail: $proposalAgentEmail
      permissions: $permissions
    ) {
      id
    }
  }
`;

export const REMOVE_USER_MUTATION = gql`
  mutation removeUser($_id: ID!) {
    removeUser(_id: $_id)
  }
`;

export const DATA_SHEET_COMPLETE = gql`
  mutation dataSheetComplete($proposalId: ID!) {
    dataSheetComplete(proposalId: $proposalId) {
      id
      datasheetcomplete
      ProposalStatus
    }
  }
`;

export const COMPLETE_PROPOSAL_MUTATION = gql`
  mutation completeProposal(
    $proposalId: ID
    $CompletedProposalInput: CompletedProposalInput
    $user: ID
    $GeneracSystemInput: GeneracSystemInput
  ) {
    completeProposal(
      proposalId: $proposalId
      CompletedProposalInput: $CompletedProposalInput
      user: $user
      GeneracSystemInput: $GeneracSystemInput
    ) {
      id
      preparerEmail
      preparerName
    }
  }
`;

export const CANCEL_ASSIGNMENT_MUTATION = gql`
  mutation cancelAssignment($id: ID!, $userId: ID!) {
    cancelAssignment(id: $id, userId: $userId) {
      id
      activityid
      oppnum
      RepName
      RepEmail
      AppointmentStartString
      AppointmentTimeString
      AppointmentDateString
      momentdate
      Lead
      CustomerName
      Office
      Address
      City
      State
      Zip
      Canvasser
      ProposalStatus
      Notes
      type
      panels
      inverter
      module
      PVArray
      production
      datasheetcomplete
      reprequest
      inhome
      pimg
      repnotes
      timeAssigned
      timeAssignedUnix
      proposalCompleted
      proposalStarted
    }
  }
`;

export const GRAB_NEXT_PROPOSAL_MUTATION = gql`
  mutation grabProposal($id: ID!, $userId: ID!) {
    grabProposal(id: $id, userId: $userId) {
      id
      activityid
      oppnum
      RepName
      RepEmail
      AppointmentStartString
      AppointmentTimeString
      AppointmentDateString
      momentdate
      Lead
      CustomerName
      Office
      Address
      City
      State
      Zip
      Canvasser
      ProposalStatus
      Notes
      type
      panels
      inverter
      module
      PVArray
      production
      datasheetcomplete
      reprequest
      inhome
      pimg
      repnotes
      timeAssigned
      timeAssignedUnix
      proposalCompleted
      proposalStarted
    }
  }
`;

export const DROP_FROM_QUEUE_MUTATION = gql`
  mutation dropFromQueue($id: ID!) {
    dropFromQueue(id: $id) {
      id
      activityid
      oppnum
      RepName
      RepEmail
      AppointmentStartString
      AppointmentTimeString
      AppointmentDateString
      momentdate
      Lead
      CustomerName
      Office
      Address
      City
      State
      Zip
      Canvasser
      ProposalStatus
      Notes
      type
      panels
      inverter
      module
      PVArray
      production
      datasheetcomplete
      reprequest
      inhome
      pimg
      repnotes
    }
  }
`;

export const REMOVE_UTIL_BILL = gql`
  mutation removeUtilBill($_id: ID!) {
    removeUtilBill(_id: $_id) {
      _id
      utilityName
      utilityState
      billImg
    }
  }
`;

export const ADD_BILL_MUTATION = gql`
  mutation addUtilityBill($input: UtilityBillInput) {
    addUtilityBill(input: $input) {
      billImg
      utilityName
      utilityState
    }
  }
`;

export const EDIT_UTIL_BILL = gql`
  mutation editUtilityBill($input: UtilityBillInputEdit!) {
    editUtilityBill(input: $input) {
      _id
      utilityName
      utilityState
      billImg
    }
  }
`;

export const NEW_BATTERY_PROPOSAL = gql`
  mutation newBatteryProposal($input: BatteryProposalInput!) {
    newBatteryProposal(input: $input) {
      _id
      CustomerName
      oppnum
      address
      city
      state
      zip
      designImage
      installCost
      # salesRepEmail
      productCost
      finanncingCost
      finalCost
    }
  }
`;

export const DELETE_PROPOSAL = gql`
  mutation deleteCompletedProposal($input: DeleteProposalInput!) {
    deleteCompletedProposal(input: $input) {
      id
      proposalAgentName
      proposalAgentEmail
      registered
      permissions
      proposalsAssigned {
        id
      }
    }
  }
`;

export const DROP_INTO_QUEUE = gql`
  mutation dropIntoQueue($input: DropToQueueInput!) {
    dropIntoQueue(input: $input) {
      id
      activityid
      oppnum
      RepName
      RepEmail
      AppointmentStartString
      AppointmentTimeString
      AppointmentDateString
      momentdate
      Lead
      CustomerName
      Office
      Address
      City
      State
      Zip
      Canvasser
      ProposalStatus
      Notes
      type
      panels
      inverter
      module
      PVArray
      production
      datasheetcomplete
      reprequest
      inhome
      pimg
      repnotes
      timeAssigned
      timeAssignedUnix
      proposalCompleted
      proposalStarted
    }
  }
`;

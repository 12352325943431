import React, { useState } from "react";
import styled from "styled-components";
import { Nature, FilterHdr, Cancel } from "@material-ui/icons";

import WorkInfo from "../../components/WorkInfo";
//import CheckMark from "../../components/CheckMark/CheckMark";

const AdditionalWorkStyles = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .select {
    margin-bottom: 20px;
    flex-direction: row;
    display: flex;
    justify-content: center;
  }

  .select-2 {
    margin-bottom: 20px;
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
  }

  .icon-div:not(:last-child) {
    margin-right: 32px;
  }

  .icon-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px rgb(0, 174, 239);
    font-size: 32px;
    transition: 0.3s;
    p {
      margin: 0;
      font-size: 12px;
    }

    :hover {
      opacity: 0.7;
      cursor: pointer;
      transform: scale(1.2);
      background: rgb(0, 174, 239);
      color: white;
      border: solid 1px white;
    }

    .row {
      display: flex;
      flex-direction: row;
    }
  }

  .active {
    transform: scale(1.4);
    background: rgb(0, 174, 239);
    color: white;
    border: solid 1px white;
  }

  margin-bottom: 20px;

  /* .first {
  
  } */
`;

const AdditionalWork = () => {
  const [active, setActive] = useState("");
  const [additionalwork, setAdditionalWork] = useState([
    {
      type: "tree",
      notes: "",
      img: "",
      filePreview: "",
      required: "",
      estimatedCost: ""
    },
    {
      type: "trench",
      notes: "",
      img: "",
      filePreview: "",
      required: "",
      estimatedCost: ""
    }
  ]);

  const onSetActive = e => {
    setActive(e);
  };

  const clearActive = () => {
    setActive("");

    // setOtherValues(stateCopy);
  };

  return (
    <AdditionalWorkStyles>
      <div className="select">
        <div
          onClick={() => onSetActive("Tree")}
          className={`icon-div first ${
            active === "Tree" ? "active" : "inactive"
          }`}
        >
          {/* <div className = 'icon-row'>
         
          </div> */}
          <Nature />
          <p>Tree Work</p>
          {/* <CheckSharp /> */}
          {/* <CheckMark /> */}
        </div>
        <div
          onClick={() => onSetActive("Trench")}
          className={`icon-div first ${
            active === "Trench" ? "active" : "inactive"
          }`}
        >
          <FilterHdr />
          <p>Trench Work</p>
          {/* <CheckSharp /> */}
          {/* <CheckMark /> */}
        </div>

        {active && (
          <div onClick={() => clearActive()} className="icon-div inactive">
            <Cancel />
          </div>
        )}
      </div>
      {active && (
        <WorkInfo
          active={active}
          additionalwork={additionalwork}
          setAdditionalWork={setAdditionalWork}
        />
      )}
    </AdditionalWorkStyles>
  );
};

export default AdditionalWork;

import React, { useContext } from "react";
import ProposalTable from "../../components/ProposalTable/ProposalTable";
import { ListAlt, EmailRounded } from "@material-ui/icons";
import styled from "styled-components";
import OpenModalHook from "../../utils/hooks/openmodal-hook";
import { ALL_ENPHASE_APPT_QUERY } from "../../utils/GQL/EnphaseAppointments";
import { useQuery } from "@apollo/react-hooks";
import OtherLoader from "../../components/OtherLoader/OtherLoader";
import { Link } from "../../pages/SearchProp/ProposalFound";
import { Public } from "@material-ui/icons";
import { resendEnphaseEmail, salesRepToAssign } from "./helpers";
import { Context } from "../../Context/ProposalContext";

export const tableHeaders = [
  { id: "1", label: "Created By", key: "createdBy" },
  { id: "2", label: "Opportunity Number", key: "oppnum" },
  { id: "3", label: "Customer", key: "CustomerName" },
  { id: "4", label: "State", key: "State" },

  { id: "6", label: "Appointment Address", key: "Address" },
  { id: "7", label: "Demo Link", key: "demo" },

  { id: "9", label: "Dispatch Proposal", key: "dispatchproposal" },
  { id: "10", label: "Resend Email", key: "resendEmail" },
];

const BatteryHomeStyles = styled.div`
  /* display: grid; */
  width: 96%;
  margin: auto;
  margin-bottom: 10px;
  button {
    display: grid;
    align-self: center;
    width: 240px;

    background: white;
    border: solid 2px #3db0ef;
    border-radius: 4px;

    grid-auto-flow: column;
    padding: 6px;
    align-items: center;
    font-size: 17.5px;
    color: #3db0ef;

    :hover {
      cursor: pointer;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
  }
`;

let ModalContentObj = {
  headerTitle: "Generate A New Battery Appointment",
};

const BatteryHome = () => {
  const { openTheModal } = OpenModalHook(ModalContentObj);

  const { userValues } = useContext(Context);

  const { name, userName } = userValues;

  const { data, error, loading } = useQuery(ALL_ENPHASE_APPT_QUERY);

  if (loading) return <OtherLoader />;
  if (error) return "Error Occured";

  let reducedData = data.allEnphaseAppointments.reduce((prev, curr) => {
    let repInfo = salesRepToAssign(curr.State);
    const { RepName, RepEmail } = repInfo;
    curr = {
      mapkey: curr._id,
      ...curr,

      Addresss: `${curr.Addresss}, ${curr.Zip}`,
      demo: (
        <Link
          Icon={Public}
          url={`https://batteryview.momentumsolar.app/${curr._id}`}
        />
      ),
      dispatchproposal: "Coming Soon",
      resendEmail: (
        <EmailRounded
          onClick={() => resendEnphaseEmail(curr, { name, userName })}
        />
      ),

      RepName: curr.RepName ? curr.RepName : RepName,
      RepEmail: curr.RepEmail ? curr.RepEmail : RepEmail,
    };
    prev.push(curr);
    return prev;
  }, []);

  // load data
  return (
    <>
      <BatteryHomeStyles>
        <button onClick={(e) => openTheModal()}>
          Create New Appointment
          <ListAlt />
        </button>
      </BatteryHomeStyles>
      <ProposalTable
        hideInHomeFilter={true}
        data={loading ? [] : reducedData}
        tableHeaders={tableHeaders}
        HidePaginationProp={true}
        disableHomeFilter={true}
      />
      {/* <NewBatteryOpp /> */}
    </>
  );
};

export default BatteryHome;

import React, { useContext } from "react";
import { withRouter } from "react-router";
//styles
import StyledSidebar from "./styles";
//adminlevel
import { Context } from "../../Context/ProposalContext";
import NavList from "../Navlist";
import {
  Home,
  AssignmentTurnedIn,
  Work,
  Receipt,
  Battery20Outlined,
  FindInPage,
  BarChart,
} from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

const SideNav = (props) => {
  const { admin, user } = props;

  const { show } = useContext(Context);

  return (
    <>
      <StyledSidebar open={show}>
        <ul>
          {user && (
            <NavList link="/">
              <Tooltip title="Home">
                <Home />
              </Tooltip>
            </NavList>
          )}

          <NavList link="/completed">
            <Tooltip title={"Completed Proposals"}>
              <AssignmentTurnedIn />
            </Tooltip>
          </NavList>
          <NavList link="/battery">
            <Tooltip title={"Battery Home"}>
              <Battery20Outlined />
            </Tooltip>
          </NavList>
          <NavList link="/searchprop">
            <Tooltip title={"Search Proposal"}>
              <FindInPage />
            </Tooltip>
          </NavList>
          {admin && (
            <>
              <NavList link="/products">
                <Tooltip title={"Productss"}>
                  <Work />
                </Tooltip>
              </NavList>

              <NavList link="/appointmenttracking">
                <Tooltip title={"Appointment Tracking"}>
                  <BarChart />
                </Tooltip>
              </NavList>
            </>
          )}
        </ul>
      </StyledSidebar>
    </>
  );
};

export default withRouter(SideNav);

import React, { useState } from "react";
import styled from "styled-components";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";

import { useMutation } from "@apollo/react-hooks";
import { NEW_APPOINTMENT_MUTATION } from "../../utils/GQL/NewAppointmentMutation";
import BatteryLoader from "./BatteryLoader";
import { useContext } from "react";
import { Context } from "../../Context/ProposalContext";
import OpenModalHook from "../../utils/hooks/openmodal-hook";
import { Copy } from "../../pages/SearchProp/ProposalFound";
import { sendEnphaseEmail } from "../../utils/API";
import { ALL_ENPHASE_APPT_QUERY } from "../../utils/GQL/EnphaseAppointments";
import { sendTo, salesRepToAssign } from "./helpers";

const BatteryOppStyles = styled.div`
  .submitting {
    width: 100%;
    display: grid;
    justify-items: center;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    padding: 20px;
  }

  .submit-button {
    grid-column: 1 / -1;
    width: 30%;
    display: grid;
    justify-self: center;
    padding: 10px;

    background: white;
    border: solid 2px #3db0ef;
    border-radius: 4px;

    font-size: 17.5px;
    color: #3db0ef;

    :hover {
      cursor: pointer;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
  }

  .submitted {
    display: grid;
    grid-auto-flow: row;
    padding: 20px;

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
    }

    .styled-button {
      width: 30%;
      display: grid;
      justify-self: center;
      padding: 10px;

      background: white;
      border: solid 2px #3db0ef;
      border-radius: 4px;

      font-size: 17.5px;
      color: #3db0ef;

      :hover {
        cursor: pointer;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
  }
`;

const initialValues = {
  oppnum: "",
  CustomerName: "",
  Office: "",
  Address: "",
  City: "",
  State: "",
  Zip: "",
};

const formVals = [
  {
    id: 1,
    label: "Opporutnity Number",
    placeholder: "Enter OP-ENDEMO if don't have one",
    key: "oppnum",
  },
  {
    id: 2,
    label: "Customer Name",

    key: "CustomerName",
  },
  {
    id: 3,
    label: "Office",

    key: "Office",
  },
  {
    id: 4,
    label: "Address",

    key: "Address",
  },
  {
    id: 5,
    label: "City",

    key: "City",
  },
  {
    id: 6,
    label: "State",
    type: "select",
    options: [
      "AZ",
      "NV",
      "NJ",
      "PA",
      "NY",
      "CA",
      "FL",
      "TX",
      "CT",
      "MA",
      "GA",
    ].sort(),
    key: "State",
  },
  {
    id: 7,
    label: "Zip Code",

    key: "Zip",
  },
];

const NewBatteryOpp = (props) => {
  const {
    userValues: { userName, name },
  } = useContext(Context);
  const { closeTheModal } = OpenModalHook();
  const [values, setValues] = useState({ ...initialValues });
  const [errors, setErrors] = useState({ ...initialValues });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [submittedSucessfully, setSubmittedSuccesfully] = useState(false);
  const [createAppointment] = useMutation(NEW_APPOINTMENT_MUTATION);

  const handleSubmission = async (e) => {
    e.preventDefault();
    setMessage("");
    let reducedErrors = Object.keys(values).reduce((prev, curr) => {
      if (values[curr] === "") {
        prev = {
          ...prev,
          [curr]: `Please Enter Value for ${curr}`,
        };
      } else {
        prev = {
          ...prev,
          [curr]: "",
        };
      }
      return prev;
    }, {});
    setErrors(reducedErrors);

    if (Object.values(values).includes("")) {
      setMessage("Please check all Fields before submitting");
      return;
    } else {
      setLoading(true);

      let SelectedState = values.State;

      let assignedRep = salesRepToAssign(SelectedState);

      try {
        let appointmentRes = await createAppointment({
          variables: {
            input: {
              ...values,
              ...assignedRep,
              createdBy: userName,
              type: "Enphase",
            },
          },
          refetchQueries: [{ query: ALL_ENPHASE_APPT_QUERY }],
        });

        //  let _id, CustomerName, State, Zip, Address

        let { _id, CustomerName, State, Zip, Address, oppnum } =
          appointmentRes.data.createAppointment;

        let to = sendTo(State);

        setLoading(false);
        sendEnphaseEmail(CustomerName, State, Zip, Address, _id, oppnum, to, {
          RepName: assignedRep.RepName,
          name,
          userName,
        });
        setSubmittedSuccesfully(
          <div className="submitted">
            <p>
              Opportunity Sucessfuly Created, Click Ok to close Form, or, Start
              New to enter another
            </p>
            <div className="buttons">
              <button onClick={() => closeTheModal()} className="styled-button">
                OK
              </button>
              <button
                onClick={() => {
                  setValues(initialValues);
                  setSubmittedSuccesfully("");
                }}
                className="styled-button"
              >
                Start New
              </button>
              <Copy url={`https://batteryview.momentumsolar.app/${_id}`} />
            </div>
          </div>
        );
      } catch (e) {
        console.log(e);
        setLoading(false);
        setMessage("An Error Occured during Submission");
      }
      console.log(values, "proceed to sumission");
    }
  };

  const setValuesAndClearErrors = (e, key) => {
    setValues({
      ...values,
      [key]: e.target.value,
    });

    setErrors({
      ...errors,
      [key]: "",
    });
  };

  return (
    <BatteryOppStyles>
      {loading ? (
        <div className="submitting">
          Submitting
          <BatteryLoader />
        </div>
      ) : submittedSucessfully ? (
        submittedSucessfully
      ) : (
        <form onSubmit={handleSubmission}>
          {formVals.map(
            ({ type, options = [], key, label, placeholder = "", id }) => (
              <>
                {type === "select" ? (
                  <FormControl
                    key={id}
                    error={errors[key] !== "" ? true : false}
                  >
                    <InputLabel> {label} </InputLabel>
                    <Select
                      value={values[key]}
                      onChange={(e) => setValuesAndClearErrors(e, key)}
                    >
                      {options.map((val) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors[key] === ""
                        ? placeholder
                        : `${placeholder && `${placeholder} || `}  ${
                            errors[key]
                          }`}
                    </FormHelperText>
                  </FormControl>
                ) : (
                  <TextField
                    error={errors[key] !== "" ? true : false}
                    helperText={
                      errors[key] === ""
                        ? placeholder
                        : `${placeholder && `${placeholder} || `}  ${
                            errors[key]
                          }`
                    }
                    key={id}
                    value={values[key]}
                    label={label}
                    onChange={(e) => setValuesAndClearErrors(e, key)}
                  />
                )}
              </>
            )
          )}
          <input className="submit-button" type="submit" />
        </form>
      )}
      {message}
    </BatteryOppStyles>
  );
};

// const SubmittedSuccessfullyComp = () => (
//   <div className="submitted">
//     <p>
//       Opportunity Sucessfuly Created, Click Ok to close Form, or, Start New to
//       enter another
//     </p>
//     <div className="buttons">
//       <button onClick={() => closeTheModal()} className="styled-button">
//         OK
//       </button>
//       <button
//         onClick={() => setValues(initialValues)}
//         className="styled-button"
//       >
//         Start New
//       </button>
//     </div>
//   </div>
// );

export default NewBatteryOpp;

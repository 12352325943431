import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ButtonGroup, Button } from "@material-ui/core";
import Assign from "../Assign/Assign";
import InProgress from "../InProgress/InProgress";
import AdminPanel from "../AdminPanel/AdminPanel";
import MyProposals from "../MyProposals/MyProposals";
import { AdminHomeStyles } from "./styles";
import {
  ALL_USERS_QUERY,
  ALL_REQUESTED_PROPOSALS,
  COMPLETED_TODAY_QUERY,
} from "../../utils/GQL/QLQueries";
import OtherLoader from "../../components/OtherLoader/OtherLoader";

import Count from "../../components/Count/Count";
import { proposalsUnassigned, proposalsAssigned } from "../Assign/Assign";
import CompletedToday from "../CompletedToday/CompletedToday";
import ActiveUsers from "../../components/ActiveUsers";
import moment from "moment";
import { Context } from "../../Context/ProposalContext";

export const StyledButton = styled(Button)`
  color: ${(props) => props.theme[props.isselected.color]};
  font-weight: ${(props) => props.isselected.fontWeight};
  font-size: ${(props) => props.isselected.fontSize};

  .text-count {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
  }
`;

export const buttonColor = (name, activeTab) => {
  if (activeTab === name) {
    return { color: "midnightBlue", fontWeight: "bolder", fontSize: "18px" };
  }
  return { color: "cadetBlue", fontWeight: "normal", fontSize: "14px" };
};

const AdminHome = (props) => {
  const { activeTab, setActiveTab, currentFilter } = useContext(Context);

  const { data: allUserData, loading: allUsersLoading } = useQuery(
    ALL_USERS_QUERY,
    {
      pollInterval: 10000,
    }
  );
  const { data: requestedProposals, loading: proposalsLoading } = useQuery(
    ALL_REQUESTED_PROPOSALS,
    {
      pollInterval: 10000,
    }
  );

  const { data: completedToday, loading: loadingToday } = useQuery(
    COMPLETED_TODAY_QUERY,
    {
      pollInterval: 60000,
    }
  );

  const { admin, currentUser } = props;
  const { permissions } = currentUser;

  //const [active, setActive] = useState("Assign");
  // const [alterRender, setAlterRender] = useState(false);
  const alterRender = false;

  const Components = {
    Assign,
    InProgress,
    AdminPanel,
    MyProposals,
    CompletedToday,
  };

  const Tag = Components[activeTab];

  let allRequestedProps =
    proposalsLoading || !requestedProposals
      ? []
      : requestedProposals.requestedProposals;
  let allUsersData = allUsersLoading || !allUserData ? [] : allUserData.users;
  let proposalsCompletedToday =
    loadingToday || !completedToday ? [] : completedToday.completedToday;

  // if (!admin) push("/");

  // if (allUsersLoading) return <OtherLoader />;
  if (proposalsLoading) return <OtherLoader />;
  // if (loadingToday) return <OtherLoader />;

  const requestedReduced = allRequestedProps.reduce((prev, curr) => {
    curr = {
      ...curr,
      Office: curr["Office"] === curr["Zip"] ? curr["State"] : curr["Office"],
      timerequestedpretty: curr.timerequested
        ? moment(curr.timerequested).format("MM-DD  hh:mm:ss a")
        : "No Time Requested",
    };

    prev.push(curr);
    return prev;
  }, []);

  return (
    <AdminHomeStyles>
      {alterRender && (
        <div className="top-nav">
          <ul>
            <li
              className={activeTab === "Assign" ? "active" : "inactive"}
              onClick={(e) => setActiveTab("Assign")}
              value="Assign"
            >
              Assign
            </li>
            <li
              className={activeTab === "InProgress" ? "active" : "inactive"}
              onClick={(e) => setActiveTab("InProgress")}
              value="InProgress"
            >
              In Progress
            </li>
            <li
              className={activeTab === "AdminPanel" ? "active" : "inactive"}
              onClick={(e) => setActiveTab("AdminPanel")}
              value="AdminPanel"
            >
              Admin
            </li>
          </ul>
        </div>
      )}
      {!alterRender && (
        <div className="admin-nav">
          <div className="active-users">
            <ActiveUsers users={allUsersData} />
          </div>
          <ButtonGroup
            aria-label="outlined primary button group"
            variant="text"
          >
            <StyledButton
              onClick={() => setActiveTab("Assign")}
              // eslint-disable-next-line no-use-before-define
              isselected={buttonColor("Assign", activeTab)}
            >
              {/* <div className="text-count">
              Assign
              <Count count={proposalsUnassigned(proposalData.proposals)} />
            </div> */}
              <div className="text-count">
                Assign
                <Count
                  count={
                    proposalsUnassigned(requestedReduced, currentFilter).length
                  }
                />
              </div>
            </StyledButton>

            <StyledButton
              onClick={() => setActiveTab("InProgress")}
              // eslint-disable-next-line no-use-before-define
              isselected={buttonColor("InProgress", activeTab)}
            >
              <div className="text-count">
                In Progress
                <Count
                  count={
                    proposalsAssigned(requestedReduced, currentFilter).length
                  }
                />
              </div>
            </StyledButton>
            <StyledButton
              onClick={() => setActiveTab("CompletedToday")}
              // eslint-disable-next-line no-use-before-define
              isselected={buttonColor("CompletedToday", activeTab)}
            >
              <div className="text-count">
                Completed Today
                <Count count={proposalsCompletedToday.length} />
              </div>
            </StyledButton>
            {permissions.includes("ADMIN") && (
              <StyledButton
                onClick={() => setActiveTab("AdminPanel")}
                // eslint-disable-next-line no-use-before-define
                isselected={buttonColor("AdminPanel", activeTab)}
              >
                Admin
              </StyledButton>
            )}
            <StyledButton
              onClick={() => setActiveTab("MyProposals")}
              // eslint-disable-next-line no-use-before-define
              isselected={buttonColor("MyProposals", activeTab)}
            >
              My Proposals
            </StyledButton>
          </ButtonGroup>
          <div className="false-div"></div>
        </div>
      )}

      <Tag
        data={currentUser}
        proposalData={requestedReduced}
        allUserData={allUsersData}
        admin={admin}
        completedToday={proposalsCompletedToday}
      />
      {/* <button onClick={() => setAlterRender(!alterRender)}>Alter Render</button> */}
    </AdminHomeStyles>
  );
};

export default withRouter(AdminHome);

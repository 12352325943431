import axios from "axios";
import { ProposalStartedEmail, ProposalCompletedEmail } from "./Email";
import { EnphaseProposalCompletedEmail } from "./EmailTemplates/EnphaseEmail";

export const sendEmail = (postBody) => {
  return axios.post(
    "https://designS3.momentumsolar.app/api/sendemail",
    postBody
  );
};

export const sendEnphaseEmail = (
  CustomerName,
  State,
  Zip,
  Address,
  _id,
  oppnum,
  to,
  userInfo
) => {
  console.log(CustomerName, State, Zip, Address, _id, oppnum, to, userInfo);
  axios.post(
    `https://veeyieqt20.execute-api.us-east-1.amazonaws.com/api/sendmailbulk`,
    {
      from: "enphaseproposals@momentumsolar.app",
      to,
      subject: `Customer: ${CustomerName}, Oppnum: ${oppnum} Enphase DD1 Completed `,
      body: EnphaseProposalCompletedEmail(
        CustomerName,
        State,
        Zip,
        Address,
        oppnum,
        _id,
        userInfo
      ),
    }
  );
};

export const sendEmail2 = (info, userInfo, complete) => {
  console.log(info);
  axios.post(
    `https://veeyieqt20.execute-api.us-east-1.amazonaws.com/api/sendmailbulk`,
    {
      from: "proposals@momentumsolar.app",
      to: ["clantier@momentumsolar.com", info.RepName],
      subject: complete ? "Proposal Complete" : "Proposal Started",
      body: complete
        ? ProposalCompletedEmail(info, userInfo)
        : ProposalStartedEmail(info, userInfo),
    }
  );
};

export const postSlackError = (postBody) =>
  axios.post(
    `https://hooks.slack.com/services/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`,

    postBody,
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }

    // { "Content-Type": "application/json", "A" }
  );

export const sendExcelSheet = (postBody) => {
  return axios.post("/api/onedriveupload", postBody);
};

export const imagePOST = (formData) =>
  axios.post(`https://designS3.momentumsolar.app/api/uploadS3Img`, formData);

export const generateLead = (body) =>
  axios.post(
    `https://api.trn.igssolarpower.com/api/Customer/CreateCustomer`,
    body,
    {
      auth: {
        username: "MomentumIntegrationSvc@igssolarpower.com",
        password: "IgsMomentum2019!",
      },
    }
  );

export const uploadImg = async (selectedFile, key) => {
  let formData = new FormData();

  formData.append("Bucket", "proposalimages2");
  // not the moment error
  formData.append("Key", key);

  formData.append("Body", selectedFile);

  const imageUploadRes = await imagePOST(formData);

  return imageUploadRes.data.url;
};

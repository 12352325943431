import React from "react";
import styled from "styled-components";

const BatteryLoaderStyles = styled.div`
  width: 100%;

  .battery {
    position: relative;
    display: block;
    margin: 20px 65px;
    background: #ffffff;
    width: 10px;
    height: 28px;
    float: left;
  }

  .battery:before {
    content: "";
    display: block;
    background: transparent;
    border: 6px solid #ffffff;
    margin: -12px;
    width: 85px;
    height: 40px;
    position: absolute;
    border-radius: 2px;
    -moz-box-shadow: 0 0 5px 5px #888;
    -webkit-box-shadow: 0 0 5px 5px#888;
    box-shadow: -2px 0 5px 2px #222;
    z-index: 2000;
  }

  .battery:after {
    content: "";
    display: block;
    background: #ffffff;
    border: 6px solid #ffffff;
    margin: 0px 80px;
    width: 6px;
    height: 16px;
    position: absolute;
    border-radius: 2px;
    -moz-box-shadow: 0 0 5px 5px #888;
    -webkit-box-shadow: 0 0 5px 5px #888;
    box-shadow: 2px 0 5px 2px #222;
    z-index: 0;
  }

  @keyframes charging {
    from {
      width: 0px;
      background: #f00;
    }
    to {
      width: 72px;
      background: #0f0;
    }
  }

  @keyframes charging2 {
    from {
      width: 0px;
    }
    to {
      width: 72px;
    }
  }

  .charging {
    animation: charging 6s ease-in-out infinite;
  }

  .charging2 {
    animation: charging2 20s ease-in-out infinite;
  }

  .charging_txt {
    font-family: arial, helvetica, sans-serif;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    clear: both;
    color: "blue";
    text-shadow: 0 2px #000;
  }

  @keyframes glow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .glow {
    animation: glow 3s ease-in-out infinite;
    animation-direction: alternate;
  }
`;

const BatteryLoader = () => (
  <BatteryLoaderStyles>
    <div className="battery charging"></div>
    {/* <div class="charging_txt glow">Loading...</div> */}
  </BatteryLoaderStyles>
);

export default BatteryLoader;

import React, { createContext, useState } from "react";

export const ModalContext = createContext({});

const ModalContextProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  return (
    <ModalContext.Provider
      value={{
        modalOpen,
        setModalOpen,
        modalContent,
        setModalContent,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;

import styled from "styled-components";

const StyledHeader = styled.div`
  .logout-button {
    color: black;
    margin-left: 10px;
    margin-right: 15px;

    :hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
  svg {
    font-size: 38px;
  }

  .navbar {
    min-height: 64px;
    background-color: #ffffff;
    position: fixed;
    display: flex;
    flex-direction: row;
    box-shadow: none;
  }
  .toolbarLeft {
    width: 250px;
    background-color: ${props => props.theme.midnightBlue};
    padding: 0;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
    -moz-box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
    box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
  }

  .imgHeader {
    /* animation: bounce 2s;
    -webkit-animation: bounce 2s; */
    margin-left: 27px;
    width: 60%;
  }
  .toolbar {
    padding: 0;
    max-height: 64px;
    width: calc(100% - 250px);
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
    -moz-box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
    box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
  }

  .state-type {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-grow: 1;

    p {
      margin: 0;
      margin-left: 10px;
      margin-right: 5px;
    }
  }
  .grow {
    flex-grow: 1;
    color: ${props => props.theme.midnightBlue};
    font-family: ${props => props.theme.font};
    font-size: 28px;
    margin-left: 30px;
    flex-direction: row;
    display: flex;
  }

  p {
    color: black;
  }

  .logoutButton {
    color: black;
    font-size: 48px;
    margin: 0 3%;
  }

  .homeButton {
    margin-left: 5%;
  }

  .homeButton:hover {
    opacity: 0.4;
    cursor: pointer;
  }
`;
export { StyledHeader };

import React, { useContext } from "react";
import styled from "styled-components";
import OtherLoader from "../../components/OtherLoader/OtherLoader";
import SideNav from "../../components/SideNav/SideNav";
import { Context } from "../../Context/ProposalContext";

export const StyledApp = styled.div`
  /* margin: 95px auto 30px auto; */
  margin-top: 100px;
  margin-left: ${(props) => props.show && "75px"};
  transition: all 0.5s;

  .error {
    margin-top: 100px;
    display: flex;
    align-self: center;
  }
`;

const RequestAccessStyles = styled.div`
  display: flex;
  margin-top: 100px;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;

  .card {
    display: flex;
    width: 33%;
    border: solid 2px lightgray;
    border-radius: 10px;
    background: white;
    padding: 15px;
    letter-spacing: 0.03rem;
    text-align: center;
    flex-direction: column;
    align-items: center;

    button {
      display: flex;
      color: white;

      background: #011649;
      align-items: center;
      padding: 10px;
      border-radius: 30px;
      justify-content: center;
      font-size: 20px;
      :hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }
`;

const Landing = ({ error, loading, data, addUser, Component }) => {
  if (error)
    return (
      <StyledApp>
        <p className="error">An Error Occured</p>
      </StyledApp>
    );
  if (loading) return <OtherLoader />;
  if (!data.userbyemail) return <RequestAccess addUser={addUser} />;

  if (data)
    return <Permissions data={data.userbyemail} Component={Component} />;
};

const Permissions = ({ Component, data }) => {
  const { show, setPermissions, appPermissions } = useContext(Context);
  // console.log(process.env);

  const permissions = data.permissions;

  const admin = ["ADMIN", "PERMISSIONUPDATE"];
  const user = ["USER", "READ", "WRITE"];
  //const battery = []
  const isAdmin = permissions.some((permission) => admin.includes(permission));
  const isUser = permissions.some((permission) => user.includes(permission));

  if (appPermissions.admin === "") {
    setPermissions({
      permissionsList: data.permissions,
      admin: isAdmin,
      user: isUser,
      _id: data.id,
    });
    return (
      <>
        <SideNav />
        <StyledApp show={false}></StyledApp>
      </>
    );
  }

  if (isAdmin)
    return (
      <>
        <SideNav user={isUser} admin={isAdmin} />
        <StyledApp show={show}>
          <Component env={process.env.NODE_ENV} data={data} />
        </StyledApp>
      </>
    );
  if (isUser)
    return (
      <>
        <SideNav user={isUser} />
        <StyledApp show={show}>
          <Component env={process.env.NODE_ENV} data={data} />
        </StyledApp>
      </>
    );
  else {
return <RequestRecieved />;
  }
};

const RequestAccess = ({ addUser }) => {
  return (
    <RequestAccessStyles>
      <div className="card">
        <p>
          You are not currently a registered User, request Access by clicking
          the button below and your manager will review your request.
        </p>
        <button onClick={() => addUser()}>Request Access</button>
      </div>
    </RequestAccessStyles>
  );
};

const RequestRecieved = () => {
  return (
    <RequestAccessStyles>
      <div className="card">
        <p>
          Your request has been recieved, if you believe this is taking longer
          than ususual please reach out to your deparment head.
        </p>
      </div>
    </RequestAccessStyles>
  );
};

export default Landing;

import { gql } from "apollo-boost";

export const ALL_PROPOSALS_QUERY = gql`
  query proposals($selectedDate: Date) {
    proposals(selectedDate: $selectedDate) {
      id
      activityid
      oppnum
      RepName
      RepEmail
      AppointmentStartString
      AppointmentTimeString
      AppointmentDateString
      momentdate
      Lead
      CustomerName
      Office
      Address
      City
      State
      Zip
      Canvasser
      ProposalStatus
      Notes
      type
      panels
      inverter
      module
      PVArray
      production
      datasheetcomplete
      reprequest
      inhome
      pimg
      repnotes
      timeAssigned
      timeAssignedUnix
      proposalCompleted
      proposalStarted
      timerequested
      designUrl

      user {
        id
        proposalAgentEmail
        proposalAgentName
      }
    }
  }
`;

export const ALL_REQUESTED_PROPOSALS = gql`
  query requestedProposals {
    requestedProposals {
      id
      activityid
      oppnum
      RepName
      RepEmail
      AppointmentStartString
      AppointmentTimeString
      AppointmentDateString
      momentdate
      Lead
      CustomerName
      Office
      Address
      City
      State
      Zip
      Canvasser
      ProposalStatus
      Notes
      type
      panels
      inverter
      module
      PVArray
      production
      datasheetcomplete
      reprequest
      inhome
      pimg
      repnotes
      timeAssigned
      timeAssignedUnix
      proposalCompleted
      proposalStarted
      timerequested
      designUrl
      user {
        id
        proposalAgentEmail
        proposalAgentName
      }
    }
  }
`;

export const ALL_USERS_QUERY = gql`
  query users {
    users {
      id
      proposalAgentName
      proposalAgentEmail
      registered
      permissions

      proposalsAssigned {
        id
        activityid
        oppnum
        RepName
        RepEmail
        AppointmentStartString
        AppointmentTimeString
        AppointmentDateString
        momentdate
        Lead
        CustomerName
        Office
        Address
        City
        State
        Zip
        Canvasser
        ProposalStatus
        Notes
        type
        panels
        inverter
        module
        PVArray
        production
        datasheetcomplete
        reprequest
        inhome
        pimg
        repnotes
        timeAssigned
        timeAssignedUnix
        proposalCompleted
        proposalStarted
        timerequested
        designUrl
      }
      proposalsCompleted {
        id
      }

      UserTime {
        _id
        lastLogin
        lastAction
        lastActionString
      }
    }
  }
`;

export const COMPLETED_TODAY_QUERY = gql`
  query completedToday {
    completedToday {
      id
      panels
      panelWattage
      inverterWattage
      size
      consumption
      utilityRate
      solarRate
      wholesaleRate
      partner
      finnancialPartner
      ppW
      EPC
      downPayment
      loanAmount
      loanTerm
      loanPayment
      interest
      offset
      oldAnnualBill
      oldMonthlyBill
      utilityEscalator
      solarLoanITC
      annualPVDeg
      SRECPrice
      paymentFactor
      rebate
      proposalType
      solarEscalator
      inverter
      solarPanel
      production
      preparerEmail
      preparerName
      activityid
      RepName
      RepEmail
      CustomerName
      oppnum
      Address
      City
      State
      Zip
      designUrl
      annualElectricityCost
      solarSavings
      pvGenArray
      pdfUrl
      completedOn
      completedOnUnix
      pimg
      repnotes
    }
  }
`;

export const USER_BY_EMAIL_QUERY = gql`
  query userbyemail($proposalAgentEmail: String!) {
    userbyemail(proposalAgentEmail: $proposalAgentEmail) {
      id
      proposalAgentName
      proposalAgentEmail
      permissions

      proposalsCompleted {
        id
        panels
        panelWattage
        inverterWattage
        size
        consumption
        utilityRate
        solarRate
        wholesaleRate
        partner
        ppW
        EPC
        downPayment
        loanAmount
        loanTerm
        loanPayment
        interest
        offset
        oldAnnualBill
        oldMonthlyBill
        utilityEscalator
        solarLoanITC
        annualPVDeg
        SRECPrice
        paymentFactor
        rebate
        proposalType
        solarEscalator
        inverter
        solarPanel
        production
        preparerEmail
        activityid
        RepName
        RepEmail
        CustomerName
        oppnum
        Address
        City
        State
        Zip

        designUrl
        completedOn
        completedOnUnix
        annualElectricityCost
        solarSavings
        pvGenArray
        pdfUrl
        timeAssigned
        timeAssignedUnix
        proposalDraftNum
        proposalwon
      }

      proposalsAssigned {
        id
        activityid
        oppnum
        RepName
        RepEmail
        AppointmentStartString
        AppointmentTimeString
        AppointmentDateString
        Lead
        CustomerName
        Office
        Address
        City
        State
        Zip
        Canvasser
        ProposalStatus
        Notes
        type
        panels
        inverter
        module
        production
        datasheetcomplete
        timeAssigned
        timeAssignedUnix
        proposalCompleted
        proposalStarted
        pimg
        inhome
        repnotes
        timerequested
        designUrl
      }
    }
  }
`;

export const ALL_UTILITY_BILLS = gql`
  query UtilityBills {
    UtilityBills {
      _id
      utilityName
      utilityState
      billImg
    }
  }
`;

export const COMPLETED_PROPOSALS_QUERY = gql`
  query allCompletedProposalsPaginated($limit: Int, $cursor: ID) {
    allCompletedProposalsPaginated(limit: $limit, cursor: $cursor) {
      id
      panels
      panelWattage
      inverterWattage
      size
      consumption
      utilityRate
      solarRate
      wholesaleRate
      partner
      ppW
      EPC
      downPayment
      loanAmount
      loanTerm
      loanPayment
      interest
      offset
      completedOn
      completedOnUnix
      oldAnnualBill
      oldMonthlyBill
      utilityEscalator
      solarLoanITC
      annualPVDeg
      SRECPrice
      paymentFactor
      rebate
      proposalType
      solarEscalator
      inverter
      solarPanel
      production
      preparerEmail
      preparerName
      activityid
      RepName
      RepEmail
      CustomerName
      oppnum
      Address
      City
      State
      Zip
      designUrl
      annualElectricityCost
      solarSavings
      pvGenArray
      pdfUrl
    }
  }
`;

import React, { useContext, useState } from "react";
import { withRouter } from "react-router";

import { ButtonGroup } from "@material-ui/core";
import { StyledButton } from "../AdminHome/AdminHome";
import { AdminHomeStyles } from "../AdminHome/styles";
import ProductTable from "../../components/ProductTable";
import { client } from "../../App";
import { ALL_PRODUCTS_QUERY } from "../../utils/ProductsQueries";
import { Context } from "../../Context/ProposalContext";

const Products = ({ history }) => {
  const { allProducts } = client.readQuery({
    query: ALL_PRODUCTS_QUERY,
  });

  const { appPermissions } = useContext(Context);
  const { admin } = appPermissions;

  if (!admin) history.push("/");

  const [activeTab, setActiveTab] = useState("allPanels");

  const dataFilter = () => {
    if (!allProducts) return [];
    const productData = allProducts[activeTab];

    return productData;
  };

  const buttonColor = (name) => {
    if (activeTab === name) {
      return { color: "midnightBlue", fontWeight: "bolder", fontSize: "18px" };
    }
    return { color: "cadetBlue", fontWeight: "normal", fontSize: "14px" };
  };
  return (
    <AdminHomeStyles>
      <div className="admin-nav">
        <ButtonGroup aria-label="outlined primary button group" variant="text">
          <StyledButton
            onClick={() => setActiveTab("allPanels")}
            // eslint-disable-next-line no-use-before-define
            isselected={buttonColor("allPanels")}
          >
            <div className="text-count">Panels</div>
          </StyledButton>

          <StyledButton
            onClick={() => setActiveTab("inverters")}
            // eslint-disable-next-line no-use-before-define
            isselected={buttonColor("inverters")}
          >
            <div className="text-count">Inverters</div>
          </StyledButton>
        </ButtonGroup>
      </div>
      <ProductTable activeProducts={activeTab} productData={dataFilter()} />
    </AdminHomeStyles>
  );
};

export default withRouter(Products);

import React, { useState } from "react";
import styled from "styled-components";
import { TableRow, TableCell, Checkbox } from "@material-ui/core";
import { Check, DeleteForever } from "@material-ui/icons";
import { useMutation } from "@apollo/react-hooks";
import { ALL_USERS_QUERY } from "../../utils/GQL/QLQueries.js";
import {
  REMOVE_USER_MUTATION,
  UPDATE_USER_MUTATION,
} from "../../utils/QLMutations.js";

const IconStyles = styled.div`
  display: flex;
  flex-direction: row;

  svg:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const UserRow = ({ user, availablePermisisons }) => {
  const [permissionsState, setPermissions] = useState(user.permissions);
  const userPerms = ["USER", "READ", "WRITE"];
  const adminPerms = ["ADMIN", "PERMISSIONUPDATE", "ASSIGN"];
  const [removeUser] = useMutation(REMOVE_USER_MUTATION, {
    variables: {
      _id: user.id,
    },
    update(cache) {
      const oldData = cache.readQuery({
        query: ALL_USERS_QUERY,
      });
      const newData = oldData.users.filter((oldUser) => oldUser.id !== user.id);

      cache.writeQuery({
        query: ALL_USERS_QUERY,

        data: { users: newData },
      });
    },
  });
  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    variables: {
      userId: user.id,
      permissions: permissionsState,
    },
    refetchQueries: [{ query: ALL_USERS_QUERY }],
    // update(cache, { data: { updateUser: updateUserData } }) {
    //   const oldData = cache.readQuery({
    //     query: ALL_USERS_QUERY
    //   });

    //   cache.writeQuery({
    //     query: ALL_USERS_QUERY,

    //     data: { users: [...oldData.users, updateUserData] }
    //   });
    // }
  });

  const onCheckChange = (thePermission) => {
    const permStateFilter = permissionsState.filter(
      (perm) => perm === thePermission
    );

    if (thePermission === "USER") {
      const userFilter = permissionsState.filter((perm) => perm === "USER");
      if (userFilter.length > 0) {
        const filterOutUserPerms = permissionsState.filter(
          (perm) => !userPerms.includes(perm)
        );
        setPermissions(filterOutUserPerms);
      } else {
        setPermissions([...permissionsState, ...userPerms]);
      }
    } else if (thePermission === "ADMIN") {
      const userFilter = permissionsState.filter((perm) => perm === "ADMIN");
      if (userFilter.length > 0) {
        const filterOutAdminPerms = permissionsState.filter(
          (perm) => !adminPerms.includes(perm)
        );
        setPermissions(filterOutAdminPerms);
      } else {
        setPermissions([...permissionsState, ...adminPerms]);
      }
    } else if (permStateFilter.length === 0) {
      setPermissions([...permissionsState, thePermission]);
    } else {
      const newPermState = permissionsState.filter(
        (perm) => perm !== thePermission
      );
      setPermissions(newPermState);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>{user.proposalAgentName}</TableCell>
        <TableCell>{user.proposalAgentEmail}</TableCell>

        {availablePermisisons.map((permission, index) => (
          <TableCell key={`${user.id}-${user.proposalAgentEmail}-${index}`}>
            <Checkbox
              key={`${permission}-${user.id}`}
              onChange={() => onCheckChange(permission)}
              checked={permissionsState.includes(permission)}
            />
          </TableCell>
        ))}
        <TableCell>
          <IconStyles>
            <Check onClick={() => updateUser()} />
            <DeleteForever onClick={() => removeUser()} />
          </IconStyles>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserRow;

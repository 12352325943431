import React, { useState, useContext } from "react";
import { Select, MenuItem } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useMutation } from "@apollo/react-hooks";
import {
  ALL_REQUESTED_PROPOSALS,
  USER_BY_EMAIL_QUERY,
} from "../../utils/GQL/QLQueries";
import { Context } from "../../Context/ProposalContext";
import styled from "styled-components";
import { ASSIGN_PROPOSAL_MUTATION } from "../../utils/QLMutations";

const AssignStyles = styled.div`
  display: flex;
  align-items: center;
  transition: 0.5s;
  .check {
    color: green;
    font-size: 26px;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
`;

const AssignProposal = ({ id, allUserData }) => {
  const {
    userValues: { userName },
  } = useContext(Context);
  const [selectedUser, setSelectedUser] = useState("");

  const [assignProposal] = useMutation(ASSIGN_PROPOSAL_MUTATION);

  const onSetSelectedUser = (e) => {
    setSelectedUser(e.target.value);
  };

  const onAssignProposal = (e) => {
    assignProposal({
      variables: {
        userId: selectedUser,
        proposalId: id,
      },
      refetchQueries: [
        { query: ALL_REQUESTED_PROPOSALS },
        {
          query: USER_BY_EMAIL_QUERY,
          variables: {
            proposalAgentEmail: userName,
          },
        },
      ],
    });
  };

  return (
    <AssignStyles>
      <Select
        style={{ minWidth: "80%" }}
        value={selectedUser}
        onChange={(e) => onSetSelectedUser(e)}
      >
        {allUserData.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {user.proposalAgentName}
          </MenuItem>
        ))}
      </Select>
      {selectedUser && (
        <Check className="check" onClick={() => onAssignProposal()} />
      )}
    </AssignStyles>
  );
};

export default AssignProposal;

import React from "react";
import { OtherLoaderWrapper } from "./styles";
const OtherLoader = () => {
  return (
    <OtherLoaderWrapper>
      <div id="preloader">
        <div id="loader" />
      </div>
    </OtherLoaderWrapper>
  );
};

export default OtherLoader;

import React from "react";

import styled from "styled-components";

const AppointmentGridStyles = styled.div`
  width: 100%;

  .container {
    display: grid;
    width: 93%;
    grid-auto-flow: column;
    grid-gap: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    grid-auto-columns: 1fr;
    justify-items: center;

    border: solid 2px rgb(60, 199, 250);
    border-radius: 4px;
    background: white;
    font-size: 1.6vmin;
    justify-self: baseline;
    grid-template-rows: auto 1fr;

    box-shadow: 7px 3px 9px rgba(60, 190, 250, 0.36);
  }

  .vert-container {
    display: grid;
    width: 93%;
    grid-auto-flow: row;
    grid-gap: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    grid-auto-columns: 1fr;
    justify-items: center;

    border: solid 2px rgb(60, 199, 250);
    border-radius: 4px;
    background: white;
    font-size: 1.6vmin;
    justify-self: baseline;

    box-shadow: 7px 3px 9px rgba(60, 190, 250, 0.36);
  }
  .rrow {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat;
    width: 100%;
    grid-auto-flow: column;
  }
  .everything-else {
    display: grid;
    grid-auto-flow: column;
    width: 100%;
  }
  /* .vert-col {
    position: relative;
    margin: 3px;
  }

  .vert-col {
    :after {
      width: 400%;
      height: 3px;
      content: "";
      position: absolute;
      left: 0;

      height: 1px;
      background-color: red;
    } */

  .col {
    padding: 4px;
    text-align: center;
  }
  .row {
    display: grid;
    /* grid-template-columns: 80px repeat(3, 150px); */
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    width: 100%;
  }

  .vert-col {
    padding: 4px;
    text-align: center;
  }

  .vert-headings {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    width: 100%;
  }

  .sm {
    font-size: 12px;
  }
`;

const AppointmentGrid = ({ gridData = [], timesPresent, orientation }) => {
  if (!gridData || gridData.length === 0) return "";
  if (orientation === "horizontal")
    return (
      <AppointmentGridStyles>
        <div className="container">
          <div className="rrow">
            <div className="col"></div>
            {/* {gridData.map((d) => (
              <div className="col">{d.timeSlot} </div>
            ))} */}
          </div>
          <div className="everything-else">
            <div className="vert-row">
              <div className="vert-col"> DN</div>
              <div className="vert-col"> ADN</div>
              <div className="vert-col"> AA</div>
            </div>

            {gridData.map(
              ({
                timeSlot,
                DataNeeded = [],
                AssignedDataNeeded = [],
                AssignedDataNotNeeded = [],
              }) => (
                <div key={timeSlot} className="vert-row">
                  <div className="vert-col"> {DataNeeded.length}</div>

                  <div className="vert-col"> {AssignedDataNeeded.length}</div>
                  <div className="vert-col">
                    {" "}
                    {AssignedDataNotNeeded.length}
                  </div>
                </div>
              )
            )}
          </div>

          {/* {times.map((ts) =>
          gridData.map(
            (d) =>
              console.log(d) || (ts === d.timeSlot ? d.DataNeeded.length : 0)
          )
        )} */}

          {/* 
      {gridData.map(({ timeSlot }) => 
        
      
      )} */}
        </div>
      </AppointmentGridStyles>
    );
  if (orientation === "vertical")
    return (
      <AppointmentGridStyles>
        <div className="vert-container">
          <div className="vert-headings">
            <div className="col sm"> Time </div>
            <div className="col sm"> AA </div>
            <div className="col sm"> DN</div>
            <div className="col sm"> ADN </div>
          </div>
          {gridData.map(
            ({
              timeSlot,
              AssignedDataNeeded = [],
              AssignedDataNotNeeded = [],
              NotAssignedAndDataNeeded = [],
            }) => (
              <div key={timeSlot} className="row">
                <div className="col"> {timeSlot} </div>
                <div className="col"> {AssignedDataNotNeeded.length} </div>
                <div className="col"> {AssignedDataNeeded.length}</div>
                <div className="col"> {NotAssignedAndDataNeeded.length}</div>
              </div>
            )
          )}
        </div>
      </AppointmentGridStyles>
    );
};

export default AppointmentGrid;

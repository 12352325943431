import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/react-hooks";
import moment from "moment";
import { ALL_PROPOSALS_QUERY } from "../../../../utils/GQL/QLQueries";

import ProposalTable from "../../../../components/ProposalTable/ProposalTable";
import AppointmentChart from "../../AppointmentCharts/AppointmentChart";
import { timeOneDay } from "./helpers";
import AppointmentDateSelect from "./AppointmentDateSelect";
import AppointmentGrid from "./AppointmentGrid/AppointmentGrid";
import { CropLandscape } from "@material-ui/icons";

const AppointmentTrackerStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  height: calc(100vh - 150px);
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  margin-right: 5px;

  .border {
    padding: 5px;

    overflow: auto;
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .selected-states {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));

    justify-content: center;
    width: 100%;
  }

  .MuiFormControl-marginNormal {
    margin: 0;
  }

  .options-selection {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-items: center;
    grid-gap: 30px;
  }

  .state {
    border: solid 1px #299aff;
    padding: 8px;
    position: relative;
    padding-right: 25px;
    padding-left: 25px;
    border-radius: 4px;

    :hover {
      cursor: pointer;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
  }

  .selected {
    color: white;
    background: #299aff;

    border: solid 1px #299aff;
    padding: 8px;
    position: relative;
    padding-right: 25px;
    padding-left: 25px;
    border-radius: 4px;

    :hover {
      cursor: pointer;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
  }
`;

const StyledCropLandscape = styled(CropLandscape)`
  transition: 0.5s all;

  transform: ${(props) => props.orientation === "vertical" && `rotate(90deg)`};

  font-size: 40px;
  :hover {
    cursor: pointer;
  }
`;

export const tableHeaders = [
  { id: ".9", label: "Sales Rep", key: "RepName" },
  { id: "1", label: "Opportunity Number", key: "oppnum" },
  { id: "2", label: "Customer", key: "CustomerName" },
  { id: "3", label: "State", key: "State" },

  { id: "4", label: "Appointment Address", key: "Address" },
  { id: "5", label: "Appointment Time", key: "AppointmentTimeString" },
  { id: "6", label: "Appointment Date", key: "AppointmentDateString" },
  { id: "7", key: "ProposalDataSheetUrl", label: "Data Sheet Url" },
  { id: "8", key: "ProposalDataStatus", label: "Data Sheet Status" },
  { id: "9", key: "ProposalOwner ", label: "Prposal Owner" },
];

const AllStates = [
  "GA",
  "CA",
  "CT",
  "FL",
  "NJ",
  "NY",
  "PA",
  "TX",
  "NV",
  "AZ",
  "MA",
];

const AppointmentTracker = () => {
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().format());
  const [orientation, setOrientation] = useState("horizontal");
  const [timesPresent, setTimesPresent] = useState(false);
  //const { data, error, loading } = useQuery(ALL_PROPOSALS_QUERY);

  let [getAppointments, { loading, data = [], error, called }] = useLazyQuery(
    ALL_PROPOSALS_QUERY,
    {
      //onCompleted: (data) => console.log(data, "data"),
    }
  );

  useEffect(() => {
    getAppointments();
  }, [getAppointments]);

  const composeAllReturnData = (data, statesSelected) => {
    // let propsToday = proposalsToday(data);
    if (!data) return [];
    let propsToday = data;
    let stateFiltered = filterStates(propsToday, statesSelected);

    let allHours = timeOneDay();

    let allApptsByHour = appointmentsByHour(stateFiltered, allHours);

    return allApptsByHour;
  };

  const onSetSelectedTimes = (timeSelection) => {
    if (!selectedTimes.includes(timeSelection)) {
      setSelectedTimes((prevState) => [...prevState, timeSelection]);
      return;
    }
    let newbies = selectedTimes.filter((s) => s !== timeSelection);
    setSelectedTimes(newbies);
  };

  const addRemoveState = (state, selectedStates) => {
    if (!selectedStates.includes(state)) {
      setSelectedStates([...selectedStates, state]);
      return;
    }

    let newbies = selectedStates.filter((s) => s !== state);
    setSelectedStates(newbies);
  };

  const appointmentsByHour = (propsToday, allHours) => {
    propsToday.forEach((element) => {
      let apptStart = moment(element.AppointmentStartString)
        .add(4, "hours")
        .format("hA");

      let foundTimeSlot = allHours.findIndex(
        ({ timeSlot }) => timeSlot === apptStart
      );

      let updatedAppointments = [
        ...allHours[foundTimeSlot].appointments,
        element,
      ];

      allHours[foundTimeSlot] = {
        ...allHours[foundTimeSlot],
        appointments: updatedAppointments,
        appointmentTotals: updatedAppointments.length,
      };
    });

    let trimmedHoursArr = [];
    allHours.forEach((element, i) => {
      // let prevHour = allHours[i === 0 ? i : i - 1].appointmentTotals;
      // let nextHour =
      //   allHours[i === allHours.length - 1 ? i : i + 1].appointmentTotals;

      if (element.appointmentTotals !== 0) {
        trimmedHoursArr.push(element);
      }
    });

    return trimmedHoursArr;
  };

  // let proposalsToday = (data) =>
  //   !data
  //     ? []
  //     : data.filter(
  //         ({ AppointmentStartString }) =>
  //           moment(AppointmentStartString).startOf("day").format() ===
  //           moment().startOf("day").format()
  //       );

  let proposalsTodayMassaged = (data) => {
    return data.reduce((prev, curr) => {
      curr = {
        ...curr,
      };
      prev.push(curr);
      return prev;
    }, []);
  };

  const filterStates = (data, statesSelected) =>
    statesSelected.length > 0
      ? data.filter(({ State }) => statesSelected.includes(State))
      : data;

  const filterByTimesSelected = (data, selectedTimes) =>
    selectedTimes.length > 0
      ? data.filter(({ AppointmentStartString }) =>
          selectedTimes.includes(
            moment(AppointmentStartString).add(4, "hours").format("hA")
          )
        )
      : data;

  const nullOrNone = (val) => {
    if (!val) return null;
    else if (val === "None") return null;
    else if (val === "") return null;
    else return val;
  };

  const BottomGridData = (data, statesSelected) => {
    if (!data) return [];

    let propsToday = data;
    let stateFiltered = filterStates(propsToday, statesSelected);
    let timeSelectedProps = filterByTimesSelected(stateFiltered, selectedTimes);

    let allHours = timeOneDay();

    let allApptsByHour = appointmentsByHour(timeSelectedProps, allHours);

    let thisD = allApptsByHour.map((appt) =>
      appt.appointments.reduce((prev, curr) => {
        // console.log(curr);

        // First Set of COnditons, if all these values exist it is Assigned and There is no Data needed //AA

        // if (curr.id === "5f618dc4a5977bc2af51d833") {
        if (
          nullOrNone(curr.ProposalDataSheetUrl) &&
          nullOrNone(curr.ProposalDataStatus) &&
          nullOrNone(curr.ProposalOwner) &&
          nullOrNone(curr.RepEmail)
        ) {
          // console.log("hits 1");
          prev = {
            ...appt,
            ...prev,
            AssignedDataNotNeeded: prev.AssignedDataNotNeeded
              ? [...prev.AssignedDataNotNeeded, curr]
              : [curr],
          };
        }

        // DN Data needed - regardless if Assigned or Not
        if (
          nullOrNone(curr.ProposalDataSheetUrl) === null ||
          nullOrNone(curr.ProposalOwner) === null ||
          nullOrNone(curr.ProposalDataStatus) === null
        ) {
          prev = {
            ...appt,
            ...prev,
            DataNeeded: prev.DataNeeded ? [...prev.DataNeeded, curr] : [curr],
          };
        }

        if (
          (nullOrNone(curr.ProposalDataSheetUrl) === null ||
            nullOrNone(curr.ProposalOwner) === null) &&
          curr.RepEmail
        ) {
          prev = {
            ...appt,
            ...prev,
            AssignedDataNeeded: prev.AssignedDataNeeded
              ? [...prev.AssignedDataNeeded, curr]
              : [curr],
          };
        }

        //  }

        // Second Set Of Conditions if there is not a Sales Rep Assigned It is Unassigned
        // else if (!nullOrNone(curr.RepEmail)) {
        //   console.log("Presumably this cond hit");
        //   console.log(curr.RepEmail);
        // }

        return prev;
      }, {})
    );

    return thisD;
  };

  const ComposePreperationOfData = (data, statesSelected) => {
    // let propsToday = proposalsToday(data);
    if (!data) return [];
    let propsToday = data;

    let stateFiltered = filterStates(propsToday, statesSelected);
    let timeSelectedProps = filterByTimesSelected(stateFiltered, selectedTimes);

    let moreMassaging = proposalsTodayMassaged(timeSelectedProps);

    return moreMassaging;
  };

  // moment(AppointmentStartString).startOf("day") ===
  // moment().startOf("day")

  //if (loading || !called) return <OtherLoader />;
  if (error) return "An Error Occured";
  return (
    <AppointmentTrackerStyles>
      <div className="table-con border">
        <ProposalTable
          data={ComposePreperationOfData(
            data.proposals,
            selectedStates,
            loading
          )}
          HidePaginationProp={true}
          tableHeaders={tableHeaders}
          disableHomeFilter={true}
        ></ProposalTable>
      </div>

      <div className="chart-con border">
        {/* <div className="">Appointment Count</div> */}
        <div className="options-selection">
          <AppointmentDateSelect
            getAppointments={getAppointments}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <StyledCropLandscape
            onClick={() => {
              if (orientation === "vertical") {
                setOrientation("horizontal");
              } else {
                setOrientation("vertical");
              }
            }}
            orientation={orientation}
          />
        </div>
        <div className="selected-states">
          {AllStates.map((state) => (
            <span
              key={state}
              onClick={() => addRemoveState(state, selectedStates)}
              className={`${
                selectedStates.includes(state) ? "selected" : "state"
              }`}
            >
              {state}
            </span>
          ))}
        </div>
        <AppointmentChart
          onSetSelectedTimes={onSetSelectedTimes}
          apptData={composeAllReturnData(
            data.proposals,
            selectedStates,
            loading
          )}
        />
        <AppointmentGrid
          gridData={BottomGridData(data.proposals, selectedStates)}
          timesPresent={timesPresent}
          orientation={orientation}
        />
      </div>
    </AppointmentTrackerStyles>
  );
};

export default AppointmentTracker;

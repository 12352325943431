import React, { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { ButtonGroup, Select, MenuItem } from "@material-ui/core";
import moment from "moment";
import styled from "styled-components";
import NewModal from "./NewModal";
//import { InputStyles } from "../containers/NonUniqueInputs/NonUniques";
import { StyledButton, buttonColor } from "../containers/AdminHome/AdminHome";
import { imagePOST } from "../utils/API";
import {
  PANEL_IMAGES_QUERY,
  ADD_PANEL_IMAGE_MUTATION,
} from "../utils/ProductsQueries";
const MainStyles = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;
export const InputStyles = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  .label-styles {
    display: flex;
    flex-direction: column;
  }

  img {
    width: 325px;
    max-height: 300px;
  }
  label {
    /* display: block; */
    margin-bottom: 2rem;
  }
  input,
  textarea,
  select {
    width: 70%;
    padding: 0.2rem;
    font-size: 1rem;
    border: 1px solid black;
    display: flex;
    &:focus {
      outline: 0;
      border-color: #3db0ef;
    }
  }
`;

const ButtonStyles = styled.button`
  display: flex;
  color: white;
  background: #3db0ef;
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  :hover {
    color: #011649;
    background: white;
    border-color: #011649;
    cursor: pointer;
  }
`;
const NewProductModal = ({ open, setOpen, productState, setProductState }) => {
  const [getPanelImages, { loading, data }] = useLazyQuery(PANEL_IMAGES_QUERY);
  const [addPanelImage] = useMutation(ADD_PANEL_IMAGE_MUTATION);
  const [activeTab, setActiveTab] = useState("upload");
  const [filePreview, setFilePreview] = useState("");
  const [selectedPanelImage, setSelectedPanelImage] = useState("");
  const [uploadFile, setUploadFile] = useState("");

  const lastOccurence = (str) => {
    const lastSlash = str.lastIndexOf("/");

    const newString = str.substr(lastSlash + 1);

    const lastDot = newString.lastIndexOf(".");

    const newestString = newString.substr(0, lastDot);
    return newestString;
  };

  const panelIndex = () => {
    // const index = data.panelImages.findIndex(
    //   panelImage => panelImage._id === selectedPanelImage
    // );

    const panel = data.panelImages.find(
      (panelImage) => panelImage._id === selectedPanelImage
    );

    return panel.panelUrl;
  };

  const uploadImg = async () => {
    let formData = new FormData();

    formData.append("Bucket", "panelimages");
    // not the moment error

    formData.append(
      "Key",
      `${uploadFile.name.trim()}-${moment().format("MM-DD-YYYY-hh:mm:ss-a")}`
    );

    formData.append("Body", uploadFile);

    const imageUploadRes = await imagePOST(formData);

    return imageUploadRes.data.url;
  };

  const handleUpload = async (e) => {
    const selectedFile = document.getElementById("upload").files[0];
    //const name = e.target.name;

    let reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result);
    };

    reader.readAsDataURL(selectedFile);
    setUploadFile(selectedFile);
    // setOtherValues({
    //   ...otherValues,
    //   designUrl,
    //   [name]: selectedFile
    // });
  };

  const handleDropChange = (e) => {
    setSelectedPanelImage(e.target.value);
    setProductState({
      ...productState,
      productImage: e.target.value,
    });
  };

  const setTabAndLoadData = () => {
    setActiveTab("existing");
    getPanelImages();
  };

  const onConfirmUpload = async () => {
    const awsRes = await uploadImg();

    const res = await addPanelImage({
      variables: {
        panelUrl: awsRes,
      },
    });

    setProductState({
      ...productState,
      productImage: res.data.addPanelImage._id,
    });
  };

  return (
    <NewModal open={open} setOpen={setOpen}>
      <MainStyles>
        <h1> Image For -- {productState.name}</h1>

        <ButtonGroup aria-label="outlined primary button group" variant="text">
          <StyledButton
            onClick={() => setActiveTab("upload")}
            isselected={buttonColor("upload", activeTab)}
          >
            Upload New
          </StyledButton>
          <StyledButton
            onClick={() => setTabAndLoadData()}
            isselected={buttonColor("existing", activeTab)}
          >
            Select From Existing
          </StyledButton>
        </ButtonGroup>
        {activeTab === "upload" ? (
          <InputStyles>
            <div className="label-styles">
              <label htmlFor="upload">
                Upload Panel / Inverter Image
                <input
                  onChange={(e) => handleUpload(e)}
                  type="file"
                  name="designImageUpload"
                  id="upload"
                  accept="image/*"
                />
              </label>
            </div>

            {filePreview && (
              <>
                <img alt="upload" src={filePreview} />
                <ButtonStyles onClick={() => onConfirmUpload()}>
                  CONFIRM UPLOAD
                </ButtonStyles>
              </>
            )}
          </InputStyles>
        ) : loading ? (
          "Loading Panel Images"
        ) : (
          <>
            <Select
              value={selectedPanelImage}
              onChange={(e) => handleDropChange(e)}
              // name={"solarPanel"}
            >
              {data.panelImages.map((panelImage) => (
                <MenuItem key={panelImage._id} value={panelImage._id}>
                  {lastOccurence(panelImage.panelUrl)}
                </MenuItem>
              ))}
            </Select>
            {selectedPanelImage && (
              <>
                <img
                  alt="dropwdown-panel"
                  style={{ width: "350px" }}
                  src={panelIndex()}
                ></img>
              </>
            )}
          </>
        )}
      </MainStyles>
    </NewModal>
  );
};

export default NewProductModal;

import React, { useContext } from "react";

//import { Nature, FilterHdr, Cancel, CheckSharp } from "@material-ui/icons";
import { InputStyles } from "../containers/NonUniqueInputs/NonUniques";
import {
  TextFieldStyles,
  FormControlStyles,
  SelectStyles,
} from "../Refactored/StartProposalV2/styles";
import { Context } from "../Context/ProposalContext";
import moment from "moment";
import { uploadImg } from "../utils/API";
import { MenuItem, InputLabel } from "@material-ui/core";

const WorkInfo = ({ active }) => {
  const {
    // setAdditionalWorkRequired,
    otherValues,
    setOtherValues,
    initialFormValues,
  } = useContext(Context);

  const returnThis = (findKey) =>
    otherValues.additionalwork.find(
      (work) => work.type === findKey.toLowerCase()
    );

  const getindex = (findKey) =>
    otherValues.additionalwork.findIndex(
      (work) => work.type === findKey.toLowerCase()
    );

  const handleNotes = (e) => {
    const index = getindex(active);
    const additionalworkcopy = [...otherValues.additionalwork];

    additionalworkcopy[index][e.target.name] =
      e.target.name === "treeNum" ? +e.target.value : e.target.value;
    console.log({
      ...otherValues,
      additionalwork: additionalworkcopy,
    });
    setOtherValues({
      ...otherValues,
      additionalwork: additionalworkcopy,
    });
  };

  const handleUpload = async (e) => {
    const selectedFile = document.getElementById(
      `additional-work-upload-${active}`
    ).files[0];

    const index = getindex(active);
    const additionalworkcopy = [...otherValues.additionalwork];

    // const name = e.target.name;
    let reader = new FileReader();
    reader.onloadend = () => {
      const readerRes = reader.result;

      additionalworkcopy[index]["filePreview"] = readerRes;

      setOtherValues({
        ...otherValues,
        additionalwork: additionalworkcopy,
      });
      // setFilePreview(reader.result);
    };

    // const designUrl = await uploadImg(selectedFile);

    const additonalWorkImage = await uploadImg(
      selectedFile,
      `${initialFormValues.oppnum.trim()}/addWorkImg-${active}-${moment().format(
        "MM-DD-YYYY-hh:mm:ss-a"
      )}`
    );

    reader.readAsDataURL(selectedFile);

    additionalworkcopy[index]["img"] = additonalWorkImage;

    setOtherValues({
      ...otherValues,
      additionalwork: additionalworkcopy,
    });
  };
  const handleWorkRequired = (e) => {
    const index = getindex(active);
    const additionalworkcopy = [...otherValues.additionalwork];
    // const addworkcopy2 = JSON.parse(JSON.stringify([...otherValues.additionalwork]));
    additionalworkcopy[index]["required"] = e.target.value;

    setOtherValues({
      ...otherValues,
      additionalwork: additionalworkcopy,
    });
  };
  const handleRemover = (e) => {
    const index = getindex(active);
    const additionalworkcopy = [...otherValues.additionalwork];
    // const addworkcopy2 = JSON.parse(JSON.stringify([...otherValues.additionalwork]));
    additionalworkcopy[index]["remover"] = e.target.value;

    console.log({
      ...otherValues,
      additionalwork: additionalworkcopy,
    });

    setOtherValues({
      ...otherValues,
      additionalwork: additionalworkcopy,
    });
  };

  return (
    <>
      <div className="select-2">
        <FormControlStyles>
          <InputLabel>{active} Work Required</InputLabel>
          <SelectStyles
            value={returnThis(active).required}
            name="required"
            onChange={handleWorkRequired}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </SelectStyles>
        </FormControlStyles>
        {returnThis(active).required && (
          <InputStyles>
            <div className="label-styles">
              <label htmlFor="upload">
                Upload {active} Image
                <input
                  onChange={(e) => handleUpload(e)}
                  type="file"
                  name={`additionalWorkUpload-${active}`}
                  id={`additional-work-upload-${active}`}
                  accept="image/*"
                />
              </label>
            </div>
            {returnThis(active) && returnThis(active).filePreview && (
              <img alt="upload" src={returnThis(active).filePreview} />
            )}
          </InputStyles>
        )}
      </div>
      {returnThis(active).required && (
        <div className="select-2">
          <TextFieldStyles
            type="text"
            name={"notes"}
            value={returnThis(active) ? returnThis(active).notes : ""}
            onChange={(e) => handleNotes(e)}
            label={`${active} Notes`}
            autoComplete="off"
            error={false}
          />
          <TextFieldStyles
            name={"estimatedCost"}
            value={returnThis(active) ? returnThis(active).estimatedCost : ""}
            onChange={(e) => handleNotes(e)}
            label={`Estimated ${active} Work Cost`}
            autoComplete="off"
            error={false}
            type="number"
          />
        </div>
      )}
      {returnThis(active).required && active === "Tree" && (
        <div className="select-2">
          <FormControlStyles>
            <InputLabel>Who is Removing</InputLabel>
            <SelectStyles
              value={
                otherValues.additionalwork
                  ? otherValues.additionalwork[0].remover
                  : "tbd"
              }
              name="required"
              required
              onChange={handleRemover}
            >
              <MenuItem value={"momentum"}>Momentum</MenuItem>
              <MenuItem value={"ho"}>Home Owner</MenuItem>
              <MenuItem value={"tbd"}>TBD</MenuItem>
            </SelectStyles>
          </FormControlStyles>
          <TextFieldStyles
            name={"treeNum"}
            required
            value={
              otherValues.additionalwork
                ? otherValues.additionalwork[0].treeNum
                : 0
            }
            onChange={(e) => handleNotes(e)}
            label={`# of Trees`}
            autoComplete="off"
            error={false}
            type="number"
          />
        </div>
      )}
    </>
  );
};

export default WorkInfo;

import React, { useContext } from "react";

import { Context } from "../../Context/ProposalContext";
import { Daily, Monthly, Annual } from "./ConTypes";

const ConsumptionType = () => {
  const { consumptionType } = useContext(Context);

  const Components = { Daily, Monthly, Annual };

  const Tag = Components[consumptionType];

  return (
    <>
      <Tag />
    </>
  );
};

// const BillInformation = props => {
//   return <div>{`Annual Bill ${getMontlyBill(otherValues)}`}</div>;
// };
export default ConsumptionType;

import React from "react";
import NonUniques from "../NonUniqueInputs/NonUniques";
import Rebate from "../../components/Rebate";
import { FormItself } from "../../Refactored/StartProposalV2/styles";
import NonUniqueStatic from "../NotUniqueStatic/NotUniqueStatic";
import AdditionalWork from "../AdditionalWork.js/AdditionWork";

const Cash = ({ otherValues, setOtherValues, State }) => {
  //const { proposalType } = otherValues;

  return (
    <>
      <FormItself>
        <NonUniques otherValues={otherValues} setOtherValues={setOtherValues} />

        <Rebate
          selectedState={State}
          otherValues={otherValues}
          setOtherValues={setOtherValues}
        />
      </FormItself>
      <AdditionalWork />
      <NonUniqueStatic />
    </>
  );
};

export default Cash;

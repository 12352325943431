import moment from "moment";

export function timeOneDay() {
  var hoursPerDay = 17;

  let timeArr = [];
  var formattedTime;
  for (let i = 0; i < hoursPerDay + 1; i++) {
    //fill in all of the hours
    formattedTime = moment()
      .startOf("day")
      .add(i + 6, "hours")
      .format("hA"); //give the time in format X AM/PM

    timeArr.push({
      timeSlot: formattedTime,
      appointments: [],
      appointmentTotals: 0,
    });
  }

  return timeArr;
}

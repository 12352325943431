import { gql } from "apollo-boost";

export const NEW_APPOINTMENT_MUTATION = gql`
  mutation createAppointment($input: ProposalAppointmentInput!) {
    createAppointment(input: $input) {
      _id
      RepName
      RepEmail
      CustomerName
      oppnum
      Office
      Address
      City
      State
      Zip
      type
      createdBy
    }
  }
`;

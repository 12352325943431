import React from "react";

import styled from "styled-components";
import { DROP_FROM_QUEUE_MUTATION } from "../../utils/QLMutations";
import { useMutation } from "@apollo/react-hooks";

const ButtonStyles = styled.button`
  display: flex;
  font-size: 16px;
  padding: 6px;
  color: white;

  display: flex;
  align-self: center;
  background-color: red;
  transition: 0.3s;
  border-radius: 5px;

  &:hover {
    color: rgb(0, 174, 239);
    background-color: #fff;
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const DropFromQueue = ({ id }) => {
  const [dropFromQueue] = useMutation(DROP_FROM_QUEUE_MUTATION, {
    variables: {
      id
    },
    refetchQueries: ["requestedProposals"]
  });

  return <ButtonStyles onClick={dropFromQueue}>DROP</ButtonStyles>;
};

export default DropFromQueue;

import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";

const CarouselStyles = styled.div`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    transform: rotate(90deg);
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .con-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .carousel {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const CarouselComponent = ({ pimg }) => {
  const onReadyThumbClickThumb = e => {
    // e is the index only thhign this func gives us back ???
    //pimg[e]
    window.open(pimg[e]);
  };
  return (
    <CarouselStyles>
      <div className="container">
        <Carousel
          className="con-2"
          width="70%"
          // dynamicHeight={true}
          //  onClickThumb={e => onReadyThumbClickThumb(e)}
          onClickItem={e => onReadyThumbClickThumb(e)}
        >
          {pimg.map(img => (
            <div className="img-caro" width="600px" key={img}>
              <img width="400px" alt="bill" src={`${img}`} />
            </div>
          ))}
        </Carousel>
      </div>
    </CarouselStyles>
  );
};

export default CarouselComponent;

import { sendEnphaseEmail } from "../../utils/API";

export const FranzStates = ["FL", "NJ", "CA", "PA"];

export const JeffMStates = ["NY", "CT"];

export const to = [
  "clantier@momentumsolar.com",
  "CBurkhardt@momentumsolar.com",
  "JMarinello@momentumsolar.com",
  "VGusmano@momentumsolar.com",
  "zhoch@momentumhome.com",
];

export const salesRepToAssign = (State) => {
  if (State === "TX") {
    return {
      RepName: "Brian Stilley",
      RepEmail: "bstilley@momentumsolar.com",
    };
  } else if (FranzStates.includes(State)) {
    return {
      RepName: "Matt Franz",
      RepEmail: "mfranz@momentumsolar.com",
    };
  } else
    return {
      RepName: "Jeff Marinello",
      RepEmail: "JMarinello@momentumsolar.com",
    };
};

export const sendTo = (State) => {
  if (State === "TX") {
    return [...to, "bstilley@momentumsolar.com"];
  } else if (FranzStates.includes(State)) {
    return [...to, "mfranz@momentumsolar.com"];
  } else {
    return to;
  }
};

export const resendEnphaseEmail = (info, userInfo) => {
  const { CustomerName, State, Zip, Address, _id, oppnum, RepName } = info;
  let to = sendTo(State);

  sendEnphaseEmail(CustomerName, State, Zip, Address, _id, oppnum, to, {
    ...userInfo,
    RepName,
  });
};

import React, { useContext } from "react";
import { useMutation } from "@apollo/react-hooks";

import { Context } from "../Context/ProposalContext";
import { UPDATE_ACTION_MUATION } from "../utils/QLMutations";

const UserCallToAction = ({ children }) => {
  const {
    appPermissions: { _id },
  } = useContext(Context);

  const [updateAction] = useMutation(UPDATE_ACTION_MUATION, {
    variables: {
      id: _id,
    },
  });

  const onUpdateAction = async (e) => {
    //console.log(e);
    children.props.onClick(e);
    //const updateActionRes =
    await updateAction();
    //console.log("new", updateActionRes);
  };

  return <div>{React.cloneElement(children, { onClick: onUpdateAction })}</div>;
};

export default UserCallToAction;

import React, { useContext } from "react";
import { PostAdd } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import {
  Context,
  initialOtherValues,
  initialFormVals,
} from "../../Context/ProposalContext";
import UserCallToAction from "../UserCallToAction";

const DispatchNewProposal = ({ history }) => {
  const {
    //setInitialProposalData,
    setInitialValues,

    setOtherValues,
  } = useContext(Context);

  const dispatchTheStart = () => {
    setInitialValues(initialFormVals);

    setOtherValues(initialOtherValues);
    history.push(`/startpropsal/new`);
  };
  return (
    <UserCallToAction>
      <PostAdd onClick={() => dispatchTheStart()} />
    </UserCallToAction>
  );
};

export default withRouter(DispatchNewProposal);

import { gql } from "apollo-boost";

export const ALL_PRODUCTS_QUERY = gql`
  query allProducts {
    allProducts {
      allPanels {
        _id
        name
        commonName
        pmax
        brand
        model
        active
        productImage {
          _id
          panelUrl
        }
      }
      inverters {
        _id
        name

        pmax
        brand
        model
      }
    }
  }
`;

export const ADD_PANEL_MUTATION = gql`
  mutation addPanel($addProductInput: AddProductInput) {
    addPanel(addProductInput: $addProductInput) {
      _id
    }
  }
`;

export const ADD_PANEL_IMAGE_MUTATION = gql`
  mutation addPanelImage($panelUrl: String!) {
    addPanelImage(panelUrl: $panelUrl) {
      _id
      panelUrl
    }
  }
`;

export const ADD_INVERTER_MUTATION = gql`
  mutation addPanel($addProductInput: AddProductInput) {
    addInverter(addProductInput: $addProductInput) {
      _id
    }
  }
`;

export const REMOVE_INVERTER_MUTATION = gql`
  mutation removeInverter($_id: ID!) {
    removeInverter(_id: $_id) {
      _id
      name

      pmax
      brand
      model
    }
  }
`;

export const REMOVE_PANEL_MUTATION = gql`
  mutation removePanel($_id: ID!) {
    removePanel(_id: $_id)
  }
`;

export const EDIT_PANEL_MUTATION = gql`
  mutation editPanel($_id: ID!, $editProductInput: EditProductInput) {
    editPanel(_id: $_id, editProductInput: $editProductInput) {
      _id
    }
  }
`;

export const PANEL_IMAGES_QUERY = gql`
  query panelImages {
    panelImages {
      _id
      panelUrl
    }
  }
`;

export const TOGGLE_ACTIVE_MUTATION = gql`
  mutation toggleActivePanel($_id: ID!, $active: Boolean!) {
    toggleActivePanel(_id: $_id, active: $active) {
      active
    }
  }
`;

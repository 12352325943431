import React, { useState } from "react";
//import styled from "styled-components";
import { TableRow, TableCell, Checkbox, TextField } from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import { useMutation } from "@apollo/react-hooks";
import { ALL_USERS_QUERY } from "../../utils/GQL/QLQueries.js";
import { ADD_USER_MUTATION } from "../../utils/QLMutations.js";

const NewUser = ({ availablePermisisons }) => {
  const [permissionsState, setPermissions] = useState([]);
  const [newUser, setNewUser] = useState({
    proposalAgentEmail: "",
    proposalAgentName: "",
  });
  const [addUser] = useMutation(ADD_USER_MUTATION, {
    variables: {
      ...newUser,
      permissions: [...permissionsState],
    },
    update(cache, { data: { addUser: addUserData } }) {
      const oldData = cache.readQuery({
        query: ALL_USERS_QUERY,
      });

      cache.writeQuery({
        query: ALL_USERS_QUERY,

        data: { users: [...oldData.users, addUserData] },
      });
    },
  });

  const userPerms = ["USER", "READ", "WRITE"];
  const adminPerms = ["ADMIN", "PERMISSIONUPDATE", "ASSIGN"];
  const onCheckChange = (thePermission) => {
    const permStateFilter = permissionsState.filter(
      (perm) => perm === thePermission
    );

    if (thePermission === "USER") {
      const userFilter = permissionsState.filter((perm) => perm === "USER");
      if (userFilter.length > 0) {
        const filterOutUserPerms = permissionsState.filter(
          (perm) => !userPerms.includes(perm)
        );
        setPermissions(filterOutUserPerms);
      } else {
        setPermissions([...permissionsState, ...userPerms]);
      }
    } else if (thePermission === "ADMIN") {
      const userFilter = permissionsState.filter((perm) => perm === "ADMIN");
      if (userFilter.length > 0) {
        const filterOutAdminPerms = permissionsState.filter(
          (perm) => !adminPerms.includes(perm)
        );
        setPermissions(filterOutAdminPerms);
      } else {
        setPermissions([...permissionsState, ...adminPerms]);
      }
    } else if (permStateFilter.length === 0) {
      setPermissions([...permissionsState, thePermission]);
    } else {
      const newPermState = permissionsState.filter(
        (perm) => perm !== thePermission
      );
      setPermissions(newPermState);
    }
  };
  const handleInput = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value,
    });
  };

  const onAddUser = () => {
    addUser();
    setNewUser({
      proposalAgentEmail: "",
      proposalAgentName: "",
    });
    setPermissions([]);
  };
  return (
    <TableRow>
      <TableCell>
        <TextField
          onChange={(e) => handleInput(e)}
          name="proposalAgentName"
          value={newUser.proposalAgentName}
        />
      </TableCell>
      <TableCell>
        <TextField
          onChange={(e) => handleInput(e)}
          name="proposalAgentEmail"
          value={newUser.proposalAgentEmail}
        />
      </TableCell>
      {availablePermisisons.map((permission) => (
        <TableCell key={permission}>
          <Checkbox
            onChange={() => onCheckChange(permission)}
            checked={permissionsState.includes(permission)}
          />
        </TableCell>
      ))}
      <TableCell>
        <PersonAdd onClick={() => onAddUser()}></PersonAdd>
      </TableCell>
    </TableRow>
  );
};

export default NewUser;

import React, { useState } from "react";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

const AppointmentDateSelect = ({
  getAppointments,
  //   selectedDate,
  //   setSelectedDate,
}) => {
  const [selectedDate, setSelectedDate] = useState(moment().format());
  const handleDateChange = async (e) => {
    let newlySelectedDate = moment(e).format();

    setSelectedDate(moment(e).format());

    const apptsRes = await getAppointments({
      variables: {
        selectedDate: newlySelectedDate,
      },
    });
    console.log(apptsRes);
  };
  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM-DD-YYYY"
          margin="normal"
          id="date-picker-inline"
          label="Select Appointment Date"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default AppointmentDateSelect;

import React from "react";
import { VictoryBar, VictoryChart, VictoryAxis } from "victory";

const AppointmentChart = ({ apptData, onSetSelectedTimes }) => {
  //   const [theData, setTheData] = useState(apptData);

  const customClickHanlder = (i, d) => {
    onSetSelectedTimes(d[i].timeSlot);
    // console.log(i, d);
  };

  return (
    <VictoryChart
      animate={true}
      //   theme={VictoryTheme.material}
      domainPadding={10}
    >
      <VictoryBar
        style={{
          data: {
            paddingBottom: 5,
            paddingTop: 5,
            fill: "#fff",
            fontSize: 2,
            stroke: "#3cc7fa",
            marginBottom: 10,

            strokeWidth: 3,
          },
          labels: { fontSize: 8 },

          //   labels: { fill: "white" },
        }}
        labels={({ datum }) => datum.appointmentTotals}
        data={apptData}
        //   labelComponent={<VictoryLabel dy={30} />}
        x="timeSlot"
        y="appointmentTotals"
        events={[
          {
            target: "data",
            eventHandlers: {
              onClick: (e) => {
                return [
                  {
                    target: "data",
                    mutation: (props) => {
                      console.log(props);
                      customClickHanlder(props.index, props.data);

                      return props.clicked === "clicked"
                        ? null
                        : {
                            clicked: "clicked",
                            style: {
                              ...props.style,
                              fill: "#3cc7fa",
                              strokeWidth: 0,
                            },
                          };
                    },
                  },
                ];
              },
            },
          },
        ]}
      ></VictoryBar>
      <VictoryAxis
        style={{
          tickLabels: { fontSize: 8, padding: 5 },
        }}
      />
    </VictoryChart>
  );
};

export default AppointmentChart;

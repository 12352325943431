import React from "react";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import styled from "styled-components";

const ModalStyles = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .paper {
    background-color: #fff;
    max-height: 60%;
    min-width: 50%;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
      0 0 6px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
      0 0 6px rgba(0, 0, 0, 0.23);
    /* boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3) */
  }
`;

const NewModal = ({ open, setOpen, children }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ModalStyles
        className={"modal"}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 600,
        }}
      >
        <Fade in={open}>
          <div className={"paper"}>{children}</div>
        </Fade>
      </ModalStyles>
    </div>
  );
};

export default NewModal;

import React from "react";

import ProposalTable from "../../components/ProposalTable/ProposalTable";
// import CancelAssignment from "../../components/CancelAssignment/CancelAssignment";
import QueueTimer from "../../components/QueueTimer/QueueTimer";
import AssignProposal from "../../components/AssignProposal/AssignProposal";
import DropProposal from "../../components/DropProposal/DropProposal";

const InProgress = ({ proposalData, allUserData }) => {
  const tableHeaders = [
    { id: "1", label: "Sales Rep", key: "RepName" },
    { id: "2", label: "Opportunity Number", key: "oppnum" },
    { id: "3", label: "Customer", key: "CustomerName" },
    { id: "4", label: "Office", key: "Office" },
    { id: "5", label: "Appointment Date", key: "AppointmentDateString" },
    { id: "6", label: "Appointment Address", key: "Address" },
    { id: "7", label: "Time Requested", key: "timerequestedpretty" },

    { id: "8", label: "Assigned To", key: "assignedto" },
    { id: "9", label: "Current Status", key: "currentstatus" },
    { id: "10", label: "Time In Queue", key: "queue" },
    { id: "11", label: "Cancel Assignmnet", key: "drop" },
    { id: "12", label: "Reassign", key: "reassign" },
  ];

  // if (activeTab === "InProgress") {
  //   return (
  //     <>
  //       <ProposalStatus row={row} />
  //       <TableCell>
  //         <QueueTimer time={row.timeAssignedUnix} />
  //       </TableCell>
  //     </>
  //   );
  // }

  // eslint-disable-next-line
  const proposalsAssigned = proposalData.filter((prop) => {
    if (prop.user) return prop;
  });
  //console.log(proposalsAssigned);

  const newData = proposalsAssigned.reduce((arr, curr) => {
    arr.push({
      ...curr,

      assignedto: <>{curr.user && curr.user.proposalAgentName}</>,
      currentstatus: <ProposalStatus row={curr} />,
      queue: <QueueTimer time={curr.timeAssignedUnix} />,
      drop: <DropProposal proposal={curr} userId={curr.user.id} />,
      reassign: <AssignProposal id={curr.id} allUserData={allUserData} />,
    });
    return arr;
  }, []);

  return <ProposalTable tableHeaders={tableHeaders} data={newData} />;
};

const ProposalStatus = ({ row }) => {
  if (!row.proposalStarted) return <> Proposal Not Started </>;
  if (!row.proposalCompleted) return <> Proposal Completed </>;

  return <> No Status Tracking Info</>;
};

export default InProgress;

import React from "react";
//components
import Modal from "./styles";

const ModalComp = ({ open, children, width, modalClass, setModalState }) => {
  return open ? (
    <Modal
      //onClick={() => setModalState(false)}
      id="modalBackground"
      width={width}
    >
      <div className={modalClass ? modalClass : "modalMain"}>
        {children || "no"}
      </div>
    </Modal>
  ) : (
    ""
  );
};

export default ModalComp;

import styled from "styled-components";
export const CurrPropHeader = styled.div`
  /* display: flex; */
  /* flex-direction: row;
  justify-content: space-evenly; */
  /* align-items: center; */
  border-radius: 6px;
  color: ${props => props.theme.midnightBlue};
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 12px 17px 2px,
    rgba(0, 0, 0, 0.12) 0px 5px 22px 4px, rgba(0, 0, 0, 0.2) 0px 7px 8px -4px;
  margin: 30px 0 15px 0;
  width: 80%;
  padding: 15px;
  display: flex;
  justify-content: space-evenly;

  .category {
    text-align: center;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 13px;
    font-weight: normal;
  }

  .img-dl-con {
    width: 0%;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .img-dl {
    font-size: 30px;
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }

  @media only screen and (max-width: 1000px) {
    h3 {
      font-size: 12px;
      letter-spacing: 0;
    }

    h4 {
      font-size: 10px;
      letter-spacing: 0;
      font-weight: normal;
    }

    .img-dl {
      font-size: 24px;
    }
  }
`;

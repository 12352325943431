import React, { useContext } from "react";

import { CurrPropHeader } from "./styles";
import { Context } from "../../Context/ProposalContext";

export const CurrProposalDataHeader = (props) => {
  const {
    initialFormValues: { State, CustomerName, oppnum, RepName },
    otherValues: { proposalType, solarPanel },
  } = useContext(Context);

  const sliceAfterSecondSPace = (str) => {
    let spaceOne = str.indexOf(" ");

    let spaceTwo = str.indexOf(" ", spaceOne + 1);
    let retVal = str.substring(0, spaceTwo);
    let retValTwo = str.substring(spaceTwo);
    if (retVal) {
      return (
        <div>
          <div>{retVal}</div>
          <div>{retValTwo}</div>
        </div>
      );
    } else return str;
  };

  return (
    <>
      <CurrPropHeader>
        <div className="category">
          <h3> Customer Name</h3>
          <h4> {CustomerName}</h4>
        </div>
        {/* <div className="category">
        <h3> Address </h3>
        <h4> {Address} </h4>
      </div> */}
        <div className="category">
          <h3> State </h3>
          <h4> {State} </h4>
        </div>
        <div className="category">
          <h3> MS Number</h3>
          <h4> {oppnum} </h4>
        </div>
        <div className="category">
          <h3> Proposal Type</h3>
          <h4>
            {" "}
            {/* {State === "GA" && proposalType === "JEALoan"
              ? "GA Solar + Storage Loan"
              : proposalType || "Not Selected"}{" "} */}
            {proposalType === "JEALoan"
              ? "Solar + Storage Loan (No Net Meter)"
              : proposalType || "Not Selected"}{" "}
          </h4>
        </div>
        <div className="category">
          <h3> Panel Type</h3>
          <h4>
            {solarPanel ? sliceAfterSecondSPace(solarPanel) : "Not Selected"}
          </h4>
        </div>
        <div className="category">
          <h3> Sales Rep Name</h3>
          <h4> {RepName} </h4>
        </div>
      </CurrPropHeader>
    </>
  );
};

export default CurrProposalDataHeader;

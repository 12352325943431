import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

const QueueTimer = ({ time }) => {
  const [count, setCount] = useState(moment().unix() - time);

  useInterval(() => {
    // Your custom logic here

    setCount(count + 1);
  }, 1000);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const pad = (num) => {
    return ("0" + num).slice(-2);
  };

  const format = (secs) => {
    let actualSecs = secs % 60;
    let mins = Math.floor(secs / 60);
    let hours = Math.floor(mins / 60);

    return `${pad(hours)}:${pad(mins % 60)}:${pad(actualSecs)}`;
  };

  return (
    <>
      <p style={{ margin: "0px" }}>{format(count)}</p>
    </>
  );
};

export default QueueTimer;

import React, { useContext } from "react";
import ModalHeader from "../../components/NewCustomModal/ModalHeader";
import { ModalContext } from "../../Context/ModalContext";
import NewBatteryOpp from "../../containers/BatteryHome/NewBatteryOpp";

const OpenModalHook = (ModalContentObj = { headerTitle: "" }) => {
  const { setModalOpen, setModalContent } = useContext(ModalContext);

  const { headerTitle } = ModalContentObj;

  //console.log(headerTitle, ModalContentObj);

  const openTheModal = () => {
    //  setShow(false);
    setModalOpen(true);
    setModalContent(
      <>
        {headerTitle && <ModalHeader title={headerTitle} />}
        <NewBatteryOpp />
      </>
    );
  };

  const closeTheModal = () => {
    setModalOpen(false);
  };

  return { openTheModal, closeTheModal };
};

export default OpenModalHook;
